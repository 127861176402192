import * as React from 'react';
import {
  ValidatorOutput,
  ValidatorProps,
  ValidatorReducer,
  ValidatorValue,
  ValidatorResult,
} from './Validator.types';
import { isValidEmail } from './ValidatorFns/EmailValidator';
import { isValidPesel } from './ValidatorFns/PeselValidator';

const defaultValidators = {
  isValidPostalCode: (value: string): ValidatorOutput =>
    /^\d{2}-\d{3}$/.test(value),
  isValidNumber: (value: string): ValidatorOutput => !isNaN(Number(value)),
  isValidLength: (value: string, length: number): ValidatorOutput =>
    value.length === length,
  isValidPhoneNumber: (value: string): ValidatorOutput =>
    /^\+\d\d\s\d\d\d-\d\d\d-\d\d\d$/.test(value),
  isValidPesel: (value: string) => isValidPesel(value),
  isValidEmail: (value: string) => isValidEmail(value),
};

const defaultErrorMessages = {
  isValidPostalCode: 'Niepoprawny kod pocztowy',
  isValidNumber: 'Niepoprawna liczba',
  isValidLength: 'Przekroczyłeś limit znaków',
  isValidPhoneNumber: 'Powinien być w formacie 48 000-000-000',
  isValidPesel: 'Podaj prawidłowy nr PESEL',
  isValidEmail: 'Podaj prawidłowy adres email',
  default: 'Niepoprawna wartość',
};

const initValidationState: ValidatorResult = {
  errors: {},
  isValid: true,
  touched: true,
  validated: {},
};

export const normalizeNumber = (value: string): number => {
  return parseInt(normalizeString(value));
};
export const normalizeString = (value: string): string => {
  return value.replace(/[^0-9,+]/g, '');
};

export const Validator: React.FC<ValidatorProps> = ({
  customValidator,
  value,
  validate,
  errorMessages,
  onCorrectChange,
  onIncorrectChange,
  validators,
  returnNumber,
  children,
  normalize,
  skipEmptyValidation,
}) => {
  const [newValue, setNewValue] = React.useState(value || '');
  const [validated, setValidated] = React.useState<ValidatorReducer>({});
  const [errors, setErrors] = React.useState<ValidatorReducer>({});
  const validateFromValidators = {};
  validators &&
    Object.keys(validators).forEach((key) => {
      validateFromValidators[key] = true;
    });

  const validatorReqs = { ...validate, ...validateFromValidators };
  const validatorFunctions = { ...defaultValidators, ...validators };

  const handleCorrectChange = (value: ValidatorValue = ''): void => {
    if (returnNumber) {
      onCorrectChange && onCorrectChange(normalizeNumber(String(value)));
    } else if (normalize) {
      onCorrectChange && onCorrectChange(normalizeString(String(value)));
    } else {
      onCorrectChange && onCorrectChange(value);
    }
  };

  const handleNewValue = React.useCallback(async () => {
    const newValidations = customValidator
      ? { validated: customValidator(value) }
      : {};
    const newErrors = { ...errors };

    if (validatorReqs) {
      const promises = Object.keys(validatorReqs).map(
        (validatorKey: string) => {
          const validatorFunc: (T, K) => boolean =
            validatorFunctions[validatorKey];
          const validatorPayload = validatorReqs[validatorKey];
          if (validatorFunc) {
            return validatorFunc(value, validatorPayload);
          }
          return undefined;
        },
      );
      const promiseResults = await Promise.all(promises);
      promiseResults &&
        Object.keys(validatorReqs).forEach((validatorKey, index) => {
          newValidations[validatorKey] = promiseResults[index];
          if (!newValidations[validatorKey]) {
            const errorMsg: string =
              (errorMessages && errorMessages[validatorKey]) ||
              defaultErrorMessages[validatorKey] ||
              defaultErrorMessages.default;
            newErrors[validatorKey] = errorMsg;
          } else {
            delete newErrors[validatorKey];
          }
        });
    }

    Object.keys(newValidations).length &&
      setValidated({ ...validated, ...newValidations });
    setErrors(newErrors);
    setNewValue(value || '');
  }, [errors, validated, validate, customValidator, value, errorMessages]);

  React.useEffect(() => {
    if (newValue !== value) {
      handleNewValue();
    }
  }, [value]);

  const isValid = !(
    Object.keys(validated).length &&
    Object.keys(validated).find((key) => {
      return !validated[key];
    })
  );

  React.useEffect(() => {
    const isAfterValidation = Boolean(Object.keys(validated).length);
    if (isAfterValidation) {
      if (isValid) {
        value ? handleCorrectChange(value) : handleCorrectChange();
      } else {
        onIncorrectChange && onIncorrectChange(value);
      }
    }
  }, [validated, isValid]);

  const errorKeys = Object.keys(errors);
  const error = errorKeys.length && errors[errorKeys[0]];

  const getValidationResults = (): ValidatorResult => {
    if (skipEmptyValidation && newValue === '') {
      return initValidationState;
    }
    return {
      isValid,
      validated,
      errors,
      ...(error && { error }),
      helperText: error || true,
      touched: newValue !== '',
    } as ValidatorResult;
  };
  const validatorResult = getValidationResults();

  return children ? children(validatorResult) : null;
};
