import React, { FC, useEffect, useMemo, useRef } from 'react';
import {
  onKeyDownArrowDown,
  onKeyDownArrowUp,
  onKeyDownEnter,
  onKeyDownShift,
  onKeyDownTab,
} from '../../../utils/onKeyDown.utils';
import {
  ToggleSelectOptionCaption,
  ToggleSelectOptionIconWrapper,
  ToggleSelectOptionTitle,
  ToggleSelectOptionWrapper,
} from './Option.style';
import { OptionProps } from './Option.type';

export const Option: FC<OptionProps> = ({
  value,
  isOpened,
  dataE2e,
  option,
  focused,
  onChange,
  setFocused,
  focusNextOption,
  focusPrevOption,
  onKeyDownTab: onKeyDownTabProp,
  setIsKeyPressedShift,
  inputMode,
  icon,
}: OptionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isFocused = option.value === focused;
  const focus = (): void => {
    ref.current?.focus();
  };
  useEffect(() => {
    isFocused && focus();
  }, [isFocused]);
  const isSelected = useMemo<boolean>(() => {
    if (Array.isArray(value)) {
      return value.includes(option.value);
    }
    return value === option.value;
  }, [value, option.value]);
  useEffect(() => {
    !inputMode && isOpened && isSelected && setFocused(option.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputMode, isOpened, isSelected, option.value]);
  const onKeyDownHandle = (e: React.KeyboardEvent): void => {
    onKeyDownArrowUp(e, focusPrevOption);
    onKeyDownArrowDown(e, focusNextOption);
    onKeyDownTab(e, () => {
      e.preventDefault();
      onKeyDownTabProp();
    });
    onKeyDownShift(e, () => {
      setIsKeyPressedShift(true);
    });
    onKeyDownEnter(e, () => onChange(option.value));
  };
  return (
    <ToggleSelectOptionWrapper
      data-e2e={dataE2e}
      tabIndex={-1}
      ref={ref}
      isCentered={!option.title && !option.caption}
      onClick={() => onChange(option.value)}
      onKeyDown={onKeyDownHandle}
      onKeyUp={(e) => e.key === 'Shift' && setIsKeyPressedShift(false)}
      onMouseMove={() => !inputMode && setFocused(option.value)}
      className={`${isSelected && !isFocused ? 'selected' : ''} ${
        isFocused ? 'focused' : ''
      }`}
    >
      {(option.title || option.caption) && (
        <div>
          <ToggleSelectOptionTitle>{option.title}</ToggleSelectOptionTitle>
          <ToggleSelectOptionCaption>
            {option.caption}
          </ToggleSelectOptionCaption>
        </div>
      )}
      <ToggleSelectOptionIconWrapper>{icon}</ToggleSelectOptionIconWrapper>
    </ToggleSelectOptionWrapper>
  );
};

export default Option;
