import * as React from 'react';
import { ReactComponent as LoaderBlack } from '../assets/svg/loader-black.svg';
import { ReactComponent as LoaderWhite } from '../assets/svg/loader-white.svg';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { StyledButton, StyledLoaderWrapper } from './Button.styled';
import { ButtonProps } from './Button.types';

export const Button: React.FC<ButtonProps> = ({
  typeStyle,
  size,
  children,
  disabled,
  onClick,
  sticky,
  noMinWidth,
  loader,
  loaderDesc,
  isFocused,
  ...restProps
}: ButtonProps) => {
  const [clickPosition, setClickPosition] = React.useState({
    left: '0',
    top: '0',
    diameter: 1,
  });

  const manageClickRippleParams = (event): void => {
    const button: HTMLElement = event.currentTarget;
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;
    const left = `${event.clientX - button.offsetLeft - radius}px`;
    const top = `${event.clientY - button.offsetTop - radius}px`;
    setClickPosition({ left, top, diameter });
  };

  const handleClick = (event): void => {
    manageClickRippleParams(event);
    onClick && onClick(event);
  };

  const RenderLoader = (): JSX.Element => {
    if (typeStyle === 'secondary') {
      return (
        <StyledLoaderWrapper>
          <LoaderWhite /> {loaderDesc ?? loaderDesc}
        </StyledLoaderWrapper>
      );
    } else {
      return (
        <StyledLoaderWrapper>
          <LoaderBlack /> {loaderDesc ?? loaderDesc}
        </StyledLoaderWrapper>
      );
    }
  };

  return (
    <DefaultThemed>
      <StyledButton
        typeStyle={typeStyle || 'primary'}
        size={size || 'regular'}
        disabled={disabled || loader || false}
        sticky={sticky || false}
        {...clickPosition}
        {...restProps}
        onClick={handleClick}
        noMinWidth={noMinWidth}
        isFocused={isFocused || false}
      >
        {loader ? <RenderLoader /> : children}
      </StyledButton>
    </DefaultThemed>
  );
};
