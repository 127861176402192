import React from 'react';
import { Picker } from '../../../../../BeeDatePicker.const';
import { usePickerContext } from '../../../../../contexts';
import { isDatePickerFormFilled } from '../../../../../helpers/isDatePickerFormFilled';
import { isYearInRange } from '../../../../../helpers/isInRange';
import {
  StyledCalendarButton,
  StyledPickerBody,
} from '../../../RenderPicker.styled';
import { StyledHeader, StyledYearsWrapper } from '../YearPicker.styled';
import { MobileYearPickerProps } from './MobileYearPicker.types';

export const MobileYearPicker: React.FC<MobileYearPickerProps> = ({
  labels,
  yearsMatrix,
  yearsBlockIndex,
}) => {
  const refHeader = React.useRef<HTMLDivElement>(null);
  const {
    dispatch,
    state: { today, date, datesRange, visiblePicker },
  } = usePickerContext();

  React.useEffect(() => {
    if (visiblePicker === Picker.YEAR && yearsBlockIndex !== 0) {
      refHeader.current?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }, [visiblePicker, yearsBlockIndex]);

  const onClickHandler = React.useCallback(
    (year: number): void => {
      const stringYear = year.toString();
      const isCurrentYear = stringYear === date.year;

      if (isCurrentYear) {
        dispatch({
          type: 'SET_VISIBLE_PICKER',
          payload: null,
        });

        return;
      }

      dispatch({ type: 'SET_DATE', payload: { year: stringYear } });

      if (isDatePickerFormFilled({ ...date, year: stringYear })) {
        dispatch({ type: 'SET_VISIBLE_PICKER', payload: null });
      }
    },
    [date, dispatch],
  );

  return (
    <StyledPickerBody>
      {yearsMatrix.map((years, index) => (
        <div key={`${labels[index]}${index}`}>
          <StyledHeader>{labels[index]}</StyledHeader>
          <StyledYearsWrapper
            ref={index === yearsBlockIndex ? refHeader : null}
          >
            {years.map((year) => (
              <StyledCalendarButton
                key={year}
                onClick={() => onClickHandler(year)}
                isToday={today.getUTCFullYear() === year}
                selected={Number(date.year) === year}
                isDisabled={datesRange && !isYearInRange(year, datesRange)}
                type="button"
              >
                {year}
              </StyledCalendarButton>
            ))}
          </StyledYearsWrapper>
        </div>
      ))}
    </StyledPickerBody>
  );
};
