import React from 'react';
import { CollapseWrapperProps } from './Collapse.types';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import {
  StyledCollapseWrapper,
  StyledCollapseContent,
} from './Collapse.styled';

export const CollapseWrapper: React.FC<CollapseWrapperProps> = ({
  children,
  label,
  isVisible,
  closeHandle,
  openHandle,
  duration,
  height,
}: CollapseWrapperProps) => {
  const toggleHandle = (): void => {
    isVisible ? closeHandle() : openHandle();
  };
  return (
    <DefaultThemed>
      <StyledCollapseWrapper>
        {label &&
          React.cloneElement(label, {
            'data-e2e': 'collapsible-label',
            onClick: () => {
              label.props.onClick &&
                typeof label.props.onClick === 'function' &&
                label.props.onClick();
              toggleHandle();
            },
          })}
        <StyledCollapseContent
          height={isVisible ? height : 0}
          duration={duration}
        >
          {children}
        </StyledCollapseContent>
      </StyledCollapseWrapper>
    </DefaultThemed>
  );
};
