import { CookiePolicyDescription } from '../../../types';
import { getStartOfParagraphText } from './getStartText';

export const getStartDescriptionText = (
  description: CookiePolicyDescription,
): string => {
  const title = description.title;
  const startParagraphText = getStartOfParagraphText(
    description.paragraphs[0],
    8,
  );

  return `${title} ${startParagraphText}...`;
};
