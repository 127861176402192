import React, { createContext, PropsWithChildren, useContext } from 'react';
import { ANIMATIONS } from '../Theme/utils/animations';
import { PanelsContextType } from './Panels.types';

const defaultConfig = {
  value: '',
  duration: ANIMATIONS.defaultTransitionTime,
  setPanelParameters: () => undefined,
};

export const PanelsContext = createContext<PanelsContextType>(defaultConfig);
export const usePanels = (): PanelsContextType =>
  useContext<PanelsContextType>(PanelsContext);
export const PanelsProvider = (
  props: PropsWithChildren<{ value: PanelsContextType }>,
): JSX.Element => {
  return (
    <PanelsContext.Provider value={props.value}>
      {props.children}
    </PanelsContext.Provider>
  );
};
