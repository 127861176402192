import React, { FC, CSSProperties } from 'react';
import { DefaultThemed, useTheme } from '../ThemeProvider/ThemeProvider';
import { StyledTypographyElement } from './Typography.styled';

import { TypographyProps } from './Typography.types';

export const TypographyStyles: FC<
  TypographyProps & React.HTMLProps<HTMLElement>
> = (props: TypographyProps) => {
  const theme = useTheme();

  const getTypographyVariant = (): CSSProperties => {
    if (props.variant && typeof props.variant === 'string') {
      return theme.typography[props.variant];
    }
    if (props.variant && typeof props.variant === 'object') {
      return props.variant;
    }
    return theme.typography.body1;
  };
  const typographyVariant = getTypographyVariant();
  const styles = {
    textAlign: props.align ? props.align : 'left',
    color: props.color ? props.color : theme.palette.button.text.primary.base,
    fontFamily: props.fontFamily
      ? props.fontFamily
      : theme.typography.fontFamily,
    ...typographyVariant,
    ...props.style,
  };
  return (
    <DefaultThemed>
      <StyledTypographyElement {...props} styles={styles} />
    </DefaultThemed>
  );
};
export const Typography: FC<TypographyProps & React.HTMLProps<HTMLElement>> = (
  props: TypographyProps,
) => {
  return (
    <DefaultThemed>
      <TypographyStyles {...props} />
    </DefaultThemed>
  );
};
