export const isValidPesel = (pesel: string): boolean => {
  const reg = /^\d{11}$/;

  if (reg.test(pesel)) {
    const importance = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
    let sum = 0;
    for (let i = 0; i < importance.length; i++) {
      sum += parseInt(pesel.substring(i, i + 1), 10) * importance[i];
    }
    sum = sum % 10;
    const valid = sum === parseInt(pesel.substring(10, 11), 10);

    const exceptions = ['00000000000', '44444444444'];

    if (valid && !exceptions.includes(pesel)) {
      return true;
    }
  }
  return false;
};
