import { toast } from 'react-toastify';

export type NotificationType = 'error' | 'success';
export const notificationSuccess = '#28a745';
export const notificationError = '#FF4C2F';

export const errorOptions = {
  style: {
    backgroundColor: notificationError,
    color: 'white',
    maxWidth: '100%',
  },
};

export const successOptions = {
  style: {
    backgroundColor: notificationSuccess,
    color: 'white',
    maxWidth: '100%',
  },
};

export const notification = (
  notificationType: NotificationType,
  t: (t: string) => string,
  msg?: string,
): void => {
  switch (notificationType) {
    case 'success':
      toast.success(msg ?? t('THE_OPERATION_WAS_SUCCESSFUL'), successOptions);
      break;
    case 'error':
      toast.error(msg ?? t('STH_WENT_WRONG'), errorOptions);
      break;
    default:
      throw new Error('Unknown notification type');
  }
};
