import styled from 'styled-components';

// color: ${(p) => p.theme.palette.primary.dark};
export const CloudInfoContainer = styled.div`
  color: ${(p) => p.theme.palette.text.paragraph.primary};
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: ${(p) => p.theme.typography.body1.fontSize};
`;

export const CloudInfoHeader = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 1rem;
`;

export const CloudInfoIcon = styled.div`
  background-color: ${(p) => p.theme.palette.commons.background.infotip};
  border-radius: 1rem;
  color: ${(p) => p.theme.palette.brand.color};
  height: 1.5rem;
  line-height: 1.5rem;
  margin: 0 0.625rem 0 0;
  text-align: center;
  width: 1.5rem;
`;

type CloudInfoTipProps = { padding: string | null };

export const CloudInfoTip = styled.div<CloudInfoTipProps>`
  background-color: ${(p) => p.theme.palette.commons.background.infotip};
  border-radius: 0.625rem;
  margin: 0.5rem 0;
  padding: ${(p) => p.padding ?? '1rem 1.5rem'};
  position: relative;
  white-space: pre-wrap;

  &::before {
    border-bottom: 0.65rem solid
      ${(p) => p.theme.palette.commons.background.infotip};
    border-left: 0.75rem solid transparent;
    border-right: 0.75rem solid transparent;
    content: '';
    height: 0rem;
    left: 6.5625rem;
    position: absolute;
    top: -0.5rem;
    width: 0rem;
  }
`;
