import * as React from 'react';
import { IconStyled } from './Icon.styled';
import { IconProps } from './Icon.types';
import { DEFAULT_ICON_SIZES } from './Icon.defaults';

export const Icon: React.FC<IconProps> = ({
  icon,
  bgColor,
  orientation = 'left',
  sizes = DEFAULT_ICON_SIZES,
  inputState,
}: IconProps): React.ReactElement => {
  return (
    <IconStyled
      inputState={inputState}
      orientation={orientation}
      bgColor={bgColor}
      sizes={sizes}
    >
      {icon}
    </IconStyled>
  );
};
