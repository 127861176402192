import React, { FC } from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import {
  StyledChildren,
  StyledInput,
  StyledLabel,
  StyledValue,
} from './RadioButton.styled';
import { RadioButtonProps } from './RadioButton.type';

export const RadioButton: FC<RadioButtonProps> = ({
  id,
  name,
  value,
  children,
}: RadioButtonProps) => (
  <DefaultThemed>
    <StyledLabel htmlFor={id} hasChildren={Boolean(children)}>
      <StyledInput type="radio" id={id} name={name} value={value} />
      {children ? (
        <StyledChildren>{children}</StyledChildren>
      ) : (
        <StyledValue>{value}</StyledValue>
      )}
    </StyledLabel>
  </DefaultThemed>
);
