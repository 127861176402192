import { ReactNode, RefObject, useEffect, useRef } from 'react';

export function useCombinedRefs<T>(...refs: unknown[]): RefObject<T> {
  const targetRef = useRef<RefObject<T>>();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        targetRef.current &&
          (ref as (element: RefObject<T>) => ReactNode)(targetRef.current);
      } else {
        (ref as { current: ReactNode }).current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef as unknown as RefObject<T>;
}
