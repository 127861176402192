import { PositionType } from '../Tabs.types';

export const getPosition = (element: HTMLElement | null): PositionType => {
  const position = element?.getBoundingClientRect();
  return {
    top: position?.top || 0,
    left: position?.left || 0,
    offsetWidth: element?.offsetWidth || 0,
    offsetHeight: element?.offsetHeight || 0,
  };
};
