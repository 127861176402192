import { ColorsType } from '../../utils/colors.type';

export const ColorsPalette: ColorsType = {
  brand: '#88CA11',
  brand_background: '#F7FBEE',

  primary_button_background: '#88CA11',
  primary_button_background_hover: '#71AC07',
  primary_button_background_after: '#FFFFFF',

  secondary_button_background: '#007C33',
  secondary_button_background_hover: '#71AC07',
  secondary_button_background_after: '#FFFFFF',

  outline_button_background: '#FFFFFF',
  outline_button_background_hover: '#88CA11',
  outline_button_background_after: '#FFFFFF',

  select_button_background: '#FFFFFF',
  select_button_background_selected: '#F7FBEE',
  select_button_background_after: '#88CA11',

  outline_button_border: '#CCCCCC',
  outline_button_border_hover: '#88CA11',

  select_button_border: '#CCCCCC',
  select_button_border_hover: '#88CA11',
  select_button_border_selected: '#88CA11',

  text_primary_button: '#FFFFFF',
  text_secondary_button: '#FFFFFF',
  text_outline_button: '#000000',
  text_outline_button_hover: '#FFFFFF',
  text_select_button: '#000000',

  text_link: '#88CA11',
  text_header: '#000000',
  text_primary_paragraph: '#000000',
  text_secondary_paragraph: '#575757',

  text_input: '#575757',
  text_input_selected: '#000000',

  text_inactive: '#575757',

  input_border: '#CCCCCC',
  input_border_hover: '#000000',
  input_border_selected: '#000000',

  checkbox_border: '#CCCCCC',

  switch_background_selected: '#FFFFFF',

  error_base: '#FF0000',
  text_error_notification: '#FFFFFF',
  error_hover: '#FFF2F2',

  tonal_base: '#F6F6F6',
  tonal_hover: '#F1F9E2',

  container_background: '#FFFFFF',
  infotip_background: '#F7FBEE',
  dimmed_background: '#000000',
  icons_endorsement: '#575757',
  divider: '#F2F2F2',
  UE: '#4545FF',

  footer_primary_background: '#575757',
  footer_secondary_background: '#F6F6F6',
  text_footer: '#FFFFFF',

  dropdown_shadow: '0px 2px 8px rgba(58, 58, 58, 0.1)',
  content_box_shadow: '0px 6px 12px rgba(58, 58, 58, 0.1)',
  switch_shadow: '0px 5px 10px rgba(58, 58, 58, 0.1)',
  modal_shadow: '0px 10px 40px rgba(0, 0, 0, 0.15)',
} as const;
