import styled from 'styled-components';

export const StyledNavbar = styled.div`
  align-items: center;
  background-color: ${(p) => p.theme.palette.commons.background.container};
  color: ${(p) => p.theme.palette.brand.color};
  display: flex;
  font-size: 0.875rem;
  font-weight: bold;
  height: 3.75rem;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 1;
`;

type Position = 'left' | 'right';
type Align = 'flex-start' | 'center' | 'flex-end';
export const NavButton = styled.a<{ align: Align; position: Position }>`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: ${(p) => p.align};
  ${(p) => `${p.position}:0;`};
  line-height: 0;
  position: absolute;
  & > svg {
    fill: ${(p) => p.theme.palette.brand.color};
    height: 0.875rem;
    text-align: center;
    width: 100%;
  }
  width: 3rem;
`;
export const Title = styled.span`
  display: inline-block;
  text-align: center;
  width: 100%;
`;
