import React from 'react';
import { Picker } from '../../../BeeDatePicker.const';
import { usePickerContext } from '../../../contexts/PickerContext/PickerContext';
import { isDatePickerFormFilled } from '../../../helpers/isDatePickerFormFilled';
import { YEAR_LENGTH } from '../../RenderPicker';
import { Placeholder } from '../DateForm.const';
import { StyledInput } from '../DateForm.styled';
import { DateInputProps } from '../DateForm.types';
import { inputFocus } from '../DateForm.utils';

export const YearInput: React.FC<DateInputProps> = ({
  isVisible,
  setVisiblePicker,
}) => {
  const {
    state: { noDayMode, date, dayRef, monthRef, yearRef, readOnly },
    dispatch,
  } = usePickerContext();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value, maxLength } = event.target;

      dispatch({
        type: 'SET_DATE',
        payload: { year: value.replace(/\D/g, '') },
      });

      if (value.length !== maxLength) {
        return;
      }

      if (
        isDatePickerFormFilled({
          day: dayRef.current?.value,
          month: monthRef.current?.value,
          year: value,
        })
      ) {
        yearRef.current?.blur();
        dispatch({ type: 'SET_VISIBLE_PICKER', payload: null });
      } else {
        inputFocus(dayRef, monthRef, yearRef);
      }
    },
    [dayRef, monthRef, yearRef, dispatch],
  );

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Backspace' && event.currentTarget.value.length < 1) {
        monthRef.current?.focus();
      }
    },
    [monthRef],
  );

  return (
    <StyledInput
      noDayMode={noDayMode}
      autoComplete={'off'}
      name={Picker.YEAR}
      placeholder={isVisible ? Placeholder.YEAR : undefined}
      value={date.year}
      maxLength={YEAR_LENGTH}
      ref={yearRef}
      readOnly={readOnly}
      disabled={readOnly}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onClick={setVisiblePicker}
      onFocus={setVisiblePicker}
    />
  );
};
