import styled from 'styled-components';
import {
  ContentWrapperProps,
  DialogBackdropProps,
  DialogWrapperProps,
  PositionType,
} from './Dialog.types';

const getAlignItems = (position: PositionType): string => {
  switch (position) {
    case 'top':
      return 'flex-start';
    case 'bottom':
      return 'flex-end';
    default:
      return 'center';
  }
};
const getJustifyContent = (position: PositionType): string => {
  switch (position) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return 'center';
  }
};

const getTransform = (position: PositionType, isVisible: boolean): string => {
  if (!isVisible) {
    switch (position) {
      case 'top':
        return 'transform: translateY(-100%);';
      case 'bottom':
        return 'transform: translateY(100%);';
      case 'left':
        return 'transform: translateX(-100%);';
      case 'right':
        return 'transform: translateX(100%);';
    }
  }
  return '';
};

export const StyledDialogBackdrop = styled('div')<DialogBackdropProps>`
  background: ${(p) => p.backgroundColor};
  bottom: 0;
  left: 0;
  position: fixed;
  ${(props) => (!props.isVisible ? 'opacity: 0;' : '')};
  right: 0;
  top: 0;
  transition: opacity ${(props) => props.duration} ease-out;
  z-index: 10;
`;
export const StyledDialogWrapper = styled('div')<DialogWrapperProps>`
  align-items: ${(p) => getAlignItems(p.position)};
  bottom: 0;
  display: flex;
  justify-content: ${(p) => getJustifyContent(p.position)};
  ${(props) =>
    !props.isVisible && props.position === 'center' ? 'opacity: 0;' : ''};
  left: 0;
  max-height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity ${(props) => props.duration} ease-out;
  z-index: ${(props) => props.zIndex};
`;
export const StyledContentWrapper = styled('div')<ContentWrapperProps>`
  ${(p) => getTransform(p.position, p.isVisible)}
  max-height: 100%;
  transition: transform ${(props) => props.duration} ease-out;
`;
