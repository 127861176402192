import React, { useRef, useEffect } from 'react';
import { PanelProps } from './Panels.types';
import { usePanels } from './Panels.provider';
import { StyledPanelWrapper } from './Panels.styled';
import { getParameters } from './utils/getParameters';
import { useWindowScrollResizeEvent } from '../Hooks/useWindowScrollResizeEvent';

export const Panel: React.FC<PanelProps> = ({ name, children }: PanelProps) => {
  const ref = useRef(null);
  const { value, duration, setPanelParameters } = usePanels();
  const setNewPanelParameters = (): void => {
    if (name === value) {
      const parameters = getParameters(ref.current);
      setPanelParameters(parameters);
    }
  };
  useEffect(() => {
    setNewPanelParameters();
  }, [name, value]);
  const handleScrollOrResize = (): void => {
    setNewPanelParameters();
  };
  useWindowScrollResizeEvent(handleScrollOrResize, [name, value]);
  const isActive = value === name;
  return (
    <StyledPanelWrapper ref={ref} isActive={isActive} duration={duration}>
      {children}
    </StyledPanelWrapper>
  );
};
