import styled from 'styled-components';
import { BeeFieldState, BeeFieldStyles } from '../../BeeField.types';
import { getFieldColor } from '../../utils/getFieldColor.util';
import { DEFAULT_ANIMATED_LABEL_STYLES } from './AnimatedLabel.defaults';

export const isAnimatedLabelOnTop = (state: BeeFieldState): boolean => {
  return [
    BeeFieldState.FOCUSED,
    BeeFieldState.CHECKED,
    BeeFieldState.ERROR,
    BeeFieldState.DISABLED,
  ].includes(state);
};

const getLabelFontSize = ({
  styles,
  state,
}: {
  styles: BeeFieldStyles;
  state: BeeFieldState;
}): string | number => {
  const isVisible = isAnimatedLabelOnTop(state);
  const defaultFontSize = DEFAULT_ANIMATED_LABEL_STYLES.default.fontSize as
    | string
    | number;
  return (
    (isVisible ? styles.focused.fontSize : styles.default.fontSize) ||
    defaultFontSize
  );
};

const getLabelLineHeight = ({
  styles,
  state,
}: {
  styles: BeeFieldStyles;
  state: BeeFieldState;
}): string | number => {
  return (
    isAnimatedLabelOnTop(state)
      ? styles.focused.lineHeight
      : styles.default.lineHeight
  ) as string | number;
};

const getLabelTop = ({
  styles,
  state,
}: {
  styles: BeeFieldStyles;
  state: BeeFieldState;
}): string | number => {
  return (
    isAnimatedLabelOnTop(state) ? styles.focused.top : styles.default.top
  ) as string | number;
};

export const AnimatedLabelStyledWrapper = styled('div')`
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const AnimatedLabelStyled = styled('div')<{
  state: BeeFieldState;
  styles: BeeFieldStyles;
}>`
  background-color: white;
  box-sizing: border-box;
  caret-color: transparent;
  color: ${({ state, theme }) => getFieldColor({ theme, state })};
  font-size: ${({ state, styles }) => getLabelFontSize({ styles, state })};
  left: ${({ styles }) => styles.default.left};
  line-height: ${({ state, styles }) => getLabelLineHeight({ styles, state })};
  padding: 0rem 0.25rem;
  position: absolute;
  top: ${({ state, styles }) => getLabelTop({ styles, state })};
  transition: top 0.2s, left 0.2s, font-size 0.2s, opacity 0.2s, color 0.2s;
  z-index: 1;
`;
