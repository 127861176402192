import React from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { DatePicker } from './BeeDatePicker';
import { BeeDatePickerProps } from './BeeDatePicker.types';
import { PickerProvider } from './contexts/PickerContext/PickerContext';
import { BeeDatePickerContainer } from './BeeDatePicker.styled';

export const BeeDatePicker: React.FC<BeeDatePickerProps> = (props) => {
  return (
    <DefaultThemed>
      <PickerProvider {...props}>
        <BeeDatePickerContainer height={props.genericStyles?.height}>
          <DatePicker />
        </BeeDatePickerContainer>
      </PickerProvider>
    </DefaultThemed>
  );
};
