import {
  AvailableCookieModalLanguages,
  CookiesModalTranslatedText,
} from '../../types';
import {
  CookieModalTranslatedTextEnglish,
  CookieModalTranslatedTextPolish,
} from './translatedText';

type CookieModalTranslatedTexts = {
  [key in AvailableCookieModalLanguages]: CookiesModalTranslatedText;
};

export const cookieModalMenuTexts: CookieModalTranslatedTexts = {
  pl: CookieModalTranslatedTextPolish,
  en: CookieModalTranslatedTextEnglish,
};
