import React, { useEffect } from 'react';

import { DialogContentProps } from './DialogContent.type';
import { BeeTextInput } from '../../../BeeTextInput/BeeTextInput';
import { ReactComponent as SearchIcon } from '../../../assets/svg/search.svg';
import {
  StyledContainer,
  StyledInputContainer,
  StyledOptionsContainer,
  StyledStickyContainer,
  StyledTitleContainer,
  StyledLabel,
  StyledCloseIcon,
  StyledBackContainer,
  StyledBackIcon,
  StyledSearchIconContainer,
} from './DialogContent.style';

export const DialogContent: React.FC<DialogContentProps> = (
  props: DialogContentProps,
) => {
  const {
    contentRef,
    dialogFullScreen,
    shouldRenderInput,
    placeholder,
    inputRef,
    inputVal,
    setInputValueHandle,
    children,
    label,
    close,
    onBack,
    dataE2e,
    onInputKeyDown,
  } = props;
  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  return (
    <StyledContainer ref={contentRef} dialogFullScreen={dialogFullScreen}>
      <StyledStickyContainer>
        <StyledTitleContainer>
          <StyledBackContainer>
            {onBack && (
              <StyledBackIcon onClick={onBack} data-e2e={`${dataE2e}-back`} />
            )}
          </StyledBackContainer>
          <StyledLabel>{label}</StyledLabel>
          <StyledCloseIcon onClick={close} data-e2e={`${dataE2e}-close`} />
        </StyledTitleContainer>
        {shouldRenderInput && (
          <StyledInputContainer>
            <BeeTextInput
              data-e2e={`${dataE2e}-input`}
              suffixNode={
                <StyledSearchIconContainer>
                  <SearchIcon />
                </StyledSearchIconContainer>
              }
              label={placeholder}
              ref={inputRef}
              value={inputVal}
              onChange={setInputValueHandle}
              onKeyDown={onInputKeyDown}
            />
          </StyledInputContainer>
        )}
      </StyledStickyContainer>
      <StyledOptionsContainer>{children}</StyledOptionsContainer>
    </StyledContainer>
  );
};
export default DialogContent;
