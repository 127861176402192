import React, { useRef, useState } from 'react';
import { ANIMATIONS } from '../Theme/utils/animations';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { TabsProvider } from './Tabs.provider';
import {
  StyledBackdrop,
  StyledTabsContainer,
  StyledTabsWrapper,
} from './Tabs.styled';
import { PositionType, TabsProps, ValueType } from './Tabs.types';
import { getPosition } from './utils/getPosition';

export const Tabs: React.FC<TabsProps> = ({
  value,
  onChange,
  children,
  backdropStyle,
  tabStyle,
  activeTabStyle,
  style,
  duration = ANIMATIONS.defaultTransitionTime,
}: TabsProps) => {
  const ref = useRef(null);
  const [backdropPosition, setBackdropPosition] = useState<PositionType>({
    top: 0,
    left: 0,
    offsetHeight: 0,
    offsetWidth: 0,
  });
  const getValues = (): ValueType[] => {
    const values = [] as ValueType[];
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        values.push(child.props.name);
      }
    });
    return values;
  };
  const getIsVisibleBackdrop = (): boolean => {
    const values = getValues();
    const index = values.indexOf(value);
    return index > -1;
  };

  const onChangeHandle = (newVal: ValueType): void => {
    onChange(newVal);
  };
  const onSetBackdropPosition = (position: PositionType): void => {
    const containerPosition = getPosition(ref.current);
    setBackdropPosition({
      ...position,
      left: position.left - containerPosition.left,
      top: position.top - containerPosition.top,
    });
  };
  return (
    <DefaultThemed>
      <TabsProvider
        value={{
          value: value,
          duration: duration,
          tabStyle: tabStyle,
          activeTabStyle: activeTabStyle,
          onChange: onChangeHandle,
          setBackdropPosition: onSetBackdropPosition,
        }}
      >
        <StyledTabsContainer ref={ref}>
          <StyledBackdrop
            duration={duration}
            backdropStyle={backdropStyle}
            isVisible={getIsVisibleBackdrop()}
            top={backdropPosition.top}
            offsetHeight={backdropPosition.offsetHeight}
            left={backdropPosition.left}
            offsetWidth={backdropPosition.offsetWidth}
          />
          <StyledTabsWrapper styles={style}>{children}</StyledTabsWrapper>
        </StyledTabsContainer>
      </TabsProvider>
    </DefaultThemed>
  );
};
