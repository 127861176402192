import * as React from 'react';
import InputMask, { InputState } from 'react-input-mask';
import {
  BeeTextInputProps,
  BeeTextInputPropsWithRef,
} from '../../BeeTextInput.types';
import { withInputContext } from '../../hoc/withInputContext';
import { BeeTextInputStyled } from './BeeInput.styled';

const Input: React.FC<BeeTextInputPropsWithRef> = ({
  maskProps,
  type,
  autoFocus,
  inputMode,
  onChange,
  value,
  onFocus,
  onBlur,
  disabled,
  onMouseOver,
  onMouseLeave,
  onKeyPress,
  placeholder,
  passedRef,
  ...props
}: BeeTextInputPropsWithRef): JSX.Element => {
  if (maskProps?.mask) {
    const beforeMaskedValueChange = (
      newState: InputState,
      oldState: InputState,
      userInput: string,
    ): InputState => {
      const isPhoneNumberField = type === 'tel';
      const { value } = newState;
      const selection = newState.selection;
      const hasPrefix = userInput && !/\+48\d{9}/.test(userInput);
      return {
        value:
          !isPhoneNumberField || hasPrefix || userInput === null
            ? value
            : `+48${userInput}`,
        selection,
      };
    };

    return (
      <InputMask
        {...maskProps}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        disabled={disabled}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        beforeMaskedValueChange={beforeMaskedValueChange}
      >
        {(inputProps: BeeTextInputProps) => (
          <BeeTextInputStyled
            ref={passedRef}
            {...inputProps}
            {...props}
            type={type}
            inputMode={inputMode}
          />
        )}
      </InputMask>
    );
  }
  return (
    <BeeTextInputStyled
      ref={passedRef}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      placeholder={placeholder}
      onKeyPress={onKeyPress}
      type={type}
      inputMode={inputMode}
      {...props}
    />
  );
};

export const BeeInputWithContext = withInputContext<BeeTextInputPropsWithRef>(
  Input,
  ['inputState', 'withAnimatedLabel'],
);

const BeeInputForwardRefFun = (
  props: BeeTextInputPropsWithRef,
  ref: React.ForwardedRef<HTMLInputElement>,
) => <BeeInputWithContext passedRef={ref} {...props} />;

const BeeInputWithRef = React.forwardRef<
  HTMLInputElement,
  BeeTextInputPropsWithRef
>(BeeInputForwardRefFun);

export { BeeInputWithRef as BeeInput };
