import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { Picker } from '../../BeeDatePicker.const';
import { TodayButtonProp } from './RenderPicker.types';

export const StyledPicker = styled.div<{ pickerZIndex?: number }>`
  background-color: #fff;
  z-index: ${({ pickerZIndex }) =>
    isMobile ? pickerZIndex ?? 100 : pickerZIndex ?? 2};

  ${isMobile
    ? css`
        height: 100vh;
        left: 0;
        overflow-y: auto;
        padding-bottom: 0.75rem;
        position: fixed;
        top: 0;
        width: 100vw;
      `
    : css`
        border-radius: 0.5rem;
        box-shadow: ${({ theme }) => theme.shadow.dropdown};
        padding: 1.5rem;
        position: absolute;
        width: 100%;
      `}
`;

export const StyledPickerBody = styled.div`
  padding: 7.5rem 0 3.5rem 0 !important;
`;

export const StyledCalendarButton = styled.button<TodayButtonProp>`
  border: none;
  border-radius: 1.6rem;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  text-align: center;

  ${({ theme, isToday, selected, isDisabled }) => css`
    background-color: ${isToday && !selected
      ? theme.palette.commons.tonal.base
      : selected
      ? theme.palette.brand.color
      : theme.palette.commons.background.container};
    color: ${selected
      ? theme.palette.commons.background.container
      : theme.palette.input.border.hover};
    font-family: ${theme.typography.fontFamily};
    opacity: ${isDisabled ? 0.2 : 1};
    pointer-events: ${isDisabled ? 'none' : 'auto'};

    &:focus {
      border: 0.0625rem dashed ${theme.shadow.contentBox};
      outline: none;
    }
    &:hover {
      background-color: ${theme.palette.button.background.select.selected};
      color: ${theme.palette.input.text.selected};
    }
  `}

  ${({ picker }) =>
    picker === Picker.DAY &&
    isMobile &&
    css`
      height: 2.5rem;
      margin: 0 auto;
      width: 2.5rem;
    `}
`;
