import { useEffect } from 'react';

export const useWindowScrollResizeEvent = (
  listener: () => void,
  dependencies: unknown[],
  options: AddEventListenerOptions = {
    passive: true,
  },
): void => {
  useEffect(() => {
    window.addEventListener('scroll', listener, options);
    window.addEventListener('resize', listener, options);
    window.addEventListener('wheel', listener, options);
    return () => {
      window.removeEventListener('scroll', listener, false);
      window.removeEventListener('resize', listener, false);
      window.removeEventListener('wheel', listener, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
