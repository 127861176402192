import { RefObject, useEffect, useState } from 'react';

export function useCheckFocus<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
): boolean {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  useEffect(() => {
    const element = ref.current;
    const listener = (): void => {
      const isFocused = !!element?.contains(document.activeElement);
      setIsFocused(isFocused);
    };
    element?.addEventListener('focusin', listener);
    element?.addEventListener('focusout', listener);
    return () => {
      element?.removeEventListener('focusin', listener, false);
      element?.removeEventListener('focusout', listener, false);
    };
  }, [ref]);
  return isFocused;
}
export function useFocus(): {
  focusNextElementInDOM: (target?: HTMLElement) => void;
  focusPrevElementInDOM: (target?: HTMLElement) => void;
} {
  const focusElementInDOM = (pos: number, target?: HTMLElement): void => {
    const activeElement = target ? target : document.activeElement;
    const focussableElements =
      'a:not([disabled]), button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
    const focussable = Array.prototype.filter.call(
      document.querySelectorAll(focussableElements),
      function (element) {
        //check for visibility while always include the current activeElement
        return (
          element.offsetWidth > 0 ||
          element.offsetHeight > 0 ||
          element === activeElement
        );
      },
    );

    const index = focussable.indexOf(activeElement);
    if (index > -1) {
      const element = focussable[index + pos] || focussable[0];
      (element as HTMLElement).focus();
    }
  };
  const focusNextElementInDOM = (target?: HTMLElement): void => {
    focusElementInDOM(1, target);
  };
  const focusPrevElementInDOM = (target?: HTMLElement): void => {
    focusElementInDOM(-1, target);
  };
  return { focusNextElementInDOM, focusPrevElementInDOM };
}

export default {
  useFocus,
  useCheckFocus,
};
