import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import {
  StyledOfferCheckBoxIconProps,
  StyledOfferCheckBoxWrapperProps,
  TooltipPositionType,
} from './OfferCheckBox.types';

const setPosition = (tooltipPosition: TooltipPositionType): string => {
  switch (tooltipPosition) {
    case 'right':
      return `
        top: 50%;
        left: 130%;
        transform: translateY(-50%);
      `;
    case 'left':
      return `
        top: 50%;
        right: 130%;
        transform: translateY(-50%);
      `;
    case 'bottom':
      return `
        top: 130%;
        left: 0;
        bottom: 0; 
        right: 0;
      `;
    default:
      return '';
  }
};

const setAnimationByPosition = (
  tooltipPosition: TooltipPositionType,
): string => {
  switch (tooltipPosition) {
    case 'right':
      return 'left: 120%;';
    case 'left':
      return 'right: 120%;';
    case 'bottom':
      return 'top: 110%;';
    default:
      return '';
  }
};

const getIconstyle = () => `
  transition: all 0.1s ease;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  content: '';
  position: absolute;
  width: 1rem;
  height: 0.12rem;
  left: 50%;
  top: 50%;
`;

export const StyledOfferCheckBoxWrapper = styled.div<StyledOfferCheckBoxWrapperProps>`
  background-color: ${(p) =>
    p.active
      ? p.theme.palette.brand.color
      : p.theme.palette.commons.tonal.base};
  border-radius: 50%;
  cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
  font-family: ${(p) => p.theme.typography.fontFamily};
  height: 1.75rem;
  position: relative;
  transition: all 0.3s ease;
  width: 1.75rem;

  :before {
    color: ${(p) => p.theme.palette.brand.color};
    content: '${(p) => p.tooltipText}';
    font-size: 0.75rem;
    opacity: ${(p) => (p.tooltipAlwaysVisible ? '1' : '0')};
    position: absolute;
    text-align: center;
    transition: all 0.3s ease;
    visibility: ${(p) => (p.tooltipAlwaysVisible ? 'visible' : 'hidden')};
    white-space: nowrap;
    width: fit-content;
    ${(p) => setPosition(p.tooltipPosition)};
  }

  :hover {
    background-color: ${(p) =>
      !p.active && !isMobile && p.theme.palette.commons.tonal.hover};

    :before {
      opacity: 1;
      visibility: visible;
      ${(p) => setAnimationByPosition(p.tooltipPosition)};
    }

    span {
      ${(p) =>
        p.active &&
        !isMobile &&
        !p.disabled &&
        `
        :before,
        :after {
          transform: translate(-50%, -50%);
          width: 1rem;
        }
        :before {
          background-color: transparent;
        }`}
    }
  }
`;

export const StyledOfferCheckBoxIcon = styled.span<StyledOfferCheckBoxIconProps>`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  :before,
  :after {
    ${getIconstyle()};
    background-color: ${(p) =>
      p.active
        ? p.theme.palette.commons.background.container
        : p.theme.palette.switch.text.inactive};
  }

  ${(p) =>
    !p.disabled &&
    `
  :hover {
    :before,
    :after {
      ${getIconstyle()};
      background-color: ${
        p.active
          ? p.theme.palette.commons.background.container
          : p.theme.palette.brand.color
      };
    }

    :before {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  
  `}

  :before {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  ${(p) =>
    p.active &&
    `:before {
      transform: translate(-30%,-40%) rotate(135deg);
      width: 0.9rem;
    }
    :after {
      transform: translate(-100%,60%) rotate(40deg);
      width: 0.55rem;
    }`}
`;
