import { BeeFieldStyles, BeeFieldState } from './BeeField.types';

export const DEFAULT_FIELD_STYLES: BeeFieldStyles = {
  hover: {},
  focused: {
    borderWidth: '0.125rem',
  },
  checked: {},
  error: {},
  default: {
    borderWidth: '0.0625rem',
  },
};

export const DEFAULT_FIELD_STATE = BeeFieldState.INACTIVE;
