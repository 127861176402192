import * as React from 'react';
import { safeWindow } from '../utils/safeWindow';

export interface WindowDimensionsProp {
  width: number;
  height: number;
}

export const useWindowDimensions = (): WindowDimensionsProp => {
  const getWindowDimensions = (): WindowDimensionsProp => {
    const width = safeWindow.outerWidth ?? 0;
    const height = safeWindow.outerHeight ?? 0;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions(),
  );

  const handleResize = (): void => {
    setWindowDimensions(getWindowDimensions());
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
