import React, { FC, PropsWithChildren } from 'react';
import { StickerStyled as StickerStyledRaw } from './Sticker.styled';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';

export const StickerStyled: FC<PropsWithChildren<unknown>> = (
  props: PropsWithChildren<unknown>,
) => {
  return (
    <DefaultThemed>
      <StickerStyledRaw>{props.children}</StickerStyledRaw>
    </DefaultThemed>
  );
};
