import React, { useEffect, useRef } from 'react';
import {
  CookieBarButton,
  CookieInfoBarButtonsWrapper,
  CookieModalInfoBarContainer,
  CookieModalInfoBarText,
  CookieModalInfoBarWrapper,
} from './CookieModalInfoBar.styles';
import { useCookieModalContext } from '../../context/useCookieModalContext';
import { StyleTextByType } from '../CookiePolicyMarkdownParagraph/paragraphComponents/StyleTextByType/StyleTextByType';
import { useElementHeightListener } from '../../../Hooks/useElementHeightListener';

export const CookieModalInfoBar: React.FC = () => {
  const {
    translatedText,
    setIsSettingMode,
    acceptAll,
    cookieBarDescription,
    externalHandlers,
    isSettingMode,
    areConsentsChosen,
  } = useCookieModalContext();
  const elementRef = useRef<HTMLDivElement>(null);

  useElementHeightListener(elementRef, externalHandlers.listenCookieBarHeight);

  const shouldBeVisible = !isSettingMode && !areConsentsChosen;

  useEffect(() => {
    externalHandlers.onCookieBarVisible?.(shouldBeVisible);
  }, [shouldBeVisible]);

  return (
    <CookieModalInfoBarContainer ref={elementRef} isVisible={shouldBeVisible}>
      <CookieModalInfoBarWrapper>
        <CookieModalInfoBarText>
          {cookieBarDescription.map((markdownContent, index) => (
            <StyleTextByType
              key={`${markdownContent.type}-${index}`}
              markdownContent={markdownContent}
            />
          ))}
        </CookieModalInfoBarText>
        <CookieInfoBarButtonsWrapper>
          <CookieBarButton
            typeStyle="outlinedWithoutBackground"
            size="small"
            onClick={() => setIsSettingMode(true)}
          >
            {translatedText.SET_PREFERENCE}
          </CookieBarButton>
          <CookieBarButton typeStyle="primary" size="small" onClick={acceptAll}>
            {translatedText.ACCEPT}
          </CookieBarButton>
        </CookieInfoBarButtonsWrapper>
      </CookieModalInfoBarWrapper>
    </CookieModalInfoBarContainer>
  );
};
