import styled from 'styled-components';

export const CookieModalConsentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const CookieModalArrowContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;
`;

export const CookieModalConsentInfo = styled.div<{ isOpen: boolean }>`
  background-color: ${({ theme }) => theme.palette.commons.background.infotip};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.palette.text.paragraph.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 0.75rem;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
  max-height: 0;
  overflow: hidden;
  padding: 0 0.75rem;
  text-align: justify;
  transition: 0.17s ease-out;
  ${({ isOpen }) =>
    isOpen &&
    `
    padding: 1rem 0.75rem;
    max-height: 50rem;
    transition: 0.17s ease-in;
  `}
  @media(max-width: 589px) {
    margin-left: 0rem;
  }
`;
