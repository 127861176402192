import React, { useState, useEffect } from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import {
  AnimatedLayer,
  CheckBoxElement,
  InputCheckbox,
  LabelElement,
} from './CheckBox.styled';
import { CheckBoxProps } from './CheckBox.types';

export const CheckBox: React.FC<CheckBoxProps> = ({
  checked,
  disabled,
  column,
  text,
  name,
  id,
  onChange,
  required,
  dataE2e,
  checkBoxTopSelfAlignment,
}: CheckBoxProps) => {
  const [value, setValue] = useState<boolean>();
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setValue(checked ?? false);
  }, [checked]);

  const handleChange = (newValue: boolean): void => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    setValue(Boolean(checked));
  }, [checked]);

  useEffect(() => {
    setIsInvalid(!!required && !value);
  }, [required, value]);

  return (
    <DefaultThemed>
      <LabelElement htmlFor={name} column={column}>
        <CheckBoxElement checkBoxTopSelfAlignment={checkBoxTopSelfAlignment}>
          <InputCheckbox
            id={id}
            name={name}
            checked={value}
            disabled={disabled}
            onChange={() => handleChange(!value)}
            required={required}
            isInvalid={isInvalid}
            data-e2e={`${dataE2e}Checked`}
          />
          <AnimatedLayer />
        </CheckBoxElement>
        {text}
      </LabelElement>
    </DefaultThemed>
  );
};
