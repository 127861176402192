import { isMobile } from 'react-device-detect';
import styled, { keyframes } from 'styled-components';
import { Typography } from '../Typography';

const visibleAnimation = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

export const TooltipTitle = styled(Typography)`
  animation-duration: 0.25s;
  animation-name: ${visibleAnimation};
  animation-timing-function: linear;
  background-color: ${({ theme }) => theme.palette.commons.tonal.base};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }) => theme.shadow.modal};
  color: ${({ theme }) => theme.palette.text.paragraph.primary};
  display: inline-block;
  left: ${() => (!isMobile ? '0' : '-10rem')};
  padding: 0.5rem;

  position: absolute;
  top: 1rem;
  z-index: 10;
`;

export const TooltipWrapper = styled.div`
  position: relative;
  p {
    color: ${({ theme }) => theme.palette.text.paragraph.primary};
  }
`;
