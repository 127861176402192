export type CallbackType = () => void;

export const onKeyDown = (
  key: string,
  e: React.KeyboardEvent,
  callback: CallbackType,
): void => {
  if (e.key === key) {
    callback();
  }
};
export const onKeyDownEscape = (
  e: React.KeyboardEvent,
  callback: CallbackType,
): void => {
  onKeyDown('Escape', e, callback);
};

export const onKeyDownEnter = (
  e: React.KeyboardEvent,
  callback: CallbackType,
): void => {
  onKeyDown('Enter', e, callback);
};

export const onKeyDownArrowDown = (
  e: React.KeyboardEvent,
  callback: CallbackType,
): void => {
  onKeyDown('ArrowDown', e, callback);
};
export const onKeyDownArrowUp = (
  e: React.KeyboardEvent,
  callback: CallbackType,
): void => {
  onKeyDown('ArrowUp', e, callback);
};
export const onKeyDownTab = (
  e: React.KeyboardEvent,
  callback: CallbackType,
): void => {
  onKeyDown('Tab', e, callback);
};
export const onKeyDownShift = (
  e: React.KeyboardEvent,
  callback: CallbackType,
): void => {
  onKeyDown('Shift', e, callback);
};
