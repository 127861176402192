import styled from 'styled-components';
import { MenuElProps } from './Menu.types';

export const StyledMenuContainer = styled('div')`
  outline: 0;
`;
export const StyledMenuWrapper = styled('div')<MenuElProps>`
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  left: ${(props) => props.left};
  max-height: 100vh;
  max-width: 100vw;
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  outline: 0;
  overflow: visible;
  position: fixed;
  top: ${(props) => props.top};
  transition: opacity 0.3s ease-out;
  z-index: ${(props) => props.zIndex};
  ${(props) => (!props.isVisible ? 'opacity: 0;' : '')};
`;
