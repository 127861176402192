import styled, { Keyframes, keyframes } from 'styled-components';

export type ProgressCircularStyledProps = {
  circumference: number;
  height: number;
  width: number;
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const dashAnimation = (circumference: number): Keyframes => keyframes`
  0% {
    stroke-dasharray: ${circumference / 2}, ${circumference};
    stroke-dashoffset: ${circumference};
  }
  50% {
    stroke-dasharray: ${circumference / 2}, ${circumference};
    stroke-dashoffset: ${circumference - 0.5 * circumference};
  }
  100% {
    stroke-dasharray: ${circumference / 2}, ${circumference};
    stroke-dashoffset: ${circumference - 1.5 * circumference};
  }
`;

export const ProgressCircularStyled = styled.div<ProgressCircularStyledProps>`
  & {
    .circle {
      animation: ${rotate} 2s linear infinite,
        ${(props) => dashAnimation(props.circumference)} 2s linear infinite;
      transform-origin: center;
    }
  }
  height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.width}px`};
`;
