import styled, { css } from 'styled-components';
import { ReactComponent as Icon } from '../assets/svg/drop.svg';

export const StyledLabel = styled.div`
  align-items: center;
  display: flex;
`;
export const StyledLabelContent = styled.div<{ isLoading?: boolean }>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 3.5rem;
  padding: 0.3rem 1rem;
  width: 100%;

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: wait;
    `}
`;
export const StyledInput = styled.input`
  border: none;
  flex: 1 1 0%;
  height: 3rem;
  outline: 0;
  padding-left: 0.25rem;
  width: auto;
`;
export const StyledOptionsContainer = styled.div`
  background: ${(p) => p.theme.palette.commons.background.container};
  border-radius: 0.5rem;
  box-shadow: ${(p) => p.theme.shadow.contentBox};
  display: flex;
  flex-direction: column;
  font-family: ${(p) => p.theme.typography.fontFamily};
  gap: 0.5rem;
  max-height: 19.3125rem;
  overflow: auto;
  padding: 0.5rem;
`;

export const DropIcon = styled(Icon)<{ isOpened: boolean }>`
  transition: transform 0.2s ease;

  ${({ isOpened }) =>
    isOpened &&
    css`
      transform: rotate(180deg);
    `}
`;
