import {
  endOfDay,
  endOfMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  startOfMonth,
} from 'date-fns';
import { DateFromPickerType, DatesRange } from '../BeeDatePicker.types';
import { MAX_END_YEAR, MIN_START_YEAR } from '../components';
import { convertToTwoDigitsString } from './convertToTwoDigitsString';
import { getDate } from './getDate';

export function isDayInRange(
  day: string,
  { year, month }: DateFromPickerType,
  { minDate, maxDate }: DatesRange,
): boolean {
  const date = getDate(year, month, day);

  return (
    (!minDate ||
      isAfter(date, new Date(minDate.setHours(0, 0, 0, 0))) ||
      isEqual(date, minDate)) &&
    (!maxDate || isBefore(date, endOfDay(maxDate)) || isEqual(date, maxDate))
  );
}

export function isMonthInRange(
  year: string,
  monthIndex: number,
  { minDate, maxDate }: DatesRange,
): boolean {
  const date = getDate(year, convertToTwoDigitsString(monthIndex + 1));

  return (
    (!minDate ||
      isAfter(date, startOfMonth(minDate)) ||
      isEqual(date, minDate)) &&
    (!maxDate || isBefore(date, endOfMonth(maxDate)) || isEqual(date, maxDate))
  );
}

export function isYearInRange(
  year: number,
  { minDate, maxDate }: DatesRange,
): boolean {
  const minDateYear = minDate ? getYear(minDate) : MIN_START_YEAR;
  const maxDateYear = maxDate ? getYear(maxDate) : MAX_END_YEAR;

  return year >= minDateYear && year <= maxDateYear;
}
