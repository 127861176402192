import {
  PositionXYType,
  YVariantsType,
  XVariantsType,
  XYVariantsType,
} from '../Menu.types';

export const getAnchorOrSelfPositionName = (
  val: XYVariantsType,
): PositionXYType => {
  const yVariants: YVariantsType[] = ['top', 'center', 'bottom'];
  const xVariants: XVariantsType[] = ['left', 'right', 'middle'];
  let positionY: YVariantsType = 'bottom';
  let positionX: XVariantsType = 'left';
  for (const variant of yVariants) {
    const index = val.search(variant);
    if (index > -1) {
      positionY = variant;
      break;
    }
  }
  for (const variant of xVariants) {
    const index = val.search(variant);
    if (index > -1) {
      positionX = variant;
      break;
    }
  }
  return {
    positionY,
    positionX,
  };
};
