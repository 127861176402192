import React from 'react';
import { CustomCheckboxProps } from './CookieModalCheckBox.types';
import {
  CookieModalCheckboxContainer,
  CookieModalCheckBoxFrame,
  CookieModalCheckBoxLabel,
  CookieModalCheckIcon,
} from './CookieModalCheckBox.styles';

export const CookieModalCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onClick,
  label,
  isDisabled,
}) => {
  return (
    <CookieModalCheckboxContainer
      data-testid={'CookieModalCheckboxContainer'}
      onClick={onClick}
    >
      <CookieModalCheckBoxFrame checked={checked} isDisabled={isDisabled}>
        <CookieModalCheckIcon checked={checked} isDisabled={isDisabled} />
      </CookieModalCheckBoxFrame>
      <CookieModalCheckBoxLabel>{label}</CookieModalCheckBoxLabel>
    </CookieModalCheckboxContainer>
  );
};
