import { ElementProperties } from '../Menu.types';

export const getElementProperties = (
  el: HTMLElement | null,
): ElementProperties => {
  return {
    width: el?.offsetWidth || 0,
    height: el?.offsetHeight || 0,
  };
};
