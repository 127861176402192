import * as React from 'react';
import { FC, PropsWithChildren } from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { ImgContainer, InfoLineContainer } from './Infoline.styled';

export interface InfoLineProps {
  icon?: React.SVGProps<SVGSVGElement>;
  text: string;
}

export const InfoLine: FC<PropsWithChildren<InfoLineProps>> = (
  props: PropsWithChildren<InfoLineProps>,
): React.ReactElement => {
  return (
    <DefaultThemed>
      <InfoLineContainer>
        {props.children && <ImgContainer>{props.children}</ImgContainer>}
        {props.text}
      </InfoLineContainer>
    </DefaultThemed>
  );
};
