import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { StyledToastContainer } from './NotificationService.styled';

export const Notifycator: React.FC = () => (
  <StyledToastContainer
    position="top-center"
    autoClose={3000}
    closeButton={<></>}
    hideProgressBar={true}
  />
);
