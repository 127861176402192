import styled from 'styled-components';
import { StyledIconProps } from './Icon.types';

export const IconStyled = styled('div')<StyledIconProps>`
  align-items: center;
  background-color: ${({ bgColor, theme }) =>
    bgColor ?? theme.palette.commons.UE};
  display: flex;
  height: ${(props) => (props.inputState === 'focused' ? '3.6rem' : '3.5rem')};
  justify-content: center;
  margin: ${(props) =>
    props.inputState === 'focused' ? '-0.1rem 0 0 -0.1rem' : '0'};
  overflow: hidden;
  width: ${({ orientation, sizes }) =>
    orientation === 'left' ? sizes.left : sizes.right};
`;
