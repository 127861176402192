import styled, { CSSProperties } from 'styled-components';
import { MEDIA_QUERY } from '../Theme/utils';
import { Theme } from '../Theme/utils/theme.type';
import { ModalProps } from './Modal.types';

const getBlurredModal = (theme: Theme) => `
&:before {
  backdrop-filter: blur(10px);
  background-color: ${theme.palette.commons.background.dimmed};
  opacity: 0.6;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}
`;

export const StyledModal = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

  ${({ isVisible }) => !isVisible && 'display: none'}

  ${(p) => p.isBlurred && p.isVisible && getBlurredModal(p.theme)}
`;

export const StyledModalWrapper = styled.div<{ parentStyles?: CSSProperties }>`
  display: flex;
  height: auto;
  left: auto;
  margin: auto;
  max-height: 100vh;
  position: relative;
  top: auto;

  ${({ parentStyles }) => parentStyles && { ...parentStyles }}

  ${MEDIA_QUERY.below('sm')} {
    min-height: 100%;
  }
`;

export const StyledWrapper = styled.div<ModalProps>`
  background-clip: padding-box;
  background-color: ${(p) => p.theme.palette.commons.background.container};
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  padding: 1.5rem;

  position: relative;
  width: 100%;
  ${MEDIA_QUERY.above('sm')} {
    border-radius: 1rem;
    box-shadow: ${(p) => p.theme.shadow.modal};
    display: flex;
    max-height: 100%;
    padding: 3rem;
    width: ${({ isNarrow }) => (isNarrow ? '29.125rem' : '49.063rem')};
  }
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem;
  }

  ${MEDIA_QUERY.below('sm')} {
    flex-grow: 1;
  }
`;

export const StyledModalCloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: auto;
  outline: none;
  padding: 1rem;
  position: absolute;
  right: 3rem;
  top: 3rem;
  transform: translate(2rem, -2rem);
  & > svg {
    fill: ${(p) => p.theme.palette.brand.color};
    height: 1rem;
    width: 1rem;
  }
  ${MEDIA_QUERY.below('sm')} {
    padding: 0;
  }
`;
