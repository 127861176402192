import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import useOnClickOutside from '../Hooks/useOnClickOutside';
import { useWindowScrollResizeEvent } from '../Hooks/useWindowScrollResizeEvent';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { onKeyDownEnter, onKeyDownEscape } from '../utils/onKeyDown.utils';
import { safeWindow } from '../utils/safeWindow';
import { StyledMenuContainer, StyledMenuWrapper } from './Menu.styled';
import { MenuProps } from './Menu.types';
import { getPositionProperties } from './utils/getPositionProperties';

export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  (
    {
      el,
      children,
      onChange,
      target,
      value,
      anchor = 'bottom left',
      self = 'top left',
      offsetTop = 0,
      hoverable = false,
      hideOnScroll = true,
      autoWidth = true,
      withinTheScreen = true,
      zIndex = 10,
      onKeyDown,
    }: MenuProps,
    refProp,
  ) => {
    const contentRef = useRef(null);
    const labelRef = useRef(null);
    const [isRenderedPortal, setIsRenderedPortal] = useState(value);
    const [isVisible, setIsVisible] = useState(false);
    const [width, setWidth] = useState(0);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const isOpened = isVisible && isRenderedPortal;
    const refLocal = useRef<HTMLDivElement>(null);
    const ref = refProp ? refProp : refLocal;
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const body = safeWindow.document?.body;

    const setPosition = useCallback((): void => {
      const current = labelRef.current ? labelRef.current : document.body;
      const parent = target ? target : current;
      const positions = getPositionProperties(
        parent,
        anchor,
        self,
        contentRef.current,
        offsetTop,
        autoWidth,
        withinTheScreen,
      );
      setWidth(positions.width);
      setLeft(positions.left);
      setTop(positions.top);
    }, [anchor, autoWidth, offsetTop, self, target, withinTheScreen]);

    const show = useCallback((): void => {
      setIsRenderedPortal(true);
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = global.setTimeout(() => {
        setPosition();
        setIsVisible(true);
      }, 0);
    }, [setPosition]);

    const hide = useCallback((): void => {
      if (isRenderedPortal) {
        setIsVisible(false);
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = global.setTimeout(() => {
          setIsRenderedPortal(false);
        }, 300);
      }
    }, [isRenderedPortal]);

    const onClick = (): void => {
      !hoverable && onChange && onChange(!isVisible);
    };

    const onMouseEnter = (): void => {
      hoverable && onChange && onChange(true);
    };

    const onMouseLeave = (): void => {
      hoverable && onChange && onChange(false);
    };

    const handleScrollOrResize = (): void => {
      hideOnScroll && onChange && onChange(false);
      isRenderedPortal && setPosition();
    };

    useEffect(() => {
      value ? show() : hide();
    }, [hide, show, value]);

    useEffect(() => {
      setPosition();
    }, [target, offsetTop, anchor, self, children, el, setPosition]);

    useWindowScrollResizeEvent(handleScrollOrResize, [
      target,
      offsetTop,
      anchor,
      self,
      isRenderedPortal,
    ]);

    const handleClickOutside = (): void => {
      !hoverable && onChange && onChange(false);
    };

    const onKeyDownHandle = (e: React.KeyboardEvent): void => {
      onKeyDownEnter(e, () => {
        onChange && !isOpened && onChange(true);
      });
      onKeyDownEscape(e, () => {
        onChange && onChange(false);
      });
      onKeyDown && onKeyDown(e);
    };

    useOnClickOutside(contentRef, handleClickOutside, labelRef);

    const componentJsx =
      body &&
      createPortal(
        <DefaultThemed>
          <StyledMenuWrapper
            isRenderedPortal={!isRenderedPortal}
            isVisible={isVisible}
            ref={contentRef}
            minWidth={autoWidth ? `${width}px` : ''}
            top={`${top}px`}
            left={`${left}px`}
            zIndex={zIndex}
          >
            {children}
          </StyledMenuWrapper>
        </DefaultThemed>,
        target || body,
      );

    return (
      <StyledMenuContainer
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onKeyDown={onKeyDownHandle}
        tabIndex={0}
        ref={ref}
      >
        {React.cloneElement(el, {
          onClick: () => {
            el.props.onClick &&
              typeof el.props.onClick === 'function' &&
              el.props.onClick();
            onClick();
          },
          ref: labelRef,
        })}
        {isRenderedPortal && componentJsx}
      </StyledMenuContainer>
    );
  },
);

Menu.displayName = 'Menu';

export default Menu;
