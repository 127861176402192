import React from 'react';
import { CookiePolicyDescription } from '../../types';
import {
  CookieDescriptionItemContainer,
  CookieDescriptionItemTitle,
} from './CookiePolicyDescriptionItem.styles';
import { CookiePolicyMarkdownParagraph } from '../CookiePolicyMarkdownParagraph/CookiePolicyMarkdownParagraph';

export const CookiePolicyDescriptionItem: React.FC<{
  description: CookiePolicyDescription;
}> = ({ description }) => {
  return (
    <CookieDescriptionItemContainer>
      <CookieDescriptionItemTitle>
        {description.title}
      </CookieDescriptionItemTitle>
      <div>
        {description.paragraphs.map((paragraph, index) => (
          <CookiePolicyMarkdownParagraph
            key={`${paragraph.type}-${index}`}
            paragraph={paragraph}
          />
        ))}
      </div>
    </CookieDescriptionItemContainer>
  );
};
