import styled from 'styled-components';

export const CookieMarkdownLinkedText = styled.a`
  color: ${({ theme }) => theme.palette.text.link};
  font-wight: bold;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.palette.button.background.secondary.hover};
  }
`;
