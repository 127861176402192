import styled, { DefaultTheme } from 'styled-components';
import { AnimatedLabelProps } from './AnimatedLabel.types';

const color = (
  theme: DefaultTheme,
  readOnly = false,
  error?: string,
  focused = false,
  visible = false,
  isValid = false,
  isHovered = false,
): string => {
  if (readOnly) {
    return theme.palette.input.border.base;
  } else if (error && !focused && visible) {
    return theme.palette.error.text.base;
  } else if (focused || isValid) {
    return theme.palette.input.border.selected;
  } else if (isHovered) {
    return `${theme.palette.input.border.hover} !important`;
  } else {
    return theme.palette.input.text.base;
  }
};

export const StyledTextInputWrapperWrapper = styled('div')<{
  fullWidth?: boolean;
}>`
  font-family: ${(p) => p.theme.typography.fontFamily};
  max-width: ${({ fullWidth }) => (fullWidth ? 'none' : '23.25rem;')};
  position: relative;
  width: 100%;
`;

export const AnimatedLabelStyledWrapper = styled.div<AnimatedLabelProps>`
  align-items: center;
  color: ${({ theme, readOnly, error, focused, visible, isValid, isHovered }) =>
    color(theme, readOnly, error, focused, visible, isValid, isHovered)};
  display: flex;
  height: 100%;
  padding-left: 1rem;
  pointer-events: none;
  position: absolute;
  transition: font-size 0.2s, top 0.2s;
  ${({ visible }) =>
    visible &&
    `
      font-size: 75%;
      top: -50%;
  `}
  ${({ visible }) =>
    !visible &&
    `
      font-size: 100%;
      top: 0;

  `}
`;

export const AnimatedLabelStyled = styled.div`
  background-color: white;
  padding: 0rem 0.3rem;
  width: 100%;
`;
