import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const StyledToastContainer = styled(ToastContainer)`
  width: auto;

  .Toastify__toast {
    min-height: 3rem;

    &-icon {
      svg {
        fill: ${(p) => p.theme.palette.commons.background.container};
      }
    }
  }
`;
