import React, { useState } from 'react';
import {
  CookiePolicyDescriptionContainer,
  CookieModalSeeMoreButton,
} from './CookiePolicyDescriptionComponent.styles';
import { useCookieModalContext } from '../../context/useCookieModalContext';
import { CookiePolicyDescriptionItem } from '../CookiePolicyDescriptionItem/CookiePolicyDescriptionItem';
import { CookiePolicyDescriptionText } from '../CookiePolicyMarkdownParagraph/paragraphComponents/paragraphComponents.styles';
import { getStartDescriptionText } from './utils/getStartDescriptionText';

const THE_FIRST_DESCRIPTION_INDEX = 0;

export const CookiePolicyDescriptionComponent: React.FC = () => {
  const { translatedText, policyDescription } = useCookieModalContext();

  const [showPolicyInfo, setShowPolicyInfo] = useState(false);

  const startDescriptionText = getStartDescriptionText(
    policyDescription[THE_FIRST_DESCRIPTION_INDEX],
  );

  return (
    <>
      <CookiePolicyDescriptionText>
        {!showPolicyInfo && startDescriptionText}
      </CookiePolicyDescriptionText>
      <CookiePolicyDescriptionContainer isOpen={showPolicyInfo}>
        {policyDescription.map((description) => (
          <CookiePolicyDescriptionItem
            key={description.title}
            description={description}
          />
        ))}
      </CookiePolicyDescriptionContainer>
      <CookieModalSeeMoreButton
        onClick={() => setShowPolicyInfo(!showPolicyInfo)}
      >
        {!showPolicyInfo ? translatedText.SEE_MORE : translatedText.SEE_LESS}
      </CookieModalSeeMoreButton>
    </>
  );
};
