import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import React from 'react';
import { Picker } from '../../../../BeeDatePicker.const';
import { usePickerContext } from '../../../../contexts';
import { convertToTwoDigitsString } from '../../../../helpers/convertToTwoDigitsString';
import { isDatePickerFormFilled } from '../../../../helpers/isDatePickerFormFilled';
import { isDayInRange } from '../../../../helpers/isInRange';
import { WEEK } from '../../RenderPicker.const';
import { StyledCalendarButton } from '../../RenderPicker.styled';
import { StyledDayName, StyledMonth } from './DayPicker.styled';

export const DayPicker: React.FC = () => {
  const {
    state: { date, dayNames, today, datesRange },
    dispatch,
  } = usePickerContext();

  const onClickHandler = React.useCallback(
    (numberDay: number): void => {
      const day = convertToTwoDigitsString(numberDay);

      if (day === date.day) {
        dispatch({
          type: 'SET_VISIBLE_PICKER',
          payload: null,
        });

        return;
      }

      dispatch({
        type: 'SET_DATE',
        payload: { day },
      });

      if (isDatePickerFormFilled({ ...date, day })) {
        dispatch({
          type: 'SET_VISIBLE_PICKER',
          payload: null,
        });
      }
    },
    [date, dispatch],
  );

  const generateDatesForCurrentWeek = React.useCallback(
    (currentWeekDate: Date, selectedDate: Date): React.ReactElement[] => {
      const week: React.ReactElement[] = [];

      let currentDate = currentWeekDate;

      for (let i = 0; i < WEEK; i++) {
        const day = format(currentDate, 'd');

        if (!isSameMonth(currentDate, selectedDate)) {
          week.push(<div key={currentDate.toISOString()}></div>);
        } else {
          week.push(
            <StyledCalendarButton
              key={currentDate.toISOString()}
              picker={Picker.DAY}
              value={date.day}
              onClick={() => onClickHandler(Number(day))}
              isToday={isSameDay(currentDate, today)}
              selected={Number(date.day) === Number(day)}
              isDisabled={datesRange && !isDayInRange(day, date, datesRange)}
              type="button"
            >
              {day}
            </StyledCalendarButton>,
          );
        }

        currentDate = addDays(currentDate, 1);
      }
      return week;
    },
    [date, datesRange, today, onClickHandler],
  );

  const getDates = React.useCallback((): React.ReactElement[][] => {
    const selectedDate = new Date(
      `${date.year}/${date.month}/${date.day || 1}`,
    );
    const startOfTheSelectedMonth = startOfMonth(selectedDate);
    const endOfTheSelectedMonth = endOfMonth(selectedDate);
    const startDate = startOfWeek(startOfTheSelectedMonth);
    const endDate = endOfWeek(endOfTheSelectedMonth);
    const dates: React.ReactElement[][] = [];

    let currentDate = startDate;

    while (currentDate <= endDate) {
      dates.push(generateDatesForCurrentWeek(currentDate, selectedDate));
      currentDate = addDays(currentDate, 7);
    }

    return dates;
  }, [date, generateDatesForCurrentWeek]);

  return (
    <StyledMonth>
      {dayNames.map((dayName) => (
        <StyledDayName key={dayName}>{dayName.slice(0, 3)}</StyledDayName>
      ))}
      {getDates().map((week) => week)}
    </StyledMonth>
  );
};
