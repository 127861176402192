import React from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import {
  StyledToggle,
  StyledToggleButton,
  StyledToggleLabelsWrapper,
  StyledToggleWrapper,
} from './Toggle.styled';
import { ToggleProps } from './Toggle.types';

export const Toggle: React.FC<ToggleProps> = ({
  activeLabel,
  neutralLabel,
  onChange,
  active,
  initialState,
  tabIndex,
  dataE2e,
  maxWidth,
  disabled,
}: ToggleProps) => {
  const getInitialToggleState = (): boolean => {
    return typeof initialState === 'boolean'
      ? initialState
      : typeof active === 'boolean'
      ? active
      : false;
  };
  const [isActive, setActive] = React.useState(getInitialToggleState());

  const handleChange = (value: boolean): void => {
    onChange && onChange(value);
    setActive(value);
  };

  React.useEffect(() => {
    if (typeof active === 'boolean' && active !== isActive) {
      setActive(active);
    }
  }, [active, isActive]);

  return (
    <DefaultThemed>
      <StyledToggleWrapper data-e2e={dataE2e} {...{ maxWidth, disabled }}>
        <StyledToggle active={isActive} />
        <StyledToggleLabelsWrapper>
          <StyledToggleButton
            type="button"
            data-e2e="InstallmentsButton1"
            aria-checked={!isActive}
            {...(tabIndex && { tabIndex: tabIndex })}
            active={!isActive}
            onClick={() => handleChange(false)}
          >
            {neutralLabel}
          </StyledToggleButton>
          <StyledToggleButton
            type="button"
            data-e2e="InstallmentsButton2"
            aria-checked={isActive}
            {...(tabIndex && { tabIndex: tabIndex + 1 })}
            active={isActive}
            onClick={() => handleChange(true)}
          >
            {activeLabel}
          </StyledToggleButton>
        </StyledToggleLabelsWrapper>
      </StyledToggleWrapper>
    </DefaultThemed>
  );
};

Toggle.defaultProps = {
  activeLabel: 'TAK',
  neutralLabel: 'NIE',
};
