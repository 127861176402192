import React from 'react';
import { RollUpProps } from './RollUp.types';
import { RollUpButton, RollUpContainer, RollUpIcon } from './RollUp.styled';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';

export const RollUp: React.FC<RollUpProps> = ({
  open,
  checkBox,
  expandIcon,
  children,
  onClick,
  label,
}: RollUpProps) => (
  <DefaultThemed>
    <>
      <RollUpButton onClick={onClick}>
        {checkBox}
        {label}
        {expandIcon && <RollUpIcon open={open}>{expandIcon}</RollUpIcon>}
      </RollUpButton>
      <RollUpContainer open={open}>{children}</RollUpContainer>
    </>
  </DefaultThemed>
);
