import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { ClearButton } from '../shared/mixins';
import { ToggleElProps } from './Toggle.types';

export const StyledToggleWrapper = styled('div')<ToggleElProps>`
  -webkit-tap-highlight-color: ${(p) =>
    p.theme.palette.commons.background.container};
  background-color: ${(p) => p.theme.palette.commons.tonal.base};
  border-radius: 1rem;
  border-radius: ${isMobile ? '18px' : '16px'};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-family: ${(p) => p.theme.typography.fontFamily};
  height: ${isMobile ? '2.25rem' : '3.5rem'};
  max-width: ${(p) => (p.maxWidth ? p.maxWidth : 'unset')};
  opacity: ${(p) => (p.disabled ? '0.5' : '1')};
  pointer-events: ${(p) => (p.disabled ? 'none' : 'inherit')};
  position: relative;
  width: 12rem;
`;

export const StyledToggle = styled('div')<ToggleElProps>`
  background-color: ${(p) => p.theme.palette.switch.background.selected};
  border-radius: inherit;
  box-shadow: ${(p) => p.theme.shadow.switch};
  font-family: ${(p) => p.fontFamily ?? p.theme.typography.fontFamily};
  height: 100%;
  left: ${(p: ToggleElProps) => (p.active ? '50%' : '0%')};
  position: absolute;
  transition: left 0.2s;
  width: 50%;
`;

export const StyledToggleLabelsWrapper = styled('div')<ToggleElProps>`
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
`;

export const StyledToggleButton = styled(ClearButton)<ToggleElProps>`
  align-items: center;
  border-radius: 1rem;
  box-sizing: border-box;
  color: ${(p) =>
    p.active
      ? p.theme.palette.brand.color
      : p.theme.palette.switch.text.inactive};
  display: flex;
  font-family: inherit;
  font-size: ${isMobile ? '0.75rem' : '1rem'};
  font-weight: bold;
  height: 100%;
  justify-content: center;
  transition: border-color 0.2s;
  transition: color 0.2s;
  width: 100%;
`;
