import styled from 'styled-components';

export const ClearButton = styled('button')`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: none;
  outline: none;
  padding: 0;
  &:focus {
    outline: none;
  }
`;
