import React, { useState } from 'react';
import { CollapseProvider } from './Collapse.provider';
import {
  CollapseGroupProps,
  GroupType,
  GroupNameType,
  GroupHashType,
} from './Collapse.types';

export const CollapseGroup: React.FC<CollapseGroupProps> = ({
  children,
  accordion = false,
  noClosible = false,
}: CollapseGroupProps) => {
  const [groups, setGroups] = useState<GroupType[]>([]);
  let hashUnkey: GroupHashType = 0;
  const getHashHandle = (): GroupHashType => {
    hashUnkey++;
    return hashUnkey;
  };
  const addHash = (
    hashes: GroupHashType[],
    hash: GroupHashType,
  ): GroupHashType[] => {
    const hashesCopy = [...hashes];
    const index = hashesCopy.indexOf(hash);
    if (index === -1) {
      hashesCopy.push(hash);
    }
    return hashesCopy;
  };
  const removeHash = (
    hashes: GroupHashType[],
    hash: GroupHashType,
  ): GroupHashType[] => {
    const hashesCopy = [...hashes];
    const index = hashesCopy.indexOf(hash);
    if (index > -1) {
      hashesCopy.splice(index, 1);
    }
    return hashesCopy;
  };
  const removeAllGroupHandle = (group: GroupNameType): void => {
    const groupsCopy = [...groups];
    const index = groupsCopy.map((el) => el.name).indexOf(group);
    if (index > -1) {
      groupsCopy.splice(index, 1);
      setGroups(groupsCopy);
    }
  };
  const addOneHashHandle = (
    group: GroupNameType,
    hash: GroupHashType,
  ): void => {
    const groupsCopy = [...groups];
    const index = groupsCopy.map((el) => el.name).indexOf(group);
    if (index > -1) {
      const hashIndex = groupsCopy[index].hashes.indexOf(hash);
      if (hashIndex > -1) {
        removeAllGroupHandle(group);
      } else {
        groupsCopy[index].hashes = [hash];
        setGroups(groupsCopy);
      }
    } else {
      addHashHandle(group, hash);
    }
  };
  const addHashHandle = (group: GroupNameType, hash: GroupHashType): void => {
    const groupsCopy = [...groups];
    const index = groupsCopy.map((el) => el.name).indexOf(group);
    if (index > -1) {
      groupsCopy[index].hashes = addHash(groupsCopy[index].hashes, hash);
    } else {
      groupsCopy.push({
        name: group,
        hashes: [hash],
      });
    }
    setGroups(groupsCopy);
  };
  const removeHashHandle = (
    group: GroupNameType,
    hash: GroupHashType,
  ): void => {
    const groupsCopy = [...groups];
    const index = groupsCopy.map((el) => el.name).indexOf(group);
    if (index > -1) {
      groupsCopy[index].hashes = removeHash(groupsCopy[index].hashes, hash);
      if (groupsCopy[index].hashes.length === 0) {
        removeAllGroupHandle(group);
        setGroups(groupsCopy);
      }
    }
  };
  return (
    <CollapseProvider
      value={{
        accordion: accordion,
        noClosible: noClosible,
        groups: groups,
        addHash: addHashHandle,
        removeHash: removeHashHandle,
        addOneHash: addOneHashHandle,
        removeAllGroup: removeAllGroupHandle,
        getHash: getHashHandle,
      }}
    >
      {children}
    </CollapseProvider>
  );
};
