import styled from 'styled-components';
import { Chips } from '../Chips/Chips';
import { ReactComponent as ChevronIcon } from '../assets/svg/chevron-menu.svg';

export const StyledChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0.5rem 0;
  width: 100%;
`;
export const StyledChips = styled(Chips)`
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  white-space: nowrap;
`;
export const StyledNextChip = styled.div`
  alighn-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
  width: 100%;
`;
export const StyledChevronIcon = styled(ChevronIcon)`
  width: 1.5rem;
`;
