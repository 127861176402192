import { useRef } from 'react';

export const useDebounce = (
  callback: (...args: unknown[]) => void,
  timeout = 500,
): {
  handler: (...args: unknown[]) => void;
  clear: () => void;
} => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const clear = (): void => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  };
  const handler = (...args: unknown[]): void => {
    clear();
    timeoutRef.current = global.setTimeout(() => {
      callback(...args);
    }, timeout);
  };
  return {
    handler,
    clear,
  };
};

export default useDebounce;
