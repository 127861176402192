import React from 'react';
import { CookieModalOrderedListParagraph } from '../../../../types';
import { CookieDescriptionOrderedListItem } from './CookieOrderedListParagraph.styles';
import { MapMarkdownTextContent } from '../MapMarkdownTextContent/MapMarkdownTextContent';

export const CookieOrderedListParagraph: React.FC<{
  paragraph: CookieModalOrderedListParagraph;
}> = ({ paragraph }) => {
  return (
    <div>
      {paragraph.orderedElements.map((orderedElement, index) => (
        <CookieDescriptionOrderedListItem
          key={`${orderedElement.markdownTextContent[0].type}-${index}`}
        >
          <MapMarkdownTextContent
            markdownTextContent={orderedElement.markdownTextContent}
          />
        </CookieDescriptionOrderedListItem>
      ))}
    </div>
  );
};
