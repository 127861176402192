import { CookiesModalTranslatedText } from '../../../types';

export const CookieModalTranslatedTextPolish: CookiesModalTranslatedText = {
  PRIVACY_POLICY: 'Polityka prywatności',
  COMMON_POLICY_DESCRIPTION: `Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
    szczególności pliki tekstowe, które są umieszczane i przechowywane w
    urządzeniu końcowym (np. komputerze, telefonie czy tablecie)
    Użytkownika.`,
  SAVE_AND_CLOSE: 'Zapisz i zamknij',
  DENY_ALL: 'Odrzuć',
  ACCEPT_ALL: 'Zaakceptuj wszystkie',
  ACCEPT: 'Akceptuj wszystkie pliki cookie',
  SET_PREFERENCE: 'Ustawienia plików cookie',
  SEE_MORE: 'Pokaż więcej',
  SEE_LESS: 'Pokaż mniej',
};
