import styled from 'styled-components';
import { BeeFieldState, BeeFieldStyles } from './BeeField.types';
import { getFieldColor } from './utils/getFieldColor.util';
import { getFieldOpacity } from './utils/getFieldOpacity.util';

function getInputWrapperMarginTop(noTopMargin: boolean): string {
  return noTopMargin ? 'margin-top: 0' : '';
}

function getInputWrapperWidth(fullWidth: boolean): string {
  return fullWidth ? 'width: 100%' : '';
}

export const StyledBeeFieldWrapper = styled.div<{
  noTopMargin: boolean;
  fullWidth: boolean;
}>`
  display: flex;
  flex-direction: column;
  font-family: ${(p) => p.theme.typography.fontFamily};
  padding: 1rem 0rem;
  position: relative;
  ${(props) => getInputWrapperMarginTop(props.noTopMargin)};
  ${(props) => getInputWrapperWidth(props.fullWidth)};
`;

export const getInputBorderWidth = ({
  state,
  styles,
}: {
  state: BeeFieldState;
  styles: BeeFieldStyles;
}): string | number => {
  let borderWidth: string | number | undefined;
  if (state === BeeFieldState.FOCUSED) {
    borderWidth = styles.focused.borderWidth;
  } else {
    borderWidth = styles.default.borderWidth;
  }
  borderWidth = (borderWidth || styles.default.borderWidth) as string | number;
  return borderWidth;
};

export const StyledBeeFieldBorder = styled.div<{
  state: BeeFieldState;
  styles: BeeFieldStyles;
}>`
  border-color: ${(p) => getFieldColor(p)};
  border-radius: 0.25rem;
  border-style: solid;
  border-width: ${({ state, styles }) =>
    getInputBorderWidth({ state, styles })};
  box-sizing: border-box;
  display: flex;
  opacity: ${({ state }) => getFieldOpacity(state)};
  position: relative;
  transition: border-color 0.2s;
`;

export const StyledBeeFieldContent = styled.div`
  border-radius: inherit;
  height: 100%;
  position: relative;
  width: 100%;
`;
