import React, { useCallback, useState } from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { TooltipTitle, TooltipWrapper } from './Tooltip.styles';
import { TooltipProps } from './Tooltip.type';

export const Tooltip = (props: TooltipProps): JSX.Element => {
  const { title, children } = props;
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const onMouseOver = useCallback(
    () => setIsTooltipShown(true),
    [isTooltipShown],
  );
  const onMouseLeave = useCallback(
    () => setIsTooltipShown(false),
    [isTooltipShown],
  );

  return (
    <DefaultThemed>
      <TooltipWrapper
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onTouchStart={onMouseOver}
        onTouchEnd={onMouseLeave}
      >
        {children}

        {isTooltipShown && <TooltipTitle variant="text1">{title}</TooltipTitle>}
      </TooltipWrapper>
    </DefaultThemed>
  );
};
