import styled from 'styled-components';
import { Theme } from '../Theme/utils/theme.type';
import Close from '../assets/svg/close.svg';
import { ChipColors, ChipsProps } from './Chips.types';

const getColorStyle = (color: ChipColors, theme: Theme): string => {
  if (color === 'secondary') {
    return `
    background-color: ${theme.palette.brand.background};
    color: ${theme.palette.input.text.selected};
    font-weight: 600;
    `;
  } else {
    return `
    background-color: ${theme.palette.brand.color};
    color: ${theme.palette.button.text.secondary.base};
    font-weight: 500;
    `;
  }
};

export const ChipsContainer = styled.div<ChipsProps>`
  align-items: center;
  ${(p) => getColorStyle(p.color as ChipColors, p.theme)};
  border: 0;
  border-radius: 1.125rem;
  box-sizing: border-box;
  display: inline-flex;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: ${(p) => p.theme.typography.chipsMedium.fontSize};
  height: ${(p) => (p.size === 'small' ? `1.5rem` : '1.875rem')};
  justify-content: space-between;
  line-height: ${(p) =>
    p.size === 'small'
      ? p.theme.typography.chipsSmall.lineHeight
      : p.theme.typography.chipsMedium.lineHeight};
  margin-right: 0.5rem;
  min-width: 4.25rem;
  padding: 0 ${(p) => (p.size === 'small' ? `0.5rem` : '0.75rem')};
  transition: background-color ease-in-out 200ms;
`;

export const ChipsClose = styled.div<ChipsProps>`
  background-color: ${(p) =>
    p.color === 'secondary'
      ? p.theme.palette.input.text.selected
      : p.theme.palette.button.text.secondary.base};
  cursor: pointer;
  height: 0.5rem;
  margin-left: 10px;
  mask: url(${Close}) no-repeat 50% 50%;
  padding: 0 0 0 1rem;
  width: 0.5rem;

  & > path {
    pointer-events: none;
  }
`;
