interface StringMap {
  [key: string]: string[];
}

export class URLSearchParams {
  public dict: StringMap = {};
  constructor(search: string) {
    this.parseToDict(search);
  }
  private parseToDict(search: string): StringMap {
    if (search.indexOf('?') === 0) {
      search = search.slice(1);
    }
    const pairs = search.split('&');
    for (let j = 0; j < pairs.length; j++) {
      let value = pairs[j];
      const ind = value.indexOf('?');
      if (ind > 0) {
        value = value.slice(ind + 1);
      }
      const index = value.indexOf('=');
      if (-1 < index) {
        this.appendTo(
          this.decode(value.slice(0, index)),
          this.decode(value.slice(index + 1)),
        );
      } else {
        if (value) {
          this.appendTo(this.decode(value), '');
        }
      }
    }
    return this.dict;
  }
  private decode(str: string): string {
    return str
      .replace(/[ +]/g, '%20')
      .replace(/(%[a-f0-9]{2})+/gi, function (match) {
        return decodeURIComponent(match);
      });
  }
  private appendTo(name: string, value: string): void {
    if (Object.hasOwnProperty.call(this.dict, name)) {
      this.dict[name].push(value);
    } else {
      this.dict[name] = [value];
    }
  }
  public has(name: string): boolean {
    return Object.hasOwnProperty.call(this.dict, name);
  }
  public get(name: string): string | string[] | null {
    return this.has(name)
      ? this.dict[name].length === 1
        ? this.dict[name][0]
        : this.dict[name]
      : null;
  }
  public getFirst(name: string): string | null {
    const value = this.get(name);
    return Array.isArray(value) ? value[0] : value;
  }
}
export default URLSearchParams;
