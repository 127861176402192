import React, { useMemo } from 'react';
import {
  StyledOfferCheckBoxWrapper,
  StyledOfferCheckBoxIcon,
} from './OfferCheckBox.styled';
import { OfferCheckBoxProps } from './OfferCheckBox.types';

export const OfferCheckBox: React.FC<OfferCheckBoxProps> = ({
  value,
  onChange,
  disabled = false,
  tooltipText = '',
  tooltipPosition = 'right',
  tooltipAlwaysVisible = false,
  tooltipDeleteText = '',
}) => {
  const getTooltipText = useMemo(() => {
    return !disabled && value ? tooltipDeleteText : tooltipText;
  }, [disabled, tooltipText, tooltipDeleteText, value]);

  return (
    <StyledOfferCheckBoxWrapper
      disabled={disabled}
      active={value}
      onClick={() => !disabled && onChange(!value)}
      tooltipPosition={tooltipPosition}
      tooltipText={getTooltipText}
      tooltipAlwaysVisible={tooltipAlwaysVisible}
      aria-checked={value}
    >
      <StyledOfferCheckBoxIcon active={value} disabled={disabled} />
    </StyledOfferCheckBoxWrapper>
  );
};
