import React from 'react';
import {
  CookieModalContext,
  CookieModalContextData,
} from './CookieModalProvider';

export const USE_COOKIE_MODAL_CONTEXT_ERROR =
  'useCookieModalContext must be used within a CookieModalProvider';

export const useCookieModalContext = (): CookieModalContextData => {
  const cookieContext = React.useContext(CookieModalContext);

  if (!cookieContext) {
    throw new Error(USE_COOKIE_MODAL_CONTEXT_ERROR);
  }

  return cookieContext;
};
