import * as React from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { CardContainer } from './Card.styled';
import { CardProps } from './Card.types';

export const Card: React.FC<CardProps> = ({
  children,
  ...restProps
}: CardProps) => {
  return (
    <DefaultThemed>
      <CardContainer {...restProps}>{children}</CardContainer>
    </DefaultThemed>
  );
};
