import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

type StyledCookiesBarWrapperProps = {
  zIndex?: number;
  isCookiesApproved: boolean;
};

const MAX_HEIGHT = '8rem';

export const StyledCookiesWrapperBar = styled.div<StyledCookiesBarWrapperProps>`
  align-items: center;
  background-color: ${(p) => p.theme.palette.commons.background.infotip};
  display: flex;
  justify-content: center;
  max-height: ${MAX_HEIGHT};
  padding: 1rem;
  position: fixed;
  transition: bottom 300ms ease-out;
  width: 100%;
  z-index: ${(p) => p.zIndex ?? 20};
  ${(p) => (p.isCookiesApproved ? `bottom: -${MAX_HEIGHT};` : `bottom: 0;`)}
`;

export const StyledCookiesContentWrapper = styled.div`
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 0.6rem;
  margin-right: 1rem;
  width: ${isMobile ? '100%;' : '43.75rem;'};
`;

export const StyledLink = styled.a`
  color: ${(p) => p.theme.palette.brand.color};
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
`;
