import React from 'react';
import { isMobile } from 'react-device-detect';
import { usePickerContext } from '../../../../contexts';
import { MIN_START_YEAR } from '../../RenderPicker.const';
import { DesktopYearPicker } from './DesktopYearPicker';
import { MobileYearPicker } from './MobileYearPicker';
import { StyledHeader, StyledYearsWrapper } from './YearPicker.styled';
import { getYearBlockIndex } from './YearPicker.utils';

export const YearPicker: React.FC = () => {
  const {
    state: {
      date,
      startDate,
      endDate,
      openToYear,
      onlyFutureDates,
      displayFromCurrentYear,
      today,
    },
  } = usePickerContext();
  const [yearsMatrix, setYearsMatrix] = React.useState<number[][]>([]);
  const [yearsBlockIndex, setYearsBlockIndex] = React.useState(
    getYearBlockIndex(yearsMatrix, Number(date.year)),
  );
  const [labels, setLabels] = React.useState<string[]>([]);

  const startYear = React.useMemo(() => {
    if (startDate) {
      return startDate.getUTCFullYear();
    }
    if (onlyFutureDates) {
      return today.getUTCFullYear();
    }
    return MIN_START_YEAR;
  }, [startDate, onlyFutureDates, today]);

  const endYear = React.useMemo(() => {
    if (endDate) {
      return endDate.getUTCFullYear();
    }
    return today.getUTCFullYear();
  }, [today, endDate]);

  React.useEffect(() => {
    const yearsArr = Array.from(
      { length: endYear - startYear + 1 },
      (_, index) =>
        displayFromCurrentYear ? endYear - index : startYear + index,
    );
    while (yearsArr.length) {
      const chunkArr = yearsArr.splice(0, 20);
      setYearsMatrix((yearsMatrix) => [...yearsMatrix, chunkArr]);
    }
  }, [displayFromCurrentYear, endYear, startYear]);

  React.useEffect(() => {
    yearsMatrix.forEach((yearsArr, index) => {
      const firstItem = yearsArr[0];
      const lastItem = yearsArr[yearsArr.length - 1];
      const year = openToYear ? openToYear : Number(date.year);

      if (yearsArr.includes(year)) {
        setYearsBlockIndex(index);
      }

      setLabels((labels) => [...labels, `${firstItem}-${lastItem}`]);
    });
  }, [openToYear, yearsMatrix, date.year]);

  if (!yearsMatrix.length) {
    return (
      <>
        <StyledHeader />
        <StyledYearsWrapper />
      </>
    );
  }

  return (
    <>
      {isMobile ? (
        <MobileYearPicker
          labels={labels}
          yearsMatrix={yearsMatrix}
          yearsBlockIndex={yearsBlockIndex || 0}
        />
      ) : (
        <DesktopYearPicker
          yearsBlockIndex={yearsBlockIndex}
          setYearsBlockIndex={setYearsBlockIndex}
          labels={labels}
          yearsMatrix={yearsMatrix}
        />
      )}
    </>
  );
};
