import * as React from 'react';
import { InputContext } from '../InputContext/InputContext';
import { InputContextOptionalType } from '../InputContext/InputContext.types';
const { useContext } = React;

export const withInputContext = <P extends InputContextOptionalType>(
  Component: React.FC<P>,
  deps?: string[],
): React.FC<P> => {
  return function ComponentWithInputContext(props: P) {
    const context = useContext(InputContext);
    let passedContext: InputContextOptionalType = {};
    if (deps) {
      deps.forEach((key: string) => {
        passedContext[key] = context[key];
      });
    } else {
      passedContext = context;
    }

    return <Component {...props} {...passedContext} />;
  };
};
