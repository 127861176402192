import { Picker } from '../../../../BeeDatePicker.const';
import { DateFromPickerType } from '../../../../BeeDatePicker.types';
import { getMonthIndex } from '../../../../contexts/PickerContext/PickerContext.utils';
import { convertToTwoDigitsString } from '../../../../helpers/convertToTwoDigitsString';
import {
  isValidDay,
  isValidMonth,
  isValidYear,
} from '../../../../helpers/dateValidators';
import { RadioSlideIndex, RadioSlideLabels } from './RadioSlide.const';

export function getPickerByRadioSlideIndex(
  radioSlideIndex: number,
): keyof typeof Picker | null {
  switch (radioSlideIndex) {
    case RadioSlideIndex.YEAR:
      return Picker.YEAR;
    case RadioSlideIndex.MONTH:
      return Picker.MONTH;
    case RadioSlideIndex.DAY:
      return Picker.DAY;
    default:
      return null;
  }
}

export function getDayLabel(date: DateFromPickerType): string {
  if (isValidYear(date.year) && isValidMonth(date)) {
    return isValidDay(date)
      ? convertToTwoDigitsString(Number(date.day))
      : RadioSlideLabels.DAY;
  }

  return '';
}

export function getMonthLabel(
  date: DateFromPickerType,
  monthNames: string[],
): string {
  if (isValidYear(date.year)) {
    return isValidMonth(date)
      ? monthNames[getMonthIndex(date.month)]
      : RadioSlideLabels.MONTH;
  }

  return '';
}

export function getYearLabel({ year }: DateFromPickerType): string {
  if (isValidYear(year)) {
    return year;
  } else {
    return RadioSlideLabels.YEAR;
  }
}
