import React from 'react';
import { CookieModalMarkDownContent } from '../../../../types';
import { CookiePolicyDescriptionText } from '../paragraphComponents.styles';
import { StyleTextByType } from '../StyleTextByType/StyleTextByType';

export const MapMarkdownTextContent: React.FC<{
  markdownTextContent: CookieModalMarkDownContent[];
}> = ({ markdownTextContent }) => {
  return (
    <CookiePolicyDescriptionText>
      {markdownTextContent.map((markdownContent, index) => (
        <StyleTextByType
          key={`${markdownContent.type}-${index}`}
          markdownContent={markdownContent}
        />
      ))}
    </CookiePolicyDescriptionText>
  );
};
