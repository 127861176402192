import { useEffect } from 'react';

export function useKeyDown(keyName: string, callback: () => void): void {
  useEffect(() => {
    const listenerDown = (event: KeyboardEvent): void => {
      if (event.key === keyName) {
        callback();
      }
    };
    document.addEventListener('keydown', listenerDown);
    return () => {
      document.removeEventListener('keydown', listenerDown, false);
    };
  }, [keyName, callback]);
}
export default useKeyDown;
