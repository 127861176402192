import { ColorsType } from '../../utils/colors.type';

export const ColorsPalette: ColorsType = {
  brand: '#DC241F',
  brand_background: '#F4F10A',

  primary_button_background: '#DC241F',
  primary_button_background_hover: '#E14440',
  primary_button_background_after: '#FFFFFF',

  secondary_button_background: '#DC241F',
  secondary_button_background_hover: '#E14440',
  secondary_button_background_after: '#FFFFFF',

  outline_button_background: '#FFFFFF',
  outline_button_background_hover: '#FDF2F2',
  outline_button_background_after: '#DC241F',

  select_button_background: '#FFFFFF',
  select_button_background_selected: '#FDF2F2',
  select_button_background_after: '#DC241F',

  outline_button_border: '#DC241F',
  outline_button_border_hover: '#DC241F',

  select_button_border: '#F3F3F3',
  select_button_border_hover: '#DC241F',
  select_button_border_selected: '#DC241F',

  text_primary_button: '#FFFFFF',
  text_secondary_button: '#FFFFFF',
  text_outline_button: '#DC241F',
  text_outline_button_hover: '#DC241F',
  text_select_button: '#000000',

  text_link: '#DC241F',
  text_header: '#000000',
  text_primary_paragraph: '#000000',
  text_secondary_paragraph: '#373F41',

  text_input: '#5E6870',
  text_input_selected: '#000000',

  text_inactive: '#7C7C7C',

  input_border: '#B8BECA',
  input_border_hover: '#000000',
  input_border_selected: '#000000',

  checkbox_border: '#B8BECA',

  switch_background_selected: '#FFFFFF',

  error_base: '#FF4C2F',
  text_error_notification: '#FFFFFF',
  error_hover: '#FFF1EE',

  tonal_base: '#F8F8F8',
  tonal_hover: '#FCEBEB',

  container_background: '#FFFFFF',
  infotip_background: '#F8F8F8',
  dimmed_background: '#000000',
  icons_endorsement: '#828F98',
  divider: '#E8E8E8',
  UE: '#4545FF',

  footer_primary_background: '#373F41',
  footer_secondary_background: '#F8F8F8',
  text_footer: '#FFFFFF',

  dropdown_shadow: '0px 2px 8px rgba(58,58,58,0.1)',
  content_box_shadow: '0px 6px 12px rgba(58, 58, 58, 0.1)',
  switch_shadow: '0px 5px 10px rgba(58, 58, 58, 0.1)',
  modal_shadow: '0px 10px 40px rgba(0, 0, 0, 0.15)',
} as const;
