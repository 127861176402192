import styled, { DefaultTheme } from 'styled-components';
import { BeeFieldState } from '../../../BeeField/BeeField.types';
import { isAnimatedLabelOnTop } from '../../../BeeField/components/AnimatedLabel/AnimatedLabel.styled';
import { BeeTextInputProps } from '../../BeeTextInput.types';

export const getPlaceholderVisibility = ({
  inputState,
  withAnimatedLabel,
}: {
  inputState: BeeFieldState;
  withAnimatedLabel?: boolean;
}): string => {
  return !withAnimatedLabel || isAnimatedLabelOnTop(inputState)
    ? 'visible'
    : 'hidden';
};

export const getInputColor = (
  {
    inputState,
    withAnimatedLabel,
  }: {
    inputState: BeeFieldState;
    withAnimatedLabel?: boolean;
  },
  theme: DefaultTheme,
): string => {
  return !withAnimatedLabel || isAnimatedLabelOnTop(inputState)
    ? theme.palette.input.text.selected
    : 'transparent';
};

export const BeeTextInputStyled = styled.input<BeeTextInputProps>`
  background-color: transparent;
  border: 0;
  border-radius: inherit;
  box-sizing: border-box;
  color: ${({ withAnimatedLabel, inputState, theme }) =>
    getInputColor({ inputState, withAnimatedLabel }, theme)};
  font-family: inherit;
  font-size: 1rem;
  height: 100%;
  outline: none;
  padding: 0 1rem;
  width: 100%;
  &:disabled {
    background-color: inherit;
  }
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.palette.input.text.base};
    visibility: ${({ inputState, withAnimatedLabel }) =>
      getPlaceholderVisibility({ inputState, withAnimatedLabel })};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.palette.input.text.base};
    visibility: ${({ inputState, withAnimatedLabel }) =>
      getPlaceholderVisibility({ inputState, withAnimatedLabel })};
  }
  :-moz-placeholder {
    color: ${({ theme }) => theme.palette.input.text.base};
    visibility: ${({ inputState, withAnimatedLabel }) =>
      getPlaceholderVisibility({ inputState, withAnimatedLabel })};
  }
  ::placeholder,
  ::-moz-placeholder {
    ${({ inputState, withAnimatedLabel, theme }) => {
      if (
        getPlaceholderVisibility({ inputState, withAnimatedLabel }) ===
        'visible'
      ) {
        return `
          color: ${theme.palette.input.text.base}};
          visibility: visible;
        `;
      }
      return '';
    }}
  }
`;
