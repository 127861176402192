import { DateFromPickerType } from '../BeeDatePicker.types';
import {
  DAY_LENGTH,
  MONTH_LENGTH,
  YEAR_LENGTH,
} from '../components/RenderPicker/RenderPicker.const';

function isDayValid(noDayMode?: boolean, day?: string): boolean {
  return noDayMode ? true : day?.length === DAY_LENGTH;
}
export function isDatePickerFormFilled({
  noDayMode,
  day,
  month,
  year,
}: Partial<DateFromPickerType>): boolean {
  if ((!noDayMode && !day) || !month || !year) {
    return false;
  }

  return (
    isDayValid(noDayMode, day) &&
    month.length === MONTH_LENGTH &&
    year.length === YEAR_LENGTH
  );
}
