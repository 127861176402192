import React, { useEffect, useRef, useState } from 'react';
import { useResizeObserver } from '../Hooks/useResizeObserver';
import { ANIMATIONS } from '../Theme/utils//animations';
import { useCollapse } from './Collapse.provider';
import { CollapseProps, GroupHashType } from './Collapse.types';
import { CollapseWrapper } from './CollapseWrapper';

export const Collapse: React.FC<CollapseProps> = ({
  value,
  children,
  label,
  onChange,
  group = '',
  defaultOpened = false,
  noClosible = false,
  duration = ANIMATIONS.defaultTransitionTime,
}: CollapseProps) => {
  const {
    groups,
    addHash,
    removeHash,
    addOneHash,
    removeAllGroup,
    accordion,
    noClosible: noClosibleGroup,
    getHash,
  } = useCollapse();

  const ref = useRef<HTMLDivElement>(null);
  const [hash, setHash] = useState<GroupHashType>(0);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [withAnimation, setWithAnimation] = useState<boolean>(true);
  const [height, setHeight] = useState<number>(0);
  const isOutsideControl = value !== undefined;
  const isGroupMode = group !== '';
  const isClosible = !noClosible && !noClosibleGroup;

  const getIsVisible = (): boolean => {
    const getIsVisibleGroupMode = (): boolean => {
      const groupIndex = groups.map((el) => el.name).indexOf(group);
      if (accordion) {
        return groupIndex > -1
          ? groups[groupIndex].hashes.indexOf(hash) > -1
          : false;
      }
      return groupIndex > -1;
    };
    if (isGroupMode) {
      return getIsVisibleGroupMode();
    }
    return isOutsideControl ? value : isOpened;
  };
  const isVisible = getIsVisible();
  useEffect(() => {
    setHash(getHash());
    ref.current && setHeight(ref.current.getBoundingClientRect().height);
    if (defaultOpened) {
      openHandle();
      setWithAnimation(false);
      setTimeout(() => {
        setWithAnimation(true);
      }, 0);
    }
  }, [ref.current]);
  useEffect(() => {
    isOutsideControl ? onChange && onChange(isVisible) : setIsOpened(isVisible);
  }, [isVisible]);

  useResizeObserver(
    ref.current,
    (el) => {
      if (
        height &&
        isOpened &&
        Math.round(el[0].contentRect.height) !== Math.round(height)
      ) {
        setWithAnimation(false);
        setHeight(el[0].contentRect.height);
        setTimeout(() => {
          setWithAnimation(true);
        }, 0);
      }
    },
    [height, isOpened],
  );

  const openHandle = (): void => {
    if (isOutsideControl || isGroupMode) {
      accordion ? addOneHash(group, hash) : addHash(group, hash);
    }
    setIsOpened(true);
    onChange && onChange(true);
  };
  const closeHandle = (): void => {
    if (isClosible) {
      if (isOutsideControl || isGroupMode) {
        accordion ? removeHash(group, hash) : removeAllGroup(group);
      }
      setIsOpened(false);
      onChange && onChange(false);
    }
  };
  useEffect(() => {
    value ? openHandle() : closeHandle();
  }, [value]);
  return (
    <CollapseWrapper
      height={height}
      duration={withAnimation ? duration : '0'}
      label={label}
      isVisible={isVisible}
      openHandle={openHandle}
      closeHandle={closeHandle}
    >
      <div ref={ref}>{children}</div>
    </CollapseWrapper>
  );
};
