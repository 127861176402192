import { safeWindow } from '../../utils/safeWindow';
import { PositionType, XYVariantsType } from '../Menu.types';
import { getAnchorOrSelfPositionName } from './getAnchorOrSelfPositionName';

export const getContainerPositionByBody = (
  labelRefCurent: HTMLElement,
  anchor: XYVariantsType,
  self: XYVariantsType,
): PositionType => {
  const box = labelRefCurent.getBoundingClientRect();
  const anchorPosition = getAnchorOrSelfPositionName(anchor);
  const selfPosition = getAnchorOrSelfPositionName(self);

  const body = safeWindow.document?.body;
  const docEl = safeWindow.document?.documentElement;

  const scrollTop =
    safeWindow.scrollY || docEl?.scrollTop || body?.scrollTop || 0;
  const scrollLeft =
    safeWindow.scrollX || docEl?.scrollLeft || body?.scrollLeft || 0;

  const clientTop = docEl?.clientTop || body?.clientTop || 0;
  const clientLeft = docEl?.clientLeft || body?.clientLeft || 0;

  const topLoc = box.top + scrollTop - clientTop;
  const leftLoc = box.left + scrollLeft - clientLeft;
  return {
    top: Math.round(topLoc),
    left: Math.round(leftLoc),
    anchorPosition,
    selfPosition,
  };
};
