export const RadioSlideLabels = {
  YEAR: 'Rok',
  MONTH: 'Miesiąc',
  DAY: 'Dzień',
};

export const RadioSlideIndex = {
  YEAR: 0,
  MONTH: 1,
  DAY: 2,
};
