import React from 'react';
import { CookieModalTextParagraph } from '../../../../types';
import {
  CookiePolicyDescriptionTextContainer,
  CookiePolicyDescriptionText,
} from '../paragraphComponents.styles';
import { MapMarkdownTextContent } from '../MapMarkdownTextContent/MapMarkdownTextContent';

export const CookieTextParagraph: React.FC<{
  paragraph: CookieModalTextParagraph;
}> = ({ paragraph }) => {
  return (
    <CookiePolicyDescriptionTextContainer>
      <CookiePolicyDescriptionText>
        <MapMarkdownTextContent
          markdownTextContent={paragraph.markdownTextContent}
        />
      </CookiePolicyDescriptionText>
    </CookiePolicyDescriptionTextContainer>
  );
};
