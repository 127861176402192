import styled from 'styled-components';
import { MEDIA_QUERY } from '../../../Theme/utils/mediaQuery';

export const ToggleSelectOptionWrapper = styled.div<{
  isCentered: boolean;
}>`
  border: 1px solid ${(p) => p.theme.palette.input.border.selected}08;
  border-radius: 0.25rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-family: ${(p) => p.theme.typography.fontFamily};
  justify-content: space-between;
  outline: 0;
  padding: 0.25rem 0.75rem;
  transition: 0.3s;
  width: 100%;
  &:hover {
    border: 1px solid ${(p) => p.theme.palette.input.border.hover};
  }
  &.focused {
    background: ${(p) => p.theme.palette.commons.background.container};
    border: 1px solid ${(p) => p.theme.palette.input.border.selected};
  }
  &.selected {
    background: ${(p) => p.theme.palette.commons.background.container};
    border: 1px solid ${(p) => p.theme.palette.input.border.selected};
  }
  ${MEDIA_QUERY.below('sm')} {
    padding: 0.35rem 0.75rem;
  }
`;

export const ToggleSelectOptionIconWrapper = styled.div`
  width: 0.75rem;
`;

export const ToggleSelectOptionTitle = styled.div`
  color: ${(p) => p.theme.palette.input.text.selected};
  font-family: ${(p) => p.theme.typography.fontFamily};
`;

export const ToggleSelectOptionCaption = styled.div`
  color: ${(p) => p.theme.palette.text.paragraph.secondary};
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: ${(p) => p.theme.typography.text1.fontSize};
`;
