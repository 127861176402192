import {
  AvailableCookieModalLanguages,
  CookieModalConsentDefinition,
} from '../../types';
import POLISH_CONSENT_DEFINITIONS from '../CookieConsentDefinitions/cookieConsentDefinition.pl.json';
import ENGLISH_CONSENT_DEFINITIONS from '../CookieConsentDefinitions/cookieConsentDefinition.en.json';

export const getCookieConsentsByLanguage = (
  language: AvailableCookieModalLanguages,
): CookieModalConsentDefinition[] => {
  switch (language) {
    case 'en':
      return ENGLISH_CONSENT_DEFINITIONS;
    case 'pl':
      return POLISH_CONSENT_DEFINITIONS;
    default:
      return POLISH_CONSENT_DEFINITIONS;
  }
};
