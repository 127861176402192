import ReactSlider from 'react-slider';
import styled from 'styled-components';
import { StyledSliderProps } from './Slider.types';

export const StyledSlider = styled(ReactSlider)`
  height: 1.75rem;
  width: 100%;
`;

export const StyledThumb = styled.div`
  background-color: ${(p) => p.theme.palette.commons.background.container};
  border-radius: 50%;
  box-shadow: ${(p) => p.theme.shadow.switch};
  cursor: grab;
  height: 1.75rem;
  position: relative;
  width: 1.75rem;

  &:focus-visible {
    outline: none;
  }
  &:after {
    background-color: ${(p) => p.theme.palette.brand.color};
    border-radius: 50%;
    content: '';
    cursor: grab;
    height: 1.25rem;
    left: 0.25rem;
    position: absolute;
    top: 0.25rem;
    width: 1.25rem;
  }
`;

export const StyledTrack = styled.div<StyledSliderProps>`
  background: ${(p) =>
    p.index === 1
      ? p.theme.palette.commons.tonal.base
      : p.theme.palette.brand.color};
  border-radius: 999px;
  height: 0.5rem;
  top: calc(100% * 5 / 14);
`;
