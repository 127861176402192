import React from 'react';
import { ReactComponent as Prev } from '../../../../../../assets/svg/back.svg';
import { ReactComponent as Next } from '../../../../../../assets/svg/next.svg';
import { usePickerContext } from '../../../../../contexts';
import { isDatePickerFormFilled } from '../../../../../helpers/isDatePickerFormFilled';
import { isYearInRange } from '../../../../../helpers/isInRange';
import { StyledCalendarButton } from '../../../RenderPicker.styled';
import {
  StyledHeader,
  StyledHorizontalButton,
  StyledYearsWrapper,
} from '../YearPicker.styled';
import { DesktopYearPickerProps } from './DesktopYearPicker.types';

export const DesktopYearPicker: React.FC<DesktopYearPickerProps> = ({
  yearsBlockIndex,
  labels,
  yearsMatrix,
  setYearsBlockIndex,
}) => {
  const {
    state: { date, datesRange, today },
    dispatch,
  } = usePickerContext();

  const handleSetYearsBlockIndex = React.useCallback(setYearsBlockIndex, [
    setYearsBlockIndex,
  ]);

  const onClickHandler = React.useCallback(
    (numberYear: number): void => {
      const year = numberYear.toString();

      if (year === date.year) {
        dispatch({
          type: 'SET_VISIBLE_PICKER',
          payload: null,
        });

        return;
      }

      dispatch({ type: 'SET_DATE', payload: { year } });

      if (isDatePickerFormFilled({ ...date, year })) {
        dispatch({ type: 'SET_VISIBLE_PICKER', payload: null });
      }
    },
    [date, dispatch],
  );

  return (
    <>
      <StyledHeader>
        {yearsBlockIndex > 0 && (
          <StyledHorizontalButton
            horizontalPosition="left"
            type="button"
            onClick={() =>
              handleSetYearsBlockIndex(Number(yearsBlockIndex - 1))
            }
          >
            <Prev />
          </StyledHorizontalButton>
        )}
        {labels[yearsBlockIndex]}
        {yearsBlockIndex < yearsMatrix.length - 1 && (
          <StyledHorizontalButton
            horizontalPosition="right"
            type="button"
            onClick={() =>
              handleSetYearsBlockIndex(Number(yearsBlockIndex + 1))
            }
          >
            <Next />
          </StyledHorizontalButton>
        )}
      </StyledHeader>
      <StyledYearsWrapper>
        {yearsMatrix[yearsBlockIndex].map((year) => (
          <StyledCalendarButton
            key={year}
            onClick={() => onClickHandler(year)}
            isToday={today.getUTCFullYear() === year}
            selected={Number(date.year) === year}
            isDisabled={datesRange && !isYearInRange(year, datesRange)}
            type="button"
          >
            {year}
          </StyledCalendarButton>
        ))}
      </StyledYearsWrapper>
    </>
  );
};
