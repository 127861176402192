import { useModal } from '../useModal';
import React, { useState, useEffect } from 'react';
import { ConsentsModal } from './ConsentsModal';
import { Modal } from '../Modal.types';
import {
  Consent,
  ConsentModalProps,
  ConsentsModal as ConsentsModalType,
} from './ConsentsModal.types';
import { ConsentsModalHeader } from './ConsentsModalHeader';

export const useConsentsModal = (
  props: ConsentModalProps,
): ConsentsModalType => {
  const { consents, setConsents, headerText, buttonText, shouldSaveHistory } =
    props;

  const [areAllSelectedInModal, setAreAllSelectedInModal] = useState<boolean>(
    consents.every((consent) => consent.isSelected),
  );

  const handleSelectAll = (isChecked: boolean): void => {
    const updatedConsents = consents.map((consent: Consent) => ({
      ...consent,
      isSelected: isChecked,
    }));

    setAreAllSelectedInModal(isChecked);
    setConsents(updatedConsents);
  };

  const handleSelect = (key: string): void => {
    const updatedConsents = consents.map((consent: Consent) => {
      if (consent.rawName === key) {
        return {
          ...consent,
          isSelected: !consent.isSelected,
        };
      }

      return consent;
    });
    checkAreAllSelected(updatedConsents);
    setConsents(updatedConsents);
  };

  const updateContent = (): void => {
    setContent(
      <ConsentsModal
        consents={consents}
        handleSelect={handleSelect}
        onClose={() => setIsVisible(false)}
      />,
    );
    setHeader(
      <ConsentsModalHeader
        areAllSelected={areAllSelectedInModal}
        handleSelectAll={handleSelectAll}
        headerText={headerText}
      />,
    );
  };

  const checkAreAllSelected = (updatedConsents: Consent[]): void => {
    setAreAllSelectedInModal(
      updatedConsents.every((consent: Consent) => consent.isSelected),
    );
  };

  useEffect(() => {
    updateContent();
  }, [consents]);

  const {
    component,
    setIsVisible,
    isVisible,
    hide,
    setContent,
    setHeader,
  }: Modal = useModal({
    isBlurred: true,
    shouldSaveHistory,
    content: (
      <ConsentsModal
        consents={consents}
        handleSelect={handleSelect}
        onClose={() => setIsVisible(false)}
        buttonText={buttonText}
      />
    ),
    customHeader: (
      <ConsentsModalHeader
        areAllSelected={areAllSelectedInModal}
        handleSelectAll={handleSelectAll}
        headerText={headerText}
      />
    ),
  });

  return {
    setIsVisible,
    component,
    isVisible,
    hide,
    areAllSelectedInModal,
    setAreAllSelectedInModal,
    handleSelectAll,
    setContent,
  };
};
