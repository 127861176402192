import React, { useState } from 'react';
import { ANIMATIONS } from '../Theme/utils/animations';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { PanelsProvider } from './Panels.provider';
import { StyledPanelsWrapper } from './Panels.styled';
import { PanelsProps, ParametersType } from './Panels.types';

export const Panels: React.FC<PanelsProps> = ({
  value,
  children,
  style,
  duration = ANIMATIONS.defaultTransitionTime,
}: PanelsProps) => {
  const [panelParameters, setPanelParameters] = useState<ParametersType>({
    top: 0,
    left: 0,
    offsetHeight: 0,
    offsetWidth: 0,
  });
  const onSetPanelParameters = (parameters: ParametersType): void => {
    setPanelParameters(parameters);
  };
  return (
    <DefaultThemed>
      <PanelsProvider
        value={{
          value: value,
          duration: duration,
          setPanelParameters: onSetPanelParameters,
        }}
      >
        <StyledPanelsWrapper
          styles={style}
          minHeight={panelParameters.offsetHeight}
        >
          {children}
        </StyledPanelsWrapper>
      </PanelsProvider>
    </DefaultThemed>
  );
};
