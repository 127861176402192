export const DEFAULT_HELPER_TEXT_STYLES = {
  focused: {
    opacity: 0.5,
  },
  default: {
    opacity: 1,
    left: '1rem',
    maxWidth: '19rem',
  },
};
