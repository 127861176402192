import styled from 'styled-components';
import { ANIMATIONS } from '../Theme/utils/index';
import {
  keyframesRipple,
  keyframesRippleDuplicate,
} from '../Theme/utils/animations';
import { LabelType, CheckboxType } from './CheckBox.types';

export const InputCheckbox = styled.input.attrs({
  type: 'checkbox',
})<CheckboxType>`
  appearance: none;
  background: transparent;
  border: 0.125rem solid ${(p) => p.theme.palette.input.border.checkbox};
  border-radius: 0.125rem;
  cursor: pointer;
  display: inline-block;
  height: 1.25rem;
  margin: 0;
  opacity: 1;
  outline: none;
  position: absolute;
  transition: background ${ANIMATIONS.defaultTransitionTime},
    border-color ${ANIMATIONS.defaultTransitionTime}, box-shadow 0.2s;

  vertical-align: top;
  width: 1.25rem;

  &:after {
    border: ${(p) =>
      p.checked
        ? `0.125rem solid ${p.theme.palette.commons.background.container}`
        : 'none'};
    border-left: 0;
    border-top: 0;
    height: 0.8125rem;
    left: 0.3rem;
    top: 0rem;
    transform: rotate(43deg);
    width: 0.438rem;
    content: '';
    display: block;
    position: absolute;
    transition: transform ${ANIMATIONS.defaultTransitionTime}
        cubic-bezier(${ANIMATIONS.cubicBezier}),
      opacity ${ANIMATIONS.defaultTransitionTime};
  }

  &:checked {
    background: ${(p) => p.theme.palette.brand.color};
    border: 0.125rem solid ${(p) => p.theme.palette.brand.color};
  }

  &:disabled {
    background: ${(p) => p.theme.palette.commons.background.container};
    cursor: not-allowed;
    opacity: 0.9;
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        background: transparent;
      }
    }
  }

  &:after,
  &:checked {
    opacity: 1;
  }

  ${keyframesRipple};
  ${keyframesRippleDuplicate};

  + :after {
    animation: ripple ${ANIMATIONS.defaultTransitionTime} ease-out;
  }

  /* to re-trigger the animation with just CSS, we need to duplicate the keyframes
 */
  :checked + :after {
    animation-name: rippleDuplicate;
  }

  /* fixes initial animation run, without user input, on page load.
 */
  + :after {
    visibility: hidden;
  }

  :focus + :after {
    visibility: visible;
  }

  ${(p) => p.isInvalid && `border-color: ${p.theme.palette.error.text.base};`}
`;

export const LabelElement = styled.label<LabelType>`
  align-items: center;
  color: ${(p) => p.theme.palette.text.paragraph.primary};
  display: flex;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: 0.75rem;
  line-height: 1.5;
  max-width: fit-content;

  ${(p) => p.column && 'flex-direction: column;'}
`;

export const CheckBoxElement = styled.div<{
  checkBoxTopSelfAlignment?: boolean;
}>`
  align-items: center;
  ${(props) =>
    props.checkBoxTopSelfAlignment &&
    `
    align-self: flex-start;
    margin-top: -0.5rem;
  `}
  border-radius: 50%;
  display: flex;
  justify-content: center;
  max-width: min-content;
  min-height: 2.625rem;
  min-width: 2.625rem;
  transition: background 300ms ease-in-out;

  &:hover {
    background-color: ${(p) => p.theme.palette.commons.tonal.hover};
  }

  &:focus {
    background-color: ${(p) => p.theme.palette.brand.color};
  }
  &:active {
    background-color: ${(p) => p.theme.palette.brand.color};
  }
`;

export const AnimatedLayer = styled.div`
  &:after {
    background: ${(p) => p.theme.palette.commons.tonal.hover};
    border-radius: 100%;
    content: '';
    display: block;
    height: 2.625rem;
    left: 0;
    opacity: 0.6;
    top: 0;
    transform: scale(0);
    width: 2.625rem;
  }
`;
