import { COOKIE_CONSENTS_SESSION_STORAGE_KEY } from '../../../shared/constants';

export const checkCookieConsentsInSession = (): boolean => {
  const alreadyChosenConsents = localStorage.getItem(
    COOKIE_CONSENTS_SESSION_STORAGE_KEY,
  );
  return alreadyChosenConsents
    ? Boolean(JSON.parse(alreadyChosenConsents).length)
    : false;
};
