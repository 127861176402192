import * as React from 'react';
import styled from 'styled-components';

type StyledCollapseWrapperType = {
  styles?: React.CSSProperties;
};
type StyledCollapseContentType = {
  height: number;
  duration: string;
};

export const StyledCollapseContent = styled.div<StyledCollapseContentType>`
  height: ${(p) => p.height}px;
  overflow: hidden;
  transition: height ${(p) => p.duration} ease-in-out;
`;

export const StyledCollapseWrapper = styled.div<StyledCollapseWrapperType>`
  width: 100%;
  ${({ styles }) => styles && { ...styles }}
`;
