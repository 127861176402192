import { ParametersType } from '../Panels.types';

export const getParameters = (element: HTMLElement | null): ParametersType => {
  const position = element?.getBoundingClientRect();

  return {
    top: position?.top || 0,
    left: position?.left || 0,
    offsetWidth: element?.offsetWidth || 0,
    offsetHeight: element?.offsetHeight || 0,
  };
};
