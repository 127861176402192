import React from 'react';
import { DefaultThemed } from '../../ThemeProvider/ThemeProvider';
import { Consent } from './Consent';
import { CloseConsentsButton, ConsentsWrapper } from './ConsentsModal.styled';
import { Consent as ConsentType } from './ConsentsModal.types';

export const ConsentsModal = ({
  consents,
  handleSelect,
  onClose,
  buttonText = 'OK',
}: {
  consents: ConsentType[];
  handleSelect: (key: string, value: boolean) => void;
  onClose: () => void;
  buttonText?: string;
}): JSX.Element => {
  return (
    <DefaultThemed>
      <>
        <ConsentsWrapper>
          {consents.map((consent) => (
            <Consent
              consent={consent}
              key={consent.rawName}
              data-e2e={`consent-${consent.rawName}`}
              handleSelect={handleSelect}
            />
          ))}
        </ConsentsWrapper>
        <CloseConsentsButton
          type="button"
          typeStyle="secondary"
          blockMode
          onClick={onClose}
          data-e2e="consentsModalOK"
        >
          {buttonText}
        </CloseConsentsButton>
      </>
    </DefaultThemed>
  );
};
