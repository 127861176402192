import {
  AvailableCookieModalLanguages,
  availableCookieModalLanguages,
} from '../../types';

export const DEFAULT_COOKIE_MODAL_LANGUAGE: AvailableCookieModalLanguages =
  'pl';

export const handleProvidedLanguage = (
  language: AvailableCookieModalLanguages,
): AvailableCookieModalLanguages => {
  const isSupportedLanguage = availableCookieModalLanguages.includes(language);

  if (!isSupportedLanguage) {
    return DEFAULT_COOKIE_MODAL_LANGUAGE;
  }

  return language;
};
