import styled from 'styled-components';
import { MEDIA_QUERY } from '../Theme/utils';

export const StickerStyled = styled.div`
  background-color: ${(p) => p.theme.palette.commons.background.container};
  border-bottom: 1px solid ${(p) => p.theme.palette.commons.tonal.base};
  font-family: 'Poppins', sans-serif;

  ${MEDIA_QUERY.above('sm')} {
    border: 0;
    border-radius: 1rem;
    box-shadow: ${(p) => p.theme.shadow.contentBox};
  }
`;
