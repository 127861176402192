import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

export const StyledMonth = styled.div`
  display: grid;
  grid-template-columns:
    calc(100% / 7) calc(100% / 7) calc(100% / 7) calc(100% / 7)
    calc(100% / 7) calc(100% / 7) calc(100% / 7);
  margin-top: 1.5rem;

  ${isMobile &&
  css`
    padding: 7.5rem 1rem;
  `};
`;

export const StyledDayName = styled.div`
  color: ${({ theme }) => theme.palette.commons.divider};
  font-size: 0.75rem;
  text-align: center;
`;
