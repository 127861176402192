import { BREAKPOINTS } from './breakpoints';

type AvailableBreakpoints = keyof typeof BREAKPOINTS;

export const MEDIA_QUERY = {
  below: (size: AvailableBreakpoints): string =>
    `@media (max-width: ${BREAKPOINTS[size]}px)`,
  above: (size: AvailableBreakpoints): string =>
    `@media (min-width: ${BREAKPOINTS[size]}px)`,
};
