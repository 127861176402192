import styled from 'styled-components';
import { DatePickerGenericStyles } from './BeeDatePicker.types';

export const DatePickerWrapper = styled.div`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  height: 100%;
  position: relative;
`;

export const StyledHelperTxt = styled.div`
  color: ${({ theme }) => theme.palette.error.text.base};
  font-size: 0.75rem;
  margin: 0.1875rem 0 -0.3125rem 0.625rem;
  position: absolute;
`;

export const BeeDatePickerContainer = styled.div<DatePickerGenericStyles>`
  height: ${({ height }) => height ?? '3.5rem'};
`;
