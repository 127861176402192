import * as React from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { ChipsClose, ChipsContainer } from './Chips.styled';
import { ChipsProps } from './Chips.types';

export const Chips: React.FC<ChipsProps> = ({
  children,
  size,
  color = 'primary',
  isLoading,
  onDelete,
  dataE2e = '',
  ...rest
}: ChipsProps) => {
  return (
    <DefaultThemed>
      <ChipsContainer size={size} color={color} {...rest}>
        {children}
        {onDelete && (
          <ChipsClose
            color={color}
            onClick={(e) => onDelete(e)}
            data-e2e={`${dataE2e}removeChip`}
          />
        )}
        {isLoading && 'Loading...'}
      </ChipsContainer>
    </DefaultThemed>
  );
};
