import React from 'react';
import { usePickerContext } from '../../../../contexts';
import {
  StyledRadioSlideWrapper,
  StyledRadioSlideWrapperBackground,
  StyledToggle,
  StyledToggleButton,
  StyledToggleLabelsWrapper,
} from './RadioSlide.styled';
import {
  getDayLabel,
  getMonthLabel,
  getPickerByRadioSlideIndex,
  getYearLabel,
} from './RadioSlide.utils';

export const RadioSlide: React.FC = () => {
  const {
    state: { radioSlideIndex, monthNames, date, noDayMode },
    dispatch,
  } = usePickerContext();

  const labels = React.useMemo(
    (): string[] => [
      getYearLabel(date),
      getMonthLabel(date, monthNames),
      ...(noDayMode ? [] : [getDayLabel(date)]),
    ],
    [date, monthNames, noDayMode],
  );

  const onClickHandler = React.useCallback(
    (index: number): void => {
      if (index < radioSlideIndex || labels[index].length > 0) {
        dispatch({
          type: 'SET_VISIBLE_PICKER',
          payload: getPickerByRadioSlideIndex(index),
        });
      }
    },
    [radioSlideIndex, labels, dispatch],
  );

  return (
    <StyledRadioSlideWrapperBackground>
      <StyledRadioSlideWrapper>
        <StyledToggle activeIndex={radioSlideIndex} noDayMode={noDayMode} />
        <StyledToggleLabelsWrapper>
          {labels.map((label, index) => (
            <StyledToggleButton
              key={`${label}${index}`}
              isActive={index === radioSlideIndex}
              onClick={() => onClickHandler(index)}
              type="button"
            >
              {label}
            </StyledToggleButton>
          ))}
        </StyledToggleLabelsWrapper>
      </StyledRadioSlideWrapper>
    </StyledRadioSlideWrapperBackground>
  );
};
