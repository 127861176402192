import { DateFromPickerType } from '../BeeDatePicker.types';

export function isValueEqualToInitial(
  pickerDate: DateFromPickerType,
  initialPickerDate: DateFromPickerType,
): boolean {
  return Object.entries(pickerDate).some(
    ([key, value]) => initialPickerDate[key] !== value,
  );
}
