import styled from 'styled-components';
import { MEDIA_QUERY } from '../Theme/utils/mediaQuery';
import { AccordionStyledContentProps } from './Accordion.type';

export const StyledAccordion = styled.div`
  background-color: ${(p) => p.theme.palette.commons.background.container};
  font-family: ${(p) => p.theme.typography.fontFamily};

  ${MEDIA_QUERY.above('sm')} {
    border: 0;
    border-radius: 1rem;
    box-shadow: ${(p) => p.theme.shadow.contentBox};
  }
`;

interface AccordionStateProps {
  isOpen?: boolean;
  hasChildren?: boolean;
}

export const Header = styled.div<AccordionStateProps>`
  align-items: center;
  border-bottom: solid 1px transparent;
  cursor: ${(p) => (p.hasChildren ? 'pointer' : 'default')};
  display: flex;
  height: 100%;
  min-height: 5.5rem;
  padding: 1.5rem 1.125rem;
  position: relative;

  ${MEDIA_QUERY.above('sm')} {
    padding: 1.5rem 1.5rem;
  }

  ${(p) => p.isOpen && `border-color: ${p.theme.palette.commons.divider};`}
`;

export const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h4`
  color: ${(p) => p.theme.palette.text.header};
  font-size: ${(p) => p.theme.typography.h4.fontSize};
  font-weight: ${(p) => p.theme.typography.h4.fontWeight};
  margin: 0;
`;

export const Subtitle = styled.span`
  color: ${(p) => p.theme.palette.text.header};
  font-size: ${(p) => p.theme.typography.subtitle1.fontSize};
  font-weight: ${(p) => p.theme.typography.subtitle1.fontWeight};
  line-height: ${(p) => p.theme.typography.subtitle1.lineHeight};
  word-break: break-all;
`;

export const StyledFilled = styled.div`
  align-items: center;
  background: ${(p) => p.theme.palette.brand.color};
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 1.75rem;
  justify-content: center;
  margin-right: 1.375rem;
  width: 1.75rem;
`;

function joinPaddingShorthand(props: AccordionStyledContentProps): string {
  const bottom = props.noPaddingBottom ? 0 : '1.5rem';

  return `padding: 0 1.5rem ${bottom} 1.5rem`;
}

export const Content = styled.div<AccordionStyledContentProps>`
  padding: 0 1rem 1.75rem 1rem;

  ${MEDIA_QUERY.above('sm')} {
    ${joinPaddingShorthand};
  }

  ${MEDIA_QUERY.below('sm')} {
    padding: 0 1rem 1rem;
  }
`;

export const FooterContent = styled(Content)`
  border-top: solid 1px ${(props) => props.theme.palette.commons.divider};
`;

function transformIfOpen(props: AccordionStateProps): string {
  return props.isOpen ? 'transform: rotate(0deg)' : '';
}

export const StateIcon = styled.div<AccordionStateProps>`
  margin-left: auto;
  transform: rotate(180deg);
  transition: all 300ms ease-in-out;

  path {
    stroke: ${(p) => p.theme.palette.brand.color};
  }

  ${transformIfOpen}
`;
