import { CookiesModalTranslatedText } from '../../../types';

export const CookieModalTranslatedTextEnglish: CookiesModalTranslatedText = {
  PRIVACY_POLICY: 'Privacy policy',
  COMMON_POLICY_DESCRIPTION: `Cookies are IT data, in particular text files, that are stored on the User's device (e.g. computer, telephone or tablet).`,
  SAVE_AND_CLOSE: 'Save and close',
  DENY_ALL: 'Deny',
  ACCEPT_ALL: 'Accept all cookies',
  ACCEPT: 'Accept all',
  SET_PREFERENCE: 'Cookie settings',
  SEE_MORE: 'See more',
  SEE_LESS: 'See less',
};
