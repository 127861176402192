import { convertToTwoDigitsString } from './convertToTwoDigitsString';

export function getDate(year: string, month: string, day?: string): Date {
  const twoDigitMonth = convertToTwoDigitsString(month);
  const twoDigitDay = day ? convertToTwoDigitsString(day) : undefined;
  const stringDate = twoDigitDay
    ? `${year}-${twoDigitMonth}-${twoDigitDay}`
    : `${year}-${twoDigitMonth}`;

  return new Date(stringDate);
}
