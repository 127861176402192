import React, { useCallback, useMemo, useState } from 'react';
import { COOKIE_CONSENTS_SESSION_STORAGE_KEY } from '../../shared/constants';
import {
  CookiesModalProps,
  CookiesModalTranslatedText,
  CookieModalConsentDefinition,
  CookiePolicyDescription,
  CookieModalMarkDownContent,
  CookieModalExternalHandlers,
} from '../types';
import {
  cookieBarDescriptionsPerLanguage,
  cookieModalMenuTexts,
  tenantSources,
} from './constants';
import {
  getCookieConsentsByLanguage,
  checkCookieConsentsInSession,
  getCookiePolicyDescription,
  handleProvidedLanguage,
} from './utils';

export type CookieModalContextData = {
  translatedText: CookiesModalTranslatedText;
  selectedConsentIds: string[];
  handleClickOnConsentCheckbox: (consentId: string) => void;
  cookieConsentDefinitions: CookieModalConsentDefinition[];
  acceptSelectedConsents: () => void;
  denyAll: () => void;
  areConsentsChosen: boolean;
  isSettingMode: boolean;
  setIsSettingMode: (status: boolean) => void;
  acceptAll: () => void;
  handleCloseModal: () => void;
  policyDescription: CookiePolicyDescription[];
  cookieBarDescription: CookieModalMarkDownContent[];
  externalHandlers: CookieModalExternalHandlers;
};

export const CookieModalContext =
  React.createContext<CookieModalContextData | null>(null);

export const IDS_OF_REQUIRED_CONSENTS = ['1'];

export const CookieModalProvider: React.FC<CookiesModalProps> = ({
  children,
  language,
  tenant,
  externalHandlers = {},
}) => {
  const checkedLanguage = handleProvidedLanguage(language);
  const [selectedConsentIds, setSelectedConsentIds] = useState<string[]>(
    IDS_OF_REQUIRED_CONSENTS,
  );
  const [areConsentsChosen, setAreConsentsChosenStatus] = useState(
    checkCookieConsentsInSession(),
  );
  const [isSettingMode, setIsSettingMode] = useState(false);
  const cookieConsentDefinitions = getCookieConsentsByLanguage(checkedLanguage);
  const tenantSourceInfo = tenantSources[tenant];

  const handleClickOnConsentCheckbox = useCallback(
    (consentId: string): void => {
      if (IDS_OF_REQUIRED_CONSENTS.includes(consentId)) {
        return;
      }

      const isAlreadyAccepted = selectedConsentIds.includes(consentId);

      if (isAlreadyAccepted) {
        return setSelectedConsentIds(
          selectedConsentIds.filter((consent) => consent !== consentId),
        );
      }

      return setSelectedConsentIds((prev) => [...prev, consentId]);
    },
    [selectedConsentIds],
  );

  const finalizeConsentUpdates = useCallback(
    (consentsToSave: string[]): void => {
      // TODO: logic to send info about accepted consents will be implemented in the other task.

      localStorage.setItem(
        COOKIE_CONSENTS_SESSION_STORAGE_KEY,
        JSON.stringify(consentsToSave),
      );
      setAreConsentsChosenStatus(true);
      setIsSettingMode(false);
    },
    [],
  );

  const acceptSelectedConsents = useCallback(() => {
    finalizeConsentUpdates(selectedConsentIds);
  }, [finalizeConsentUpdates, selectedConsentIds]);

  const denyAll = useCallback(() => {
    finalizeConsentUpdates(IDS_OF_REQUIRED_CONSENTS);
  }, [finalizeConsentUpdates]);

  const acceptAll = useCallback(() => {
    const allConsentIds = cookieConsentDefinitions.map(({ id }) => id);

    finalizeConsentUpdates(allConsentIds);
  }, [cookieConsentDefinitions, finalizeConsentUpdates]);

  const handleCloseModal = useCallback(() => {
    const optionalSelectedConsents = selectedConsentIds.filter(
      (id) => !IDS_OF_REQUIRED_CONSENTS.includes(id),
    );

    if (optionalSelectedConsents.length) {
      return acceptSelectedConsents();
    }

    return setIsSettingMode(false);
  }, [acceptSelectedConsents, selectedConsentIds]);

  const contextValue = useMemo((): CookieModalContextData => {
    return {
      selectedConsentIds,
      handleClickOnConsentCheckbox,
      translatedText: cookieModalMenuTexts[checkedLanguage],
      cookieConsentDefinitions,
      acceptSelectedConsents,
      denyAll,
      areConsentsChosen,
      isSettingMode,
      setIsSettingMode,
      acceptAll,
      handleCloseModal,
      policyDescription: getCookiePolicyDescription(
        tenantSourceInfo,
        checkedLanguage,
      ),
      cookieBarDescription: cookieBarDescriptionsPerLanguage(
        tenantSourceInfo,
        checkedLanguage,
      ),
      externalHandlers,
    };
  }, [
    selectedConsentIds,
    handleClickOnConsentCheckbox,
    checkedLanguage,
    cookieConsentDefinitions,
    acceptSelectedConsents,
    denyAll,
    areConsentsChosen,
    isSettingMode,
    acceptAll,
    handleCloseModal,
    tenantSourceInfo,
    externalHandlers,
  ]);

  return (
    <CookieModalContext.Provider value={contextValue}>
      {children}
    </CookieModalContext.Provider>
  );
};
