import React from 'react';

export interface BeeFieldStyles {
  hover?: React.CSSProperties;
  checked?: React.CSSProperties;
  error?: React.CSSProperties;
  focused: React.CSSProperties;
  default: React.CSSProperties;
}

export enum BeeFieldState {
  INACTIVE = 'inactive',
  HOVER = 'hover',
  FOCUSED = 'focused',
  CHECKED = 'checked',
  ERROR = 'error',
  DISABLED = 'disabled',
}

export interface BeeFieldProps {
  children: React.ReactNode;
  state: BeeFieldState;
  styles?: BeeFieldStyles;
  helperText?: string;
  error?: string;
  helperTextFullWidth?: boolean;
  label?: string;
  noTopMargin?: boolean;
  fullWidth?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}
