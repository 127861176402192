import styled, { DefaultTheme } from 'styled-components';
import {
  ANIMATIONS,
  keyframesRippleDuplicate,
} from './../Theme/utils/animations';

type StyledLabelProps = { hasChildren: boolean };

const PseudoElement = (p: DefaultTheme): string => `
  background-color: ${p.palette.commons.tonal.hover};
  border-radius: 50%;
  content: '';
  height: 3rem;
  width: 3rem;
  left: -0.75rem;
  position: absolute;
  top: calc(50% - 1.5rem);
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  margin: ${(p) =>
    p.hasChildren ? `0.75rem 0 0.75rem 1.625rem` : `0.75rem 0 0.75rem 0.75rem`};
  position: relative;
  transition: background 0.2s ease;
  @keyframes ripple {
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  &:hover {
    &::after {
      animation: ripple ${ANIMATIONS.defaultTransitionTime} ease-in-out;
      ${(p) => PseudoElement(p.theme)}
      transform: scale(1);
      will-change: scale;
    }
  }
  &:focus {
    &::after {
      ${(p) => PseudoElement(p.theme)}
      transform: scale(1);
      will-change: scale;
    }
  }
  &:active {
    position: relative;
    &::after {
      ${(p) => PseudoElement(p.theme)}
      transform: scale(1);
      will-change: scale;
    }
  }
`;

export const StyledInput = styled.input`
  appearance: none;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px
    ${(p) => p.theme.palette.button.border.outline.base};
  height: 1.5rem;
  margin: 0;
  min-width: 1.5rem;
  padding: 0;
  pointer-events: none;
  transition: box-shadow ${ANIMATIONS.defaultTransitionTime}
    cubic-bezier(${ANIMATIONS.cubicBezier});
  width: 1.5rem;
  z-index: 1;
  &:focus {
    outline: none;
  }
  ${keyframesRippleDuplicate};
  &:checked {
    box-shadow: inset 0 0 0 6px
      ${(p) => p.theme.palette.button.border.select.selected};
    position: relative;
    &:before {
      animation: rippleDuplicate 0.6s ease-in-out;
      ${(p) => PseudoElement(p.theme)}
      opacity: 0.1;
      transform: scale(0);
      will-change: scale;
    }
    & + div {
      border-color: ${(p) => p.theme.palette.button.border.select.selected};
    }
  }
`;

export const StyledValue = styled.div`
  display: inline-block;
  margin-left: 12px;
`;

export const StyledChildren = styled.div`
  border: 2px solid ${(p) => p.theme.palette.commons.tonal.hover};
  border-radius: 1rem;
  height: 100%;
  margin-left: -2.5rem;
  padding: 1.5rem 1.5rem 1.5rem 3.25rem;
  transition: border ${ANIMATIONS.defaultTransitionTime} ease-in-out;
  &:hover {
    border-color: ${(p) => p.theme.palette.button.border.outline.hover};
  }
`;
