import React, { useState } from 'react';
import { Consent as ConsentType } from './ConsentsModal.types';
import { CheckBox } from '../../CheckBox';
import {
  StyledConsentElement,
  StyledConsentText,
  StyledConsentRow,
  StyledConsentRequiredInformation,
  StyledDocumentLink,
  StyledDocumentLinks,
  StyledExpand,
  StyledDescriptionText,
  StyledChevron,
} from './ConsentsModal.styled';

const MAX_CLOSED_CONSENT_LENGTH = 122;

export const Consent = ({
  consent,
  handleSelect,
}: {
  consent: ConsentType;
  handleSelect: (key: string, value: boolean) => void;
}): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    rawName,
    text,
    descriptionText,
    isSelected,
    isRequired,
    documentLink,
    requiredText,
  } = consent;

  const shorten = (providedText: string): string =>
    providedText.length <= MAX_CLOSED_CONSENT_LENGTH
      ? providedText
      : `${providedText
          .slice(0, MAX_CLOSED_CONSENT_LENGTH)
          .split(' ')
          .slice(0, -1)
          .join(' ')}[..]`;

  return (
    <StyledConsentRow key={rawName}>
      <StyledConsentElement>
        <CheckBox
          id={rawName}
          text={
            <StyledConsentText pointer>
              {isExpanded ? text : shorten(text)}
            </StyledConsentText>
          }
          name={rawName}
          required={isRequired}
          checked={isSelected}
          onChange={() => handleSelect(rawName, !isSelected)}
          checkBoxTopSelfAlignment
        />
        {isExpanded ? (
          <StyledDocumentLinks>
            {documentLink?.map((document) => {
              return (
                <StyledDocumentLink
                  key={document.href}
                  href={document.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledConsentText margin>{document.text}</StyledConsentText>
                </StyledDocumentLink>
              );
            })}
          </StyledDocumentLinks>
        ) : null}
        {(isRequired || requiredText) && (
          <StyledConsentRequiredInformation>
            <StyledConsentText>{requiredText}</StyledConsentText>
          </StyledConsentRequiredInformation>
        )}
        {descriptionText && isExpanded && (
          <StyledDescriptionText>{descriptionText}</StyledDescriptionText>
        )}
      </StyledConsentElement>
      <StyledExpand onClick={() => setIsExpanded(!isExpanded)}>
        <StyledChevron isExpanded={isExpanded} />
      </StyledExpand>
    </StyledConsentRow>
  );
};
