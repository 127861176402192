import * as React from 'react';
import { StyledHexagonLoader } from './HexagonLoader.styled';
import { ReactComponent as Hexagon } from '../assets/svg/hex.svg';

export const HexagonLoader: React.FC = () => {
  return (
    <>
      <StyledHexagonLoader>
        <div className="hexagons">
          <Hexagon className="hexagon" />
          <Hexagon className="hexagon" />
          <Hexagon className="hexagon" />
          <Hexagon className="hexagon" />
          <Hexagon className="hexagon" />
          <Hexagon className="hexagon" />
          <Hexagon className="hexagon" />
        </div>
      </StyledHexagonLoader>
    </>
  );
};
