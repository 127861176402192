import React, { useRef } from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { BeeFieldProps } from './BeeField.types';
import {
  StyledBeeFieldWrapper,
  StyledBeeFieldBorder,
  StyledBeeFieldContent,
} from './BeeField.styled';
import { DEFAULT_FIELD_STYLES } from './BeeField.defaults';
import { AnimatedLabel } from './components/AnimatedLabel/AnimatedLabel';
import { HelperText } from './components/HelperText/HelperText';

export const BeeField: React.FC<BeeFieldProps> = ({
  children,
  state,
  fullWidth = false,
  noTopMargin = false,
  styles = DEFAULT_FIELD_STYLES,
  prefix,
  suffix,
  label,
  helperText,
  helperTextFullWidth,
  error,
}: BeeFieldProps): React.ReactElement => {
  const ref = useRef(null);
  return (
    <DefaultThemed>
      <StyledBeeFieldWrapper
        ref={ref}
        fullWidth={fullWidth}
        noTopMargin={noTopMargin}
      >
        <StyledBeeFieldBorder state={state} styles={styles}>
          {prefix}
          <StyledBeeFieldContent>
            {children}
            <AnimatedLabel content={label} state={state} />
          </StyledBeeFieldContent>
          {suffix}
        </StyledBeeFieldBorder>
        <HelperText
          helperText={helperText}
          error={error}
          fullWidth={helperTextFullWidth}
          state={state}
        />
      </StyledBeeFieldWrapper>
    </DefaultThemed>
  );
};
