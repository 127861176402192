import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

export const StyledMonthsWrapper = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 2.5rem 2.5rem 2.5rem 2.5rem;
  margin-top: 0.875rem;

  ${isMobile &&
  css`
    padding: 7.5rem 1rem;
  `};
`;
