import React from 'react';
import { ProgressCircularStyled } from './ProgressCircular.styled';
import {
  ProgressCircularProps,
  ProgressCircularStyle,
} from './ProgressCircular.type';
import { DefaultThemed, useTheme } from '../ThemeProvider/ThemeProvider';
import { Theme } from '../Theme/utils/theme.type';

export const DEFAULT_RADIUS = 16;
export const DEFAULT_STROKE = 3;

export const getStrokeColor = (
  theme: Theme,
  style: ProgressCircularStyle,
): string => {
  if (style === 'primary') {
    return theme.palette.brand.color;
  }
  return theme.palette.commons.background.dimmed;
};

const ProgressCircularComponent = (
  props: ProgressCircularProps,
): JSX.Element => {
  const theme = useTheme();
  const strokeColor = props.color ?? getStrokeColor(theme, props.typeStyle);

  const radius = props.radius ?? DEFAULT_RADIUS;
  const stroke = props.stroke ?? DEFAULT_STROKE;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  return (
    <ProgressCircularStyled
      circumference={circumference}
      height={radius * 2}
      width={radius * 2}
    >
      <svg height={radius * 2} width={radius * 2}>
        <circle
          className="circle"
          stroke={strokeColor}
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </ProgressCircularStyled>
  );
};

export const ProgressCircular = (props: ProgressCircularProps): JSX.Element => {
  return (
    <DefaultThemed>
      <ProgressCircularComponent {...props} />
    </DefaultThemed>
  );
};
