import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import { Button } from '../../../Button';
import {
  CookieModalAppearFrame,
  CookieModalAppearFrameMobile,
  CookieModalDisappearFrame,
  CookieModalDisappearFrameMobile,
  CookieModalOverlayAppearFrame,
  CookieModalOverlayDisappearFrame,
} from './CookieModalDisplay.frames';

const COMMON_ANIMATION_DURATION = '0.3s';

export const CookieModalOverlay = styled.div<{ isVisible: boolean }>`
  animation: ${({ isVisible }) =>
    isVisible
      ? css`
          ${CookieModalOverlayAppearFrame} ${COMMON_ANIMATION_DURATION} ease-in-out
        `
      : css`
          ${CookieModalOverlayDisappearFrame} ${COMMON_ANIMATION_DURATION} ease-in-out
        `};
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  overflow-y: auto;
  padding: 1rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

  ${({ isVisible }) =>
    !isVisible &&
    `
  transition: visibility ${COMMON_ANIMATION_DURATION} ease-in-out;
  opacity: 0;
  visibility: hidden;
  `}

  @media (max-width: 768px) {
    padding: 0;
    background-color: transparent;
  }
`;

export const CookieModalBodyContent = styled.div`
  margin-top: 2rem;
  max-height: 29rem;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 0.5rem;
  scrollbar-color: ${({ theme }) => `${theme.palette.brand.color} white`};
  scrollbar-width: thin;
  @media (max-width: 768px) {
    max-height: 90%;
    padding: 0 1rem;
  }
`;

export const CookieModalWindow = styled.div<{ isVisible: boolean }>`
  animation: ${({ isVisible }) =>
    isVisible
      ? css`
          ${CookieModalAppearFrame} ${COMMON_ANIMATION_DURATION} ease-in-out
        `
      : css`
          ${CookieModalDisappearFrame} ${COMMON_ANIMATION_DURATION} ease-in-out
        `};
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: ${({ theme }) => theme.shadow.modal};
  margin: auto 0;
  max-width: 70.5rem;
  padding: 2rem;
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 2rem 0 0 0;
    margin: 0;
    overflow-y: auto;
    animation: ${({ isVisible }) =>
      isVisible
        ? css`
            ${CookieModalAppearFrameMobile} ${COMMON_ANIMATION_DURATION} ease-in-out
          `
        : css`
            ${CookieModalDisappearFrameMobile} ${COMMON_ANIMATION_DURATION} ease-in-out
          `};
  }
`;

export const CookieModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export const CookiesModalHeaderTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.paragraph.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 1rem;
  font-weight: bold;
`;

export const CookieModalCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.palette.brand.color};
  height: 1rem;
  width: 1rem;
`;

export const CookieModalCommonDescriptionText = styled.div`
  color: ${({ theme }) => theme.palette.text.paragraph.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;

export const CookieModalButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 80%;
  @media (max-width: 570px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

export const CookieModalButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
`;

export const CookieModalButton = styled(Button)`
  margin: 0 1rem;
  width: 100%;
  @media (max-width: 570px) {
    margin-bottom: 1rem;
  }
`;
