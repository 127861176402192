import { BeeFieldState } from '../BeeField/BeeField.types';

export const getInputState = ({
  error,
  focused,
  hovered,
  disabled,
  filled,
}: {
  error?: string;
  focused?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  filled?: boolean;
}): BeeFieldState => {
  switch (true) {
    case disabled:
      return BeeFieldState.DISABLED;
    case focused:
      return BeeFieldState.FOCUSED;
    case !!error:
      return BeeFieldState.ERROR;
    case filled:
      return BeeFieldState.CHECKED;
    case hovered:
      return BeeFieldState.HOVER;
    default:
      return BeeFieldState.INACTIVE;
  }
};

export const getEmptyValue = (
  mask?: string | (string | RegExp)[] | undefined,
  maskChar = '_',
  skipEmptyValidation = false,
): string =>
  !mask || skipEmptyValidation
    ? ''
    : String(mask).replace(/[^\-\s]/g, maskChar);
