import { Picker } from '../../BeeDatePicker.const';
import { DateFromPickerType } from '../../BeeDatePicker.types';
import { InputRef } from '../../contexts';
import { MONTH_LENGTH, YEAR_LENGTH } from '../RenderPicker';

export function inputFocus(
  dayRef: InputRef,
  monthRef: InputRef,
  yearRef: InputRef,
): void {
  if (!dayRef.current?.value) {
    dayRef.current?.focus();
    return;
  }
  if (!monthRef.current?.value) {
    monthRef.current?.focus();
    return;
  }
  if (!yearRef.current?.value) {
    yearRef.current?.focus();
  }
}

export function getDatePicker(
  date: DateFromPickerType,
  pickerName: keyof typeof Picker,
): keyof typeof Picker {
  if (date.month.length === MONTH_LENGTH && date.year.length === YEAR_LENGTH) {
    return pickerName;
  } else {
    return date.year.length === YEAR_LENGTH ? Picker.MONTH : Picker.YEAR;
  }
}
