import { Tenants } from '../../shared/types';
import { CookieModalExternalHandlers } from './CookieModalExternalHandlers';

export const availableCookieModalLanguages = ['pl', 'en'] as const;

export type AvailableCookieModalLanguages =
  (typeof availableCookieModalLanguages)[number];

export type CookiesModalProps = {
  language: AvailableCookieModalLanguages;
  tenant: Tenants;
  externalHandlers?: CookieModalExternalHandlers;
};
