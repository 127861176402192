import * as React from 'react';
import {
  AnimatedLabelStyledWrapper,
  AnimatedLabelStyled,
} from './AnimatedLabel.styled';
import { AnimatedLabelProps } from './AnimatedLabel.types';
import { DEFAULT_ANIMATED_LABEL_STYLES } from './AnimatedLabel.defaults';

export const AnimatedLabel: React.FC<AnimatedLabelProps> = ({
  state,
  styles = DEFAULT_ANIMATED_LABEL_STYLES,
  ...props
}: AnimatedLabelProps): React.ReactElement => {
  return (
    <AnimatedLabelStyledWrapper>
      <AnimatedLabelStyled state={state} styles={styles}>
        {props.content}
      </AnimatedLabelStyled>
      {props.children}
    </AnimatedLabelStyledWrapper>
  );
};
