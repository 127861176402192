import { ColorsType } from './colors.type';
import { Theme } from './theme.type';

export const getTheme = (
  ColorsPalette: ColorsType,
  typography: Theme['typography'],
  borderRadius: string,
): Theme => {
  return {
    palette: {
      brand: {
        color: ColorsPalette.brand,
        background: ColorsPalette.brand_background,
      },
      button: {
        text: {
          primary: {
            base: ColorsPalette.text_primary_button,
          },
          secondary: {
            base: ColorsPalette.text_secondary_button,
          },
          outline: {
            base: ColorsPalette.text_outline_button,
            hover: ColorsPalette.text_outline_button_hover,
          },
          select: {
            base: ColorsPalette.text_select_button,
          },
        },
        background: {
          primary: {
            base: ColorsPalette.primary_button_background,
            hover: ColorsPalette.primary_button_background_hover,
            after: ColorsPalette.primary_button_background_after,
          },
          secondary: {
            base: ColorsPalette.secondary_button_background,
            hover: ColorsPalette.secondary_button_background_hover,
            after: ColorsPalette.secondary_button_background_after,
          },
          outline: {
            base: ColorsPalette.outline_button_background,
            hover: ColorsPalette.outline_button_background_hover,
            after: ColorsPalette.outline_button_background_after,
          },
          select: {
            base: ColorsPalette.select_button_background,
            after: ColorsPalette.select_button_background_after,
            selected: ColorsPalette.select_button_background_selected,
          },
        },
        border: {
          outline: {
            base: ColorsPalette.outline_button_border,
            hover: ColorsPalette.outline_button_border_hover,
          },
          select: {
            base: ColorsPalette.select_button_border,
            hover: ColorsPalette.select_button_border_hover,
            selected: ColorsPalette.select_button_border_selected,
          },
        },
      },
      input: {
        text: {
          base: ColorsPalette.text_input,
          selected: ColorsPalette.text_input_selected,
        },
        border: {
          base: ColorsPalette.input_border,
          hover: ColorsPalette.input_border_hover,
          selected: ColorsPalette.input_border_selected,
          checkbox: ColorsPalette.checkbox_border,
        },
      },
      switch: {
        background: {
          selected: ColorsPalette.switch_background_selected,
        },
        text: {
          inactive: ColorsPalette.text_inactive,
        },
      },
      text: {
        link: ColorsPalette.text_link,
        header: ColorsPalette.text_header,
        paragraph: {
          primary: ColorsPalette.text_primary_paragraph,
          secondary: ColorsPalette.text_secondary_paragraph,
        },
      },
      error: {
        text: {
          base: ColorsPalette.error_base,
          notification: ColorsPalette.text_error_notification,
        },
        background: {
          base: ColorsPalette.error_base,
          hover: ColorsPalette.error_hover,
        },
      },
      footer: {
        background: {
          primary: ColorsPalette.footer_primary_background,
          secondary: ColorsPalette.footer_secondary_background,
        },
        text: {
          base: ColorsPalette.text_footer,
        },
      },
      commons: {
        divider: ColorsPalette.divider,
        icon_endorsement: ColorsPalette.icons_endorsement,
        UE: ColorsPalette.UE,
        background: {
          container: ColorsPalette.container_background,
          infotip: ColorsPalette.infotip_background,
          dimmed: ColorsPalette.dimmed_background,
        },
        tonal: {
          base: ColorsPalette.tonal_base,
          hover: ColorsPalette.tonal_hover,
        },
      },
    },
    typography: typography,
    shape: {
      button: {
        borderRadius,
      },
    },
    shadow: {
      dropdown: `${ColorsPalette.dropdown_shadow}`,
      contentBox: `${ColorsPalette.content_box_shadow}`,
      switch: `${ColorsPalette.switch_shadow}`,
      modal: `${ColorsPalette.modal_shadow}`,
    },
  };
};
