import React from 'react';
import { SliderProps } from './Slider.types';
import { StyledSlider, StyledThumb, StyledTrack } from './Slider.styled';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';

export const Slider = ({
  handleChange,
  handleAfterChange,
  maxLimit,
  value,
}: SliderProps): JSX.Element => {
  const Thumb = (props): JSX.Element => (
    <StyledThumb {...props} data-e2e="discountSliderThumb" />
  );

  const Track = (props, state): JSX.Element => (
    <StyledTrack {...props} index={state.index} />
  );

  return (
    <DefaultThemed>
      <StyledSlider
        max={maxLimit}
        defaultValue={0}
        renderTrack={Track}
        renderThumb={Thumb}
        onChange={(value) => handleChange(value)}
        onAfterChange={(value) => handleAfterChange(value)}
        value={value}
      />
    </DefaultThemed>
  );
};
