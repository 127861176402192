import React, { createContext, PropsWithChildren, useContext } from 'react';
import { CollapseContextType } from './Collapse.types';

const defaultConfig = {
  accordion: false,
  noClosible: false,
  groups: [],
  addHash: () => undefined,
  removeHash: () => undefined,
  addOneHash: () => undefined,
  removeAllGroup: () => undefined,
  getHash: () => 0,
};

export const CollapseContext =
  createContext<CollapseContextType>(defaultConfig);
export const useCollapse = (): CollapseContextType =>
  useContext<CollapseContextType>(CollapseContext);
export const CollapseProvider = (
  props: PropsWithChildren<{ value: CollapseContextType }>,
): JSX.Element => {
  return (
    <CollapseContext.Provider value={props.value}>
      {props.children}
    </CollapseContext.Provider>
  );
};
