import styled from 'styled-components';

export const CookieDescriptionItemContainer = styled.div`
  margin-bottom: 2rem;
`;

export const CookieDescriptionItemTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.paragraph.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 1rem;
  font-weight: bold;
`;
