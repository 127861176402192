import * as React from 'react';
import { StyledHelperTextWrapper, StyledHelperText } from './HelperText.styled';
import { DEFAULT_HELPER_TEXT_STYLES } from './HelperText.defaults';
import { HelperTextProps } from './HelperText.types';
import { BeeFieldState } from '../../BeeField.types';

export const HelperText: React.FC<HelperTextProps> = ({
  styles = DEFAULT_HELPER_TEXT_STYLES,
  state,
  fullWidth = false,
  error,
  helperText,
}: HelperTextProps): React.ReactElement => {
  const textContent = state === BeeFieldState.ERROR ? error : helperText;

  return (
    <StyledHelperTextWrapper styles={styles} fullWidth={fullWidth}>
      <StyledHelperText state={state} styles={styles}>
        {textContent}
      </StyledHelperText>
    </StyledHelperTextWrapper>
  );
};
