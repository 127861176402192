import styled from 'styled-components';

export const CookieDescriptionOrderedListItem = styled.li`
  counter-increment: item;
  list-style: none;
  margin-top: 1.7rem;
  padding: 0 0 0 2rem;
  position: relative;
  &:before {
    align-items: center;
    background: ${({ theme }) => theme.palette.brand.color};
    border-radius: 50%;
    color: #fff;
    content: counter(item);
    display: flex;
    font-size: 0.6rem;
    font-weight: 700;
    height: 1.2rem;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0rem;
    width: 1.2rem;
  }
`;
