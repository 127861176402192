import { useEffect } from 'react';

export const useResizeObserver = (
  element: HTMLElement | null,
  callback: ResizeObserverCallback,
  dependencies: unknown[],
): void => {
  useEffect(() => {
    if (!element) {
      return undefined;
    }
    const resizeObserver = new ResizeObserver(callback);
    resizeObserver.observe(element);
    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, element]);
};
