import { useEffect } from 'react';

export const useElementHeightListener = (
  elementRef: React.RefObject<HTMLDivElement>,
  onUpdateHeight?: (height: number) => void,
): void => {
  useEffect(() => {
    if (!onUpdateHeight || !elementRef.current) {
      return undefined;
    }

    const divElement = elementRef.current;

    const resizeObserver = new ResizeObserver(() => {
      onUpdateHeight(divElement.offsetHeight);
    });

    resizeObserver.observe(divElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [elementRef, onUpdateHeight]);
};
