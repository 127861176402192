import * as React from 'react';
import styled from 'styled-components';

import { PositionType } from './Tabs.types';

type StyledTabWrapperType = {
  duration: string;
  isActive: boolean;
  tabStyle?: React.CSSProperties;
  activeTabStyle?: React.CSSProperties;
};
type StyledBackdropType = PositionType & {
  duration: string;
  isVisible: boolean;
  backdropStyle?: React.CSSProperties;
};
type StyledTabsWrapperType = {
  styles?: React.CSSProperties;
};

export const StyledTabWrapper = styled.div<StyledTabWrapperType>`
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  ${({ tabStyle }) => tabStyle && { ...tabStyle }}
  ${({ isActive, activeTabStyle }) =>
    isActive && activeTabStyle && { ...activeTabStyle }}
    z-index: 2;
`;

const getTransition = (duration: string): string => {
  return `transition: left ${duration}, top ${duration}, opacity ${duration};`;
};

export const StyledBackdrop = styled.div<StyledBackdropType>`
  background-color: ${(p) =>
    p.color ?? p.theme.palette.commons.background.container};
  box-shadow: ${(p) => p.theme.shadow.switch};
  height: ${(p) => p.offsetHeight}px;
  left: ${(p) => p.left}px;
  opacity: ${(p) => (p.isVisible ? '1' : '0')};
  position: absolute;
  top: ${(p) => p.top}px;
  ${({ duration }) => getTransition(duration)};
  width: ${(p) => p.offsetWidth}px;
  z-index: 1;
  ${({ backdropStyle }) => backdropStyle && { ...backdropStyle }}
`;

export const StyledTabsContainer = styled.div<StyledTabsWrapperType>`
  position: relative;
`;
export const StyledTabsWrapper = styled.div<StyledTabsWrapperType>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  ${({ styles }) => styles && { ...styles }}
`;
