import { DateFromPickerType, DatesRange } from '../BeeDatePicker.types';
import {
  DAYS,
  DAY_LENGTH,
  MAX_END_YEAR,
  MIN_START_YEAR,
  MONTHS,
  MONTH_LENGTH,
} from '../components';
import { getMonthIndex } from '../contexts';
import { isDatePickerFormFilled } from './isDatePickerFormFilled';
import { isDayInRange, isMonthInRange, isYearInRange } from './isInRange';

export function isValidPickerDate(
  date: DateFromPickerType,
  datesRange?: DatesRange,
): boolean {
  if (!isDatePickerFormFilled(date)) {
    return false;
  }
  return (
    isValidYear(date.year, datesRange) &&
    isValidMonth(date, datesRange) &&
    isValidDay(date, datesRange)
  );
}

export function isValidYear(year: string, datesRange?: DatesRange): boolean {
  const numberYear = Number(year);

  if (datesRange) {
    return isYearInRange(numberYear, datesRange);
  } else {
    return numberYear >= MIN_START_YEAR && numberYear <= MAX_END_YEAR;
  }
}
export function isValidMonth(
  date: DateFromPickerType,
  datesRange?: DatesRange,
): boolean {
  const monthIndex = getMonthIndex(date.month);

  if (datesRange) {
    return isMonthInRange(date.year, monthIndex, datesRange);
  } else {
    return date.month.length === MONTH_LENGTH && monthIndex <= MONTHS;
  }
}

export function isValidDay(
  date: DateFromPickerType,
  datesRange?: DatesRange,
): boolean {
  if (datesRange) {
    return isDayInRange(date.day, date, datesRange);
  } else {
    return date.day.length === DAY_LENGTH && Number(date.day) <= DAYS;
  }
}
