import {
  AvailableCookieModalLanguages,
  CookieModalMarkDownContent,
  TenantSourceInfo,
} from '../../types';
import {
  getCookieBarDescriptionInEnglish,
  getCookieBarDescriptionInPolish,
} from './cookieBarDescriptions';

export const cookieBarDescriptionsPerLanguage = (
  sourceInfo: TenantSourceInfo,
  language: AvailableCookieModalLanguages,
): CookieModalMarkDownContent[] =>
  ({
    pl: getCookieBarDescriptionInPolish(sourceInfo),
    en: getCookieBarDescriptionInEnglish(sourceInfo),
  }[language]);
