import styled from 'styled-components';
import { CardProps } from './Card.types';

export const CardContainer = styled.div<CardProps>`
  background-color: ${(p) => p.theme.palette.commons.background.container};
  border: ${(p) =>
    p.outlined
      ? `solid 1px${p.theme.palette.commons.background.container}`
      : 'none'};
  border-radius: ${(p) => (p.radius ? `${p.radius}rem` : '0')};
  box-shadow: ${(p) => (p.shadow ? p.theme.shadow.contentBox : 'none')};
  box-sizing: border-box;
  display: block;
  padding: ${(p) => (p.padding ? `${p.padding}rem` : '2rem')};
  width: ${(p) => p.width ?? 'auto'};
`;
