import React, { createContext, PropsWithChildren, useContext } from 'react';
import { ANIMATIONS } from '../Theme/utils/animations';
import { TabsContextType } from './Tabs.types';

const defaultConfig = {
  value: '',
  duration: ANIMATIONS.defaultTransitionTime,
  tabStyle: undefined,
  activeTabStyle: undefined,
  onChange: () => undefined,
  setBackdropPosition: () => undefined,
};

export const TabsContext = createContext<TabsContextType>(defaultConfig);
export const useTabs = (): TabsContextType =>
  useContext<TabsContextType>(TabsContext);
export const TabsProvider = (
  props: PropsWithChildren<{ value: TabsContextType }>,
): JSX.Element => {
  return (
    <TabsContext.Provider value={props.value}>
      {props.children}
    </TabsContext.Provider>
  );
};
