import { CookiePolicyDescription, TenantSourceInfo } from '../../../types';
import { cookiePolicyLinks } from '../../constants';

export const getPolishCookiePolicyDescription = (
  sourceInfo: TenantSourceInfo,
): CookiePolicyDescription[] => [
  {
    title: 'Jakie funkcje spełniają cookies?',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `W ramach serwisu ${sourceInfo.sourceName} pliki cookies wykorzystywane są w celu:`,
          },
        ],
      },
      {
        type: 'orderedList',
        orderedElements: [
          {
            markdownTextContent: [
              {
                type: 'text',
                text: `Analizy zachowań użytkowników w celu dostosowania zawartości strony do preferencji Użytkownika oraz optymalizacji 
                  korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika i wyświetlić stronę 
                  internetową zgodnie z jego indywidualnymi preferencjami i potrzebami.`,
              },
            ],
          },
          {
            markdownTextContent: [
              {
                type: 'text',
                text: 'Tworzenia statystyk, poprzez narzędzie Google Analytics dostarczane przez Google Ireland Ltd, w oparciu o',
              },
              {
                type: 'linkedText',
                text: 'politykę prywatności google.',
                link: `${cookiePolicyLinks.googlePolicy}?hl=pl`,
              },
              {
                type: 'text',
                text: 'Statystyki te dostarczają informacji, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Jakie cookies wykorzystujemy?',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `Serwis ${sourceInfo.sourceName} wykorzystuje następujące pliki cookies:`,
          },
        ],
      },
      {
        type: 'orderedList',
        orderedElements: [
          {
            markdownTextContent: [
              {
                type: 'text',
                text: `sesyjne - pliki tymczasowe, które przechowywane są w urządzeniu końcowym Użytkownika do 
                  czasu wylogowania, opuszczenia strony internetowej lub wyłączenia 
                  oprogramowania (przeglądarki internetowej).`,
              },
            ],
          },
          {
            markdownTextContent: [
              {
                type: 'text',
                text: `stałe - pliki przechowywane w urządzeniu końcowym Użytkownika przez czas określony w 
                  parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.`,
              },
            ],
          },
        ],
      },
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: 'Dodatkowo, w ramach serwisu wykorzystujemy pliki cookies:',
          },
        ],
      },
      {
        type: 'orderedList',
        orderedElements: [
          {
            markdownTextContent: [
              {
                type: 'text',
                text: `„niezbędne”, są kluczowe do poprawnego działania strony internetowej. 
                  Zapewniają podstawowe funkcjonalności i bezpieczeństwo korzystania ze strony internetowej.`,
              },
            ],
          },
          {
            markdownTextContent: [
              {
                type: 'text',
                text: '„analityczne”, używane są do pozyskania informacji o sposobie i statystyk korzystania przez użytkowników ze strony internetowej.',
              },
            ],
          },
          {
            markdownTextContent: [
              {
                type: 'text',
                text: `„funkcjonalne”, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w 
                  zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.`,
              },
            ],
          },
          {
            markdownTextContent: [
              {
                type: 'text',
                text: '„reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Piksel Facebook',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `Serwis ${sourceInfo.sourceName} korzysta również z piksela serwisu społecznościowego („Piksel”), mającego na celu monitorowanie działań, 
              jakie Użytkownicy wykonują w serwisie ${sourceInfo.sourceName}. Piksel gromadzi m.in. informacje o adresach URL jakie odwiedzają w 
              użytkownicy naszego serwisu, którzy posiadają konto osobiste w serwisie Facebook. Dane te służą do precyzyjnego kierowania reklam. 
              Więcej informacji na temat działań remarketingowych pikseli oraz prezentowania reklamy na Facebooku można znaleźć w`,
          },
          {
            type: 'linkedText',
            text: 'polityce prywatności Facebooka.',
            link: cookiePolicyLinks.facebookPolicy,
          },
          {
            type: 'text',
            text: 'Piksel jest narzędziem dostarczanym przez Facebook Inc.',
          },
        ],
      },
    ],
  },
  {
    title: 'reCAPTCHA',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `${sourceInfo.tenant} może wykorzystywać mechanizm weryfikacji reCAPTCHA w oparciu o`,
          },
          {
            type: 'linkedText',
            text: 'politykę prywatności Google.',
            link: `${cookiePolicyLinks.googlePolicy}?hl=pl`,
          },
        ],
      },
    ],
  },
  {
    title: 'Google Tag Manager',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `Serwis ${sourceInfo.sourceName} wykorzystuje Google Tag Manager. Narzędzie to jest stosowane do 
              zarządzania tagami (znacznikami) w celu umożliwienia pozyskiwania szczegółowych statystyk używania Serwisu 
              oraz optymalizację Serwisu.`,
          },
        ],
      },
    ],
  },
  {
    title: 'Hotjar',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `W serwisie ${sourceInfo.sourceName} korzystamy z narzędzia HotJar, które pozwala uzyskać informację o 
              czynnościach wykonywanych na stronie przez użytkownika takich jak ruch kursora, przewijanie strony oraz miejscach, 
              w które użytkownik klika. Polityka prywatności Hotjar dostępna jest`,
          },
          {
            type: 'linkedText',
            text: 'tutaj.',
            link: cookiePolicyLinks.hotjarPolicy,
          },
        ],
      },
    ],
  },
  {
    title: 'Synerise',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `Korzystamy z usług Synerise S.A. polegających na gromadzeniu informacji o sesji Użytkownika w celach 
              analitycznych, poprawienia funkcjonalności strony oraz profilowania i retargetingu. Dodatkowo narzędzie wykorzystywane 
              jest do dopasowywania treści do użytkownika. Szczegółowe informacje na temat przetwarzania danych przez Synerise 
              znajdują się w`,
          },
          {
            type: 'linkedText',
            text: 'polityce prywatności.',
            link: cookiePolicyLinks.synerisePolicy,
          },
        ],
      },
    ],
  },
  {
    title: 'Blokowanie ciasteczek',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) 
              domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownik ma jednak możliwość 
              samodzielnego zarządzania ustawieniami dotyczącymi plików cookies. Szczegółowe informacje o możliwości i sposobach obsługi 
              plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej) oraz`,
          },
          {
            type: 'boldText',
            text: 'w ustawieniach zaawansowanych dot. plików cookies w serwisie',
          },
          {
            type: 'linkedText',
            text: sourceInfo.sourceName,
            link: sourceInfo.link,
          },
        ],
      },
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `Zmieniając ustawienia dotyczące plików cookies warto mieć na uwadze, że ograniczenie ich stosowania może 
              wpłynąć na niektóre funkcjonalności dostępne w ramach serwisu`,
          },
          {
            type: 'linkedText',
            text: sourceInfo.sourceName,
            link: sourceInfo.link,
          },
          {
            type: 'boldText',
            text: 'lub też zupełnie zablokować dostęp do serwisu.',
          },
        ],
      },
    ],
  },
];
