import React, { FC, useEffect, useState } from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import {
  StyledToggleSelect,
  StyledToggleOption,
  StyledToggleOptionLabel,
  StyledIcon,
} from './ToggleSelect.styled';
import {
  ToggleSelectProps,
  ToggleSelectOptionProps,
} from './ToggleSelect.type';

export const ToggleSelect: FC<ToggleSelectProps> = ({
  id,
  options,
  onClick,
  singleSelect,
  values,
  dataE2e,
  isHorizontal,
}: ToggleSelectProps) => {
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (values) {
      setSelected(values);
    }
  }, [values]);

  const getName = (option: ToggleSelectOptionProps): string => {
    return option.name
      ? option.name
      : option.label.toLowerCase().replace(' ', '');
  };

  const getIcon = (path: string | undefined): JSX.Element | undefined => {
    if (typeof path === 'string' && !!path.length) {
      return <StyledIcon src={path} alt="icon" />;
    }
    return undefined;
  };

  const handleChange = (
    name: string,
    option: ToggleSelectOptionProps,
  ): void => {
    // Handle passed action
    onClick && onClick(name, singleSelect ? option.value : !selected[name]);

    // Handle component state
    singleSelect
      ? setSelected({ [name]: option.value })
      : setSelected({ ...selected, ...{ [name]: !selected[name] } });
  };
  const hasDataE2e = Boolean(dataE2e);
  const e2eAttr = hasDataE2e
    ? Object.entries(selected)
        .map(([key, value]) => {
          const suffix = singleSelect ? value : value ? 'Checked' : 'Unchecked';
          return `${dataE2e?.[key]}${suffix}`;
        })
        .join(' ')
    : '';

  return (
    <DefaultThemed>
      <StyledToggleSelect
        id={id}
        data-e2e={`${e2eAttr}`}
        isHorizontal={isHorizontal}
      >
        {(options || []).map((option: ToggleSelectOptionProps) => {
          const name = getName(option);
          const isSelected = singleSelect
            ? selected[name] === option.value
            : selected[name];
          const Icon = getIcon(option.iconPath);

          const testAttr = {
            'data-e2e-name': option.name,
            'data-e2e-checked': isSelected,
          };
          return (
            <StyledToggleOption
              key={`${name}_${option.value}`}
              isSelected={isSelected}
              onClick={() => handleChange(name, option)}
              hasIcon={Boolean(Icon)}
              isHorizontal={isHorizontal}
              {...testAttr}
            >
              {Icon}
              <StyledToggleOptionLabel>{option.label}</StyledToggleOptionLabel>
            </StyledToggleOption>
          );
        })}
      </StyledToggleSelect>
    </DefaultThemed>
  );
};
