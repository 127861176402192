import { keyframes } from 'styled-components';

export const cookieBarAppearFrame = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;
