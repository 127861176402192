import React, { FC, useState } from 'react';
import { ReactComponent as Check } from '../assets/svg/check.svg';
import { ReactComponent as State } from '../assets/svg/icon-nav-back.svg';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import {
  Content,
  FooterContent,
  Header,
  HeaderDetails,
  StateIcon,
  StyledAccordion,
  StyledFilled,
  Subtitle,
  Title,
} from './Accordion.styled';
import { AccordionProps } from './Accordion.type';

export const Accordion: FC<AccordionProps> = ({
  id,
  title,
  subtitle,
  isExpanded = false,
  filled,
  children,
  dataE2e,
  accordionFooter,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(isExpanded);

  const handleToggle = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <DefaultThemed>
      <StyledAccordion id={id} data-e2e={`${dataE2e}Extended`}>
        <Header
          isOpen={isOpen}
          onClick={children ? handleToggle : undefined}
          hasChildren={Boolean(children)}
        >
          {filled && (
            <StyledFilled>
              <Check width="16px" />
            </StyledFilled>
          )}
          <HeaderDetails>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </HeaderDetails>
          {children && (
            <StateIcon isOpen={isOpen}>
              <State />
            </StateIcon>
          )}
        </Header>
        {isOpen && <Content>{children}</Content>}
        {accordionFooter ? (
          <FooterContent>{accordionFooter}</FooterContent>
        ) : null}
      </StyledAccordion>
    </DefaultThemed>
  );
};
