import { CookiePolicyDescription, TenantSourceInfo } from '../../../types';
import { cookiePolicyLinks } from '../../constants';

export const getEnglishCookiePolicyDescription = (
  sourceInfo: TenantSourceInfo,
): CookiePolicyDescription[] => [
  {
    title: 'What functions do cookies perform?',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `Within the ${sourceInfo.sourceName} service, cookies are used for:`,
          },
        ],
      },
      {
        type: 'orderedList',
        orderedElements: [
          {
            markdownTextContent: [
              {
                type: 'text',
                text: `Analyzing user behavior to customize the content of the page to the User's preferences and optimize the use of websites; 
                    in particular, these files allow recognizing the User's device and displaying the website according to their individual preferences and needs.`,
              },
            ],
          },
          {
            markdownTextContent: [
              {
                type: 'text',
                text: 'Creating statistics through the Google Analytics tool provided by Google Ireland Ltd, based on the',
              },
              {
                type: 'linkedText',
                text: 'Google privacy policy.',
                link: `${cookiePolicyLinks.googlePolicy}?hl=en`,
              },
              {
                type: 'text',
                text: 'These statistics provide information on how Users of the Service use websites.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'What cookies do we use?',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `The ${sourceInfo.sourceName} service uses the following cookies:`,
          },
        ],
      },
      {
        type: 'orderedList',
        orderedElements: [
          {
            markdownTextContent: [
              {
                type: 'text',
                text: `session - temporary files stored on the User's end device until they log out, leave the website, or turn off 
                    the software (web browser).`,
              },
            ],
          },
          {
            markdownTextContent: [
              {
                type: 'text',
                text: `persistent - files stored on the User's end device for a specified time in the cookie parameters or until they are 
                    deleted by the User.`,
              },
            ],
          },
        ],
      },
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: 'Additionally, within the service, we use cookies:',
          },
        ],
      },
      {
        type: 'orderedList',
        orderedElements: [
          {
            markdownTextContent: [
              {
                type: 'text',
                text: `"necessary", which are essential for the proper functioning of the website. They provide basic functionality and security 
                    for using the website.`,
              },
            ],
          },
          {
            markdownTextContent: [
              {
                type: 'text',
                text: '"analytical", used to obtain information about the way and statistics of users using the website.',
              },
            ],
          },
          {
            markdownTextContent: [
              {
                type: 'text',
                text: `"functional", enabling the "remembering" of User-selected settings and personalization of the User interface, e.g., 
                    in terms of the selected language or region, font size, appearance of the website, etc.`,
              },
            ],
          },
          {
            markdownTextContent: [
              {
                type: 'text',
                text: '"advertising" cookies, enabling the delivery of advertising content more tailored to their interests to Users.',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Facebook Pixel',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `The ${sourceInfo.sourceName} service also uses a pixel from the social networking service ("Pixel") designed to monitor actions 
                that Users perform on the ${sourceInfo.sourceName} service. The Pixel collects, among other things, information about URLs visited by 
                users of our service who have a personal account on Facebook. This data is used for precise targeting of ads. More information about 
                remarketing actions of pixels and displaying ads on Facebook can be found in the`,
          },
          {
            type: 'linkedText',
            text: 'Facebook privacy policy.',
            link: cookiePolicyLinks.facebookPolicy,
          },
          {
            type: 'text',
            text: 'The Pixel is a tool provided by Facebook Inc.',
          },
        ],
      },
    ],
  },
  {
    title: 'reCAPTCHA',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `${sourceInfo.tenant} may use the reCAPTCHA verification mechanism based on the`,
          },
          {
            type: 'linkedText',
            text: 'Google privacy policy.',
            link: `${cookiePolicyLinks.googlePolicy}?hl=en`,
          },
        ],
      },
    ],
  },
  {
    title: 'Google Tag Manager',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `The ${sourceInfo.sourceName} service uses Google Tag Manager. This tool is used to manage tags to enable detailed statistics 
                of the use of the Service and optimize the Service.`,
          },
        ],
      },
    ],
  },
  {
    title: 'Hotjar',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `In the ${sourceInfo.sourceName} service, we use the HotJar tool, which allows us to obtain information about actions performed on 
                the site by the user, such as cursor movement, scrolling, and places where the user clicks. The Hotjar privacy policy is available`,
          },
          {
            type: 'linkedText',
            text: 'here.',
            link: cookiePolicyLinks.hotjarPolicy,
          },
        ],
      },
    ],
  },
  {
    title: 'Synerise',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `We use the services of Synerise S.A., which involve collecting information about the User's session 
                for analytical purposes, improving the functionality of the site, as well as profiling and retargeting. 
                Additionally, the tool is used to tailor content to the user. Detailed information on data processing by Synerise 
                can be found in the`,
          },
          {
            type: 'linkedText',
            text: 'privacy policy.',
            link: cookiePolicyLinks.synerisePolicy,
          },
        ],
      },
    ],
  },
  {
    title: 'Blocking cookies',
    paragraphs: [
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `In many cases, the software used for browsing websites (web browser) 
                defaults to allowing the storage of cookie files on the User's end device. However, the User has the option 
                to independently manage cookie file settings. Detailed information on the possibilities and methods of handling 
                cookies is available in the software settings (web browser) and`,
          },
          {
            type: 'boldText',
            text: 'in advanced cookie settings in the',
          },
          {
            type: 'linkedText',
            text: sourceInfo.sourceName,
            link: sourceInfo.link,
          },
        ],
      },
      {
        type: 'textParagraph',
        markdownTextContent: [
          {
            type: 'text',
            text: `When changing cookie file settings, it is worth noting that limiting their use may affect 
                some functionalities available within the service`,
          },
          {
            type: 'linkedText',
            text: sourceInfo.sourceName,
            link: sourceInfo.link,
          },
          {
            type: 'boldText',
            text: 'or even completely block access to the service.',
          },
        ],
      },
    ],
  },
];
