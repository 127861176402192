import { Theme } from '../../utils/theme.type';

export const typography: Theme['typography'] = {
  fontFamily: '"Dosis", sans-serif',
  h2: {
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  h4: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: 'normal',
  },
  subtitle1: {
    fontSize: '1.125rem',
    lineHeight: '1.5',
    fontWeight: 400,
  },
  body1: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
  },
  chipsMedium: {
    fontSize: '1rem',
    lineHeight: '2.25rem',
    fontWeight: 400,
  },
  chipsSmall: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
  },
  text1: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
  },
  info: {
    fontSize: '0.75rem',
    lineHeight: '1.7rem',
    fontWeight: 500,
  },
};
