import React from 'react';
import { CookieModalMarkDownContent } from '../../../../types';
import { CookieMarkdownLinkedText } from './StyleTextByType.styles';

export const StyleTextByType: React.FC<{
  markdownContent: CookieModalMarkDownContent;
}> = ({ markdownContent }) => {
  switch (markdownContent.type) {
    case 'text':
      return <span>{markdownContent.text} </span>;
    case 'boldText':
      return <b>{markdownContent.text} </b>;
    case 'linkedText':
      return (
        <span>
          <CookieMarkdownLinkedText href={markdownContent.link} target="_blank">
            {markdownContent.text}
          </CookieMarkdownLinkedText>{' '}
        </span>
      );
    default:
      return null;
  }
};
