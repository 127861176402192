import styled from 'styled-components';

export const CookiePolicyDescriptionText = styled.div`
  color: ${({ theme }) => theme.palette.text.paragraph.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;

export const CookiePolicyDescriptionTextContainer = styled.div`
  margin-top: 1rem;
`;
