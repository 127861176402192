import { keyframes } from 'styled-components';

export const CookieModalOverlayAppearFrame = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const CookieModalOverlayDisappearFrame = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const CookieModalAppearFrame = keyframes`
  from {
    transform: translateY(-10%);
  }
  to {
    transform: translateY(0);
  }
`;

export const CookieModalDisappearFrame = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
`;

export const CookieModalAppearFrameMobile = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const CookieModalDisappearFrameMobile = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`;
