import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

export const StyledHeader = styled.div`
  color: ${({ theme }) => theme.palette.text.header};
  font-size: 1rem;
  font-weight: 900;
  height: 1.5rem;
  margin: 1.25rem 0;
  position: relative;
  text-align: center;
`;

const yearGroupButton = (color: string): string => `
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  &:focus {
    outline: 1px dashed ${color};
  }
`;

export const StyledHorizontalButton = styled.button<{
  horizontalPosition: 'left' | 'right';
}>`
  ${({ theme }) => yearGroupButton(theme.shadow.contentBox)};
  ${({ horizontalPosition }) =>
    horizontalPosition === 'left' ? 'left: 0;' : 'right: 0;'};

  path {
    stroke: ${({ theme }) => theme.palette.button.border.select.hover};
  }
`;

export const StyledYearsWrapper = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 2.5rem 2.5rem 2.5rem 2.5rem 2.5rem;

  ${isMobile &&
  css`
    padding: 0 1rem;
  `};
`;
