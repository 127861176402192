import styled, { DefaultTheme } from 'styled-components';
import { BeeFieldState, BeeFieldStyles } from '../../BeeField.types';
import { getFieldOpacity } from '../../utils/getFieldOpacity.util';

export const getHelperTextColor = ({
  state,
  theme,
}: {
  state: BeeFieldState;
  theme: DefaultTheme;
}): string => {
  if (state === BeeFieldState.ERROR) {
    return theme.palette.error.text.base;
  } else {
    return theme.palette.text.paragraph.primary;
  }
};

export const StyledHelperTextWrapper = styled('div')<{
  fullWidth?: boolean;
  styles: BeeFieldStyles;
}>`
  margin-top: 0.5rem;
  max-width: ${({ fullWidth, styles }) =>
    fullWidth ? '' : styles?.default?.maxWidth};
  padding: 0 ${({ styles }) => styles?.default?.left};
  word-break: break-word;
`;

export const StyledHelperText = styled('div')<{
  styles: BeeFieldStyles;
  state: BeeFieldState;
}>`
  caret-color: transparent;
  color: ${({ state, theme }) => getHelperTextColor({ theme, state })};
  font-size: 0.75rem;
  line-height: 1rem;
  opacity: ${({ state }) => getFieldOpacity(state)};
  transition: color 0.2s, opacity 0.2s;
`;
