import React, { useState } from 'react';
import { IDS_OF_REQUIRED_CONSENTS } from '../../context/CookieModalProvider';
import { CookieModalCheckbox } from '../CookieModalCheckBox/CookieModalCheckBox';
import {
  CookieModalArrowContainer,
  CookieModalConsentInfo,
  CookieModalConsentWrapper,
} from './CookieModalConsent.styles';
import { CookieModalConsentDefinition } from '../../types';
import { useCookieModalContext } from '../../context/useCookieModalContext';
import { StyledChevron } from '../../../Modal/ConsentsModal/ConsentsModal.styled';

type Props = {
  consent: CookieModalConsentDefinition;
};

export const CookieModalConsent: React.FC<Props> = ({ consent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { handleClickOnConsentCheckbox, selectedConsentIds } =
    useCookieModalContext();

  return (
    <>
      <CookieModalConsentWrapper>
        <CookieModalCheckbox
          key={consent.id}
          checked={selectedConsentIds.includes(consent.id)}
          onClick={() => handleClickOnConsentCheckbox(consent.id)}
          label={consent.title}
          isDisabled={IDS_OF_REQUIRED_CONSENTS.includes(consent.id)}
        />
        <CookieModalArrowContainer
          data-testid={'CookieModalArrowContainer'}
          onClick={() => setIsOpen(!isOpen)}
        >
          <StyledChevron isExpanded={isOpen} />
        </CookieModalArrowContainer>
      </CookieModalConsentWrapper>
      <CookieModalConsentInfo isOpen={isOpen}>
        {consent.description}
      </CookieModalConsentInfo>
    </>
  );
};
