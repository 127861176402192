import React, { FC } from 'react';
import { ReactComponent as Back } from '../assets/svg/chevron-left.svg';
import { ReactComponent as Close } from '../assets/svg/close.svg';
import { NavButton, StyledNavbar, Title } from './MobilePopupTopNavbar.styled';

export interface MobilePopupTopNavbarProps {
  onBack?: () => void;
  title?: string;
  onClose?: () => void;
}

export const MobilePopupTopNavbar: FC<MobilePopupTopNavbarProps> = ({
  onBack,
  title,
  onClose,
}: MobilePopupTopNavbarProps) => (
  <StyledNavbar>
    {onBack && (
      <NavButton align={'flex-start'} position={'left'} onClick={onBack}>
        <Back />
      </NavButton>
    )}
    {title && <Title>{title}</Title>}
    {onClose && (
      <NavButton align={'flex-end'} position={'right'} onClick={onClose}>
        <Close />
      </NavButton>
    )}
  </StyledNavbar>
);
