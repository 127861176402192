import styled from 'styled-components';

type Open = { open?: boolean };

export const RollUpButton = styled.div<Open>`
  align-items: center;
  background-color: ${(p) => p.theme.palette.commons.background.container};
  border-bottom: 0.063rem solid rgba(0, 0, 0, 0.12);
  border-radius: 0.125rem;
  box-sizing: border-box;
  color: ${(p) => p.theme.palette.brand.color};
  cursor: pointer;
  display: flex;
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-weight: bold;
  justify-content: flex-start;
  padding: 0.75rem 1rem;
`;

export const RollUpIcon = styled.div<Open>`
  display: inline-block;
  height: 1.5rem;
  margin-left: auto;
  transform: rotate(${(p: Open) => (p.open ? '-180deg' : 'none')});
  transition: transform ease-in 0.2s;
`;

export const RollUpContainer = styled.div<Open>`
  background-color: ${(p) => p.theme.palette.commons.background.container};
  box-align: border-box;
  font-family: ${(p) => p.theme.typography.fontFamily};
  height: ${(p: Open) => (p.open ? '3.5rem' : '0rem')};
  overflow: hidden;
  position: relative;
  transition: height ease-in 0.2s;
  width: 100%;
`;
