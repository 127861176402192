import styled from 'styled-components';
import { CustomCheckboxProps } from './CookieModalCheckBox.types';

export const CookieModalCheckboxContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
`;

export const CookieModalCheckIcon = styled.div<
  Pick<CustomCheckboxProps, 'checked' | 'isDisabled'>
>`
  border: ${({ theme }) => `2px solid ${theme.palette.brand.color}`};
  border-left: 0;
  border-top: 0;
  content: '';
  display: ${({ checked }) => (checked ? 'block' : 'none')};
  height: 0.8125rem;
  left: 50%;
  left: 0.3rem;
  top: 0rem;
  transform: rotate(43deg);
  width: 0.375rem;
  ${({ isDisabled }) =>
    !isDisabled &&
    `
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
  `}
`;

export const CookieModalCheckBoxFrame = styled.div<
  Pick<CustomCheckboxProps, 'checked' | 'isDisabled'>
>`
  border: ${({ theme }) =>
    `solid ${theme.palette.input.border.checkbox} 0.125rem`};
  border-radius: 0.125rem;
  cursor: pointer;
  display: flex;
  height: 1.2rem;
  justify-content: center;
  padding-top: 0.08rem;
  width: 1.2rem;

  ${({ checked, isDisabled, theme }) => {
    if (checked && isDisabled) {
      return `
        border: none;
      `;
    }

    if (checked && !isDisabled) {
      return `
        background-color: ${theme.palette.brand.color};
        border: none;
      `;
    }

    return null;
  }}
`;

export const CookieModalCheckBoxLabel = styled.span`
  color: ${({ theme }) => theme.palette.text.paragraph.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  margin-left: 1rem;
`;
