import { TenantSourceInfo } from '../../types';
import { Tenants } from '../../../shared/types';

type TenantSources = {
  [key in Tenants]: TenantSourceInfo;
};

export const tenantSources: TenantSources = {
  compensa: {
    tenant: 'Compensa',
    sourceName: 'www.compensa.pl',
    link: 'https://www.compensa.pl/',
  },
  beesafe: {
    tenant: 'Beesafe',
    sourceName: 'www.beesafe.pl',
    link: 'https://www.beesafe.pl/',
  },
  benefia: {
    tenant: 'Benefia',
    sourceName: 'www.benefia.pl',
    link: 'https://www.benefia.pl/',
  },
};
