import { ColorsType } from '../../utils/colors.type';

export const ColorsPalette: ColorsType = {
  brand: '#4545FF',
  brand_background: '#FFE096',

  primary_button_background: '#FFC659',
  primary_button_background_hover: '#FACF79',
  primary_button_background_after: '#FFFFFF',

  secondary_button_background: '#4545FF',
  secondary_button_background_hover: '#5757FF',
  secondary_button_background_after: '#FFFFFF',

  outline_button_background: '#FFFFFF',
  outline_button_background_hover: '#F6F6FF',
  outline_button_background_after: '#4545FF',

  select_button_background: '#FFFFFF',
  select_button_background_selected: '#F6F6FF',
  select_button_background_after: '#4545FF',

  outline_button_border: '#4545FF',
  outline_button_border_hover: '#4545FF',

  select_button_border: '#DBE4F5',
  select_button_border_hover: '#4545FF',
  select_button_border_selected: '#4545FF',

  text_primary_button: '#000000',
  text_secondary_button: '#FFFFFF',
  text_outline_button: '#4545FF',
  text_outline_button_hover: '#4545FF',
  text_select_button: '#000000',

  text_link: '#4545FF',
  text_header: '#0e0e90',
  text_primary_paragraph: '#0e0e90',
  text_secondary_paragraph: '#494C85',

  text_input: '#5E6870',
  text_input_selected: '#000000',

  text_inactive: '#A4A5C2',

  input_border: '#ACBCC8',
  input_border_hover: '#3A3A3A',
  input_border_selected: '#4545FF',

  checkbox_border: '#000000',

  switch_background_selected: '#FFFFFF',

  error_base: '#FF4C2F',
  text_error_notification: '#FFFFFF',
  error_hover: '#FFF1EE',

  tonal_base: '#F6F6FF',
  tonal_hover: '#F0F0FF',

  container_background: '#FFFFFF',
  infotip_background: '#F6F6FF',
  dimmed_background: '#000000',
  icons_endorsement: '#829098',
  divider: '#F0F0FF',
  UE: '#4545FF',

  footer_primary_background: '#494C85',
  footer_secondary_background: '#F6F6FF',
  text_footer: '#FFFFFF',

  dropdown_shadow: '0px 2px 8px rgba(69, 69, 255, 0.15)',
  content_box_shadow: '0px 10px 60px rgba(69, 69, 255, 0.15)',
  switch_shadow: '0px 5px 10px rgba(69, 69, 255, 0.15)',
  modal_shadow: '0px 10px 40px rgba(0, 0, 0, 0.15)',
} as const;
