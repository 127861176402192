import * as React from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import {
  CloudInfoContainer,
  CloudInfoHeader,
  CloudInfoIcon,
  CloudInfoTip,
} from './CloudInfo.styled';
import { CloudInfoProps } from './CloudInfo.types';

export const CloudInfo: React.FC<CloudInfoProps> = ({
  header,
  description,
  cloudDescCSS,
}: CloudInfoProps) => {
  return (
    <DefaultThemed>
      <CloudInfoContainer>
        {header && (
          <CloudInfoHeader>
            <CloudInfoIcon>i</CloudInfoIcon>
            {header}
          </CloudInfoHeader>
        )}
        {description && (
          <CloudInfoTip padding={cloudDescCSS?.padding ?? null}>
            {description}
          </CloudInfoTip>
        )}
      </CloudInfoContainer>
    </DefaultThemed>
  );
};
