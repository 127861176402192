import {
  AvailableCookieModalLanguages,
  CookiePolicyDescription,
  TenantSourceInfo,
} from '../../types';
import {
  getEnglishCookiePolicyDescription,
  getPolishCookiePolicyDescription,
} from './getCookiePolicyDescriptionLanguages';

export const getCookiePolicyDescription = (
  sourceInfo: TenantSourceInfo,
  language: AvailableCookieModalLanguages,
): CookiePolicyDescription[] =>
  ({
    pl: getPolishCookiePolicyDescription(sourceInfo),
    en: getEnglishCookiePolicyDescription(sourceInfo),
  }[language]);
