import styled from 'styled-components';
import { ReactComponent as Close } from '../../../assets/svg/close.svg';
import { ReactComponent as Chevron } from '../../../assets/svg/chevron-left.svg';

export const StyledContainer = styled.div<{
  dialogFullScreen?: boolean;
}>`
  background: ${(p) => p.theme.palette.commons.background.container};
  border-radius: ${(p) => (p.dialogFullScreen ? '0' : '0.5rem')};
  font-family: ${(p) => p.theme.typography.fontFamily};
  height: ${(p) => (p.dialogFullScreen ? '100vh' : 'auto')};
  overflow: auto;
  width: ${(p) => (p.dialogFullScreen ? '100vw' : 'auto')};
`;
export const StyledInputContainer = styled.div`
  padding: 0 1rem;
`;
export const StyledOptionsContainer = styled.div`
  padding: 0 0.25rem;
`;
export const StyledTitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  padding-bottom: 0;
`;
export const StyledStickyContainer = styled.div`
  background: ${(p) => p.theme.palette.commons.background.container};
  position: sticky;
  top: 0;
`;
export const StyledLabel = styled.div`
  color: ${(p) => p.theme.palette.button.text.outline.hover};
  font-size: 0.875rem;
  font-weight: bold;
`;
export const StyledBackContainer = styled.div`
  height: 1rem;
  width: 1rem;
`;
export const StyledBackIcon = styled(Chevron)`
  height: 1rem;
  width: 1rem;
`;
export const StyledSearchIconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 3rem;
`;
export const StyledCloseIcon = styled(Close)`
  fill: ${(p) => p.theme.palette.input.text.selected};
  height: 1rem;
  width: 1rem;
`;
