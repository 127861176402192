import styled, { DefaultTheme } from 'styled-components';
import { ButtonProps, ButtonType } from './Button.types';

const typeStyleRules = (
  typeStyle: ButtonType,
  theme: DefaultTheme,
  isFocused: boolean | undefined = false,
): string => {
  switch (typeStyle) {
    case 'primary':
      return `
          background-color: ${theme.palette.button.background.primary.base};
          color: ${theme.palette.button.text.primary.base};
          &:hover{
            background-color:  ${theme.palette.button.background.primary.hover};
          }
          &:after{
            background: radial-gradient(circle at 50%, ${theme.palette.button.background.primary.after} 100%, transparent, transparent 100%);
          }
          `;
    case 'secondary':
      return `
          background-color: ${theme.palette.button.background.secondary.base};
          color: ${theme.palette.button.text.secondary.base};
          &:hover{
            background-color:  ${theme.palette.button.background.secondary.hover};
          }
          &:after{
            background: radial-gradient(circle at 50%, ${theme.palette.button.background.secondary.after} 100%, transparent, transparent 100%);
          }
        `;
    case 'secondaryWhite':
      return `
      background-color: transparent;
          color: ${theme.palette.text.link};
          &:hover{
            background-color: ${theme.palette.commons.tonal.hover};
          }
          &:after{
            background: radial-gradient(circle at 50%, ${theme.palette.button.background.outline.after} 100%, transparent, transparent 100%);
          }
        `;
    case 'outlined':
      return `
          background-color: ${
            isFocused
              ? theme.palette.button.text.outline.hover
              : theme.palette.button.background.outline.base
          };
          color: ${theme.palette.button.text.outline.base};
          border: solid 1px ${theme.palette.button.border.outline.base};
          &:after{
            background: radial-gradient(circle at 50%, ${
              theme.palette.button.background.outline.after
            } 100%, transparent, transparent 100%);
            color: ${theme.palette.button.text.outline.hover};
          };
          &:hover, &:focus {
            background-color: ${theme.palette.button.background.outline.hover};
            color: ${theme.palette.button.text.outline.hover};
            border: solid 1px ${theme.palette.button.border.outline.hover};
          }
        `;
    case 'outlinedWithoutBackground':
      return `
          background: none;
          color: ${theme.palette.button.text.outline.base};
          border: solid 1px ${theme.palette.button.border.outline.base};
          &:hover, &:focus {
            border: solid 1px ${theme.palette.button.border.outline.hover};
          }
        `;
    case 'textLink':
      return `
      background-color: transparent;
          color: ${theme.palette.text.link};
          border: none;
          &:hover, &:focus {
            text-decoration: underline;
          }
        `;
    case 'select':
      return `
      background-color:${theme.palette.button.background.select.base};
      color: ${theme.palette.button.text.select.base};
      border: 1px solid ${theme.palette.button.border.select.base};
      &:hover{
        border: 2px solid ${theme.palette.button.border.select.hover};
      }
      &:after{
        background-color:${theme.palette.button.background.select.after};
        color: ${theme.palette.button.text.select.base};
      };
      `;
    default:
      return '';
  }
};

interface ClickPosition {
  left: string;
  top: string;
  diameter: number;
}

export const StyledButton = styled.button<ClickPosition & ButtonProps>`
  border: none;
  border-radius: ${({ theme }) => `${theme.shape.button.borderRadius}`};
  cursor: pointer;
  font-family: ${(p) => p.theme.typography.fontFamily};
  ${(p) => p.blockMode && 'width: 100%;'};
  ${(p) => typeStyleRules(p.typeStyle || 'primary', p.theme, p.isFocused)};
  overflow: hidden;
  ${(p) => {
    switch (p.size) {
      case 'regular':
        return `
          min-width: 12.5rem;
          height: 3.75rem;
          font-size: 1rem;
          font-weight: 600;
        `;
      case 'small':
        return `
          min-width: 8.125rem;
          height: 2.25rem;
          font-size: 0.875rem;
          font-weight: 600;
        `;
      case 'extraSmall':
        return `
          min-width: 4.25rem;
          height: 1.5rem;
          font-size: 0.625rem;
          font-weight: 500;
          border-radius: 4px!important;
        `;
      default:
        return '';
    }
  }}

  &:hover: {
    opacity: 0.9;
  }

  &:focus {
    background-color: ${(p) =>
      p.typeStyle === 'outlined' &&
      `${p.theme.palette.button.background.outline.hover};`};
    opacity: 0.8;
    outline: 0;
  }

  ${(p) => p.sticky && 'border-radius: 0.5rem 0.5rem 0 0;'}
  ${(p) =>
    p.disabled &&
    'opacity: 0.39; cursor: not-allowed; &:hover{ opacity: 0.39;}'}

  ${(p) => p.noMinWidth && 'min-width: 0;'}

  position: relative;
  text-align: center;
  will-change: background-color, background-position;

  &:after {
    background-position: 50%;
    background-repeat: no-repeat;
    border-radius: 50%;
    content: '';
    display: block;
    height: ${(p) => p.diameter}px;
    left: ${(p) => p.left};
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: ${(p) => p.top};
    transform: scale(4);
    transition: transform 0.6s, opacity 0.6s ease-in;
    width: ${(p) => p.diameter}px;
  }

  &:active:after {
    opacity: 0.4;
    transform: scale(0, 0);
    transition: 0s;
  }
`;

export const StyledLoaderWrapper = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  & > svg {
    display: inline-block;
    margin: unset !important;
    padding-right: 0.5rem;
  }
`;
