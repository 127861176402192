import styled from 'styled-components';
import { MEDIA_QUERY } from '../Theme/utils/index';
import { ToggleSelectStyledProps } from './ToggleSelect.type';

export const StyledToggleSelect = styled.div<{ isHorizontal?: boolean }>`
  display: flex;
  font-family: ${(p) => p.theme.typography.fontFamily};
  justify-content: space-between;

  ${MEDIA_QUERY.below('sm')} {
    flex-direction: column;
    ${({ isHorizontal }) =>
      isHorizontal &&
      `
      flex-direction: row;
      `}
  }
`;

export const StyledToggleOption = styled.div<ToggleSelectStyledProps>`
  align-items: center;
  border: solid 2px ${(p) => p.theme.palette.button.border.select.base};
  border-radius: 0.5rem;
  color: ${(p) => p.theme.palette.button.text.select.base};
  cursor: pointer;
  display: flex;
  font-size: 0.75rem;
  height: 2.5rem;
  justify-content: center;
  letter-spacing: 0.38px;
  transition: all 200ms ease-in-out 0s;

  ${MEDIA_QUERY.below('sm')} {
    margin-top: 0.938rem;
    ${({ isHorizontal }) =>
      isHorizontal &&
      `
        margin: 0 0 0 0.938rem;
      `}
  }

  ${MEDIA_QUERY.above('sm')} {
    margin-left: 0.688rem;
  }

  width: 100%;

  ${(p) =>
    p.isSelected &&
    ` 
      background-color: ${p.theme.palette.button.background.select.selected};
      border: solid 2px ${p.theme.palette.button.border.select.selected};
    `}

  ${({ hasIcon }) =>
    hasIcon &&
    ` 
      row-gap: 0.3125rem;
      flex-direction: column;
      height: inherit;
      font-size: 0.875rem;
      padding: 0.625rem 0 1.125rem;
    `}


  &:first-child {
    margin-left: 0;
    margin-top: 0;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border: solid 2px ${(p) => p.theme.palette.button.border.select.hover};
    }
  }
`;

export const StyledIcon = styled.img`
  display: block;
  height: 3.125rem;
  margin: 0 auto;
  max-width: 3.75rem;
`;

export const StyledToggleOptionLabel = styled.p`
  font-size: inherit;
  margin: 0;
`;
