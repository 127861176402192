import React from 'react';
import { CookieModalParagraph } from '../../types';
import { CookieOrderedListParagraph } from './paragraphComponents/CookieOrderedListParagraph/CookieOrderedListParagraph';
import { CookieTextParagraph } from './paragraphComponents/CookieTextParagraph/CookieTextParagraph';

export const CookiePolicyMarkdownParagraph: React.FC<{
  paragraph: CookieModalParagraph;
}> = ({ paragraph }) => {
  switch (paragraph.type) {
    case 'orderedList':
      return <CookieOrderedListParagraph paragraph={paragraph} />;
    case 'textParagraph':
      return <CookieTextParagraph paragraph={paragraph} />;
    default:
      return <CookieTextParagraph paragraph={paragraph} />;
  }
};
