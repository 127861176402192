import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { ReactComponent as Close } from '../assets/svg/close.svg';
import { safeWindow } from '../utils/safeWindow';
import {
  StyledModal,
  StyledModalCloseButton,
  StyledModalContent,
  StyledModalWrapper,
  StyledWrapper,
} from './Modal.styled';
import { Modal, ModalProps } from './Modal.types';

export const useModal = ({
  parent,
  parentStyles,
  content: initialContent,
  isBlurred = false,
  isClosable = true,
  showCloseIcon = true,
  isNarrow = false,
  shouldSaveHistory = true,
  onClose,
  customHeader,
}: ModalProps): Modal => {
  const [isVisible, setIsVisible] = useState(false);
  const [content, setContent] = useState(initialContent);
  const [header, setHeader] = useState(customHeader);
  const wrapperRef = useRef(null);

  const root = parent || safeWindow.document?.body || null;

  const back = (): void => window.history.back();

  const close = (): void => (onClose ? onClose() : hide());

  const hide = (): void => {
    if (!isClosable) {
      return;
    }
    if (window.history.state?.modal) {
      back();
    }
    setIsVisible(false);
  };
  useEffect(() => {
    if (!root) {
      return;
    }
    if (isVisible) {
      root.style.overflowY = 'hidden';
    } else {
      root.style.overflowY = 'auto';
    }
  }, [isVisible, root, root?.style]);

  useEffect(() => {
    if (!root) {
      return undefined;
    }
    if (isVisible && shouldSaveHistory) {
      root.style.overflowY = 'hidden';
      if (!window.history.state?.modal) {
        window.history.pushState(
          { modal: true },
          document.title,
          window.location.href,
        );
        window.addEventListener('popstate', close, true);
      } else {
        window.history.forward();
      }
    }
    return () => {
      if (shouldSaveHistory) {
        window.removeEventListener('popstate', close, true);
        window.removeEventListener('popstate', back, true);
      }
    };
  });

  const component = root
    ? createPortal(
        <DefaultThemed>
          <StyledModal
            isBlurred={isBlurred}
            isClosable={isClosable}
            isVisible={isVisible}
            data-e2e="modalPortal"
            onClick={() => close()}
          >
            <StyledModalWrapper parentStyles={parentStyles}>
              <StyledWrapper
                ref={wrapperRef}
                isNarrow={isNarrow}
                onClick={(e) => e.stopPropagation()}
              >
                {header}
                {!!showCloseIcon && (
                  <StyledModalCloseButton type="button" onClick={close}>
                    <Close />
                  </StyledModalCloseButton>
                )}
                <StyledModalContent>{content}</StyledModalContent>
              </StyledWrapper>
            </StyledModalWrapper>
          </StyledModal>
        </DefaultThemed>,
        root,
      )
    : null;

  return {
    setHeader,
    setContent,
    isVisible,
    setIsVisible,
    component,
    hide,
  };
};
