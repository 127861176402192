import { CookieModalParagraph } from '../../../types';

export const getStartOfParagraphText = (
  paragraph: CookieModalParagraph,
  numberOfCharacters: number,
): string => {
  switch (paragraph.type) {
    case 'textParagraph':
      return paragraph.markdownTextContent[0].text.slice(0, numberOfCharacters);
    case 'orderedList':
      return paragraph.orderedElements[0].markdownTextContent[0].text.slice(
        0,
        numberOfCharacters,
      );
    default:
      return '';
  }
};
