import { DefaultTheme } from 'styled-components';
import { BeeFieldState } from '../BeeField.types';

export const getFieldColor = ({
  state,
  theme,
}: {
  state: BeeFieldState;
  theme: DefaultTheme;
}): string => {
  switch (state) {
    case BeeFieldState.INACTIVE: {
      return theme.palette.input.text.base;
    }
    case BeeFieldState.DISABLED: {
      return theme.palette.input.text.base;
    }
    case BeeFieldState.HOVER: {
      return theme.palette.input.border.hover;
    }
    case BeeFieldState.FOCUSED: {
      return theme.palette.input.border.selected;
    }
    case BeeFieldState.CHECKED: {
      return theme.palette.input.border.selected;
    }
    case BeeFieldState.ERROR: {
      return theme.palette.error.text.base;
    }
    default: {
      return theme.palette.input.text.base;
    }
  }
};
