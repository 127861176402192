import { CookieModalMarkDownContent, TenantSourceInfo } from '../../../types';

export const getCookieBarDescriptionInPolish = (
  sourceInfo: TenantSourceInfo,
): CookieModalMarkDownContent[] => [
  {
    type: 'text',
    text: `Wykorzystujemy pliki cookie m.in . w celu personalizowania treści dla Użytkowników oraz w celach optymalizacyjnych.
      Możesz kontrolować i określać sposób zapisywania plików cookies na swoim urządzeniu przy pomocy ustawień przeglądarki internetowej
      lub korzystając z opcji „Ustawienia plików cookie”. Polityka prywatności`,
  },
  {
    type: 'linkedText',
    text: sourceInfo.sourceName,
    link: sourceInfo.link,
  },
  {
    type: 'text',
    text: `dostępna jest po kliknięciu opcji "Ustawienia plików cookie". Klikając "Akceptuj wszystkie pliki cookie" akceptujesz wykorzystywanie
    wszystkich rodzajów plików cookie.`,
  },
];
