export const ANIMATIONS = {
  defaultTransitionTime: '0.3s',
  cubicBezier: '0.2, 0.85, 0.32, 1.2',
};

export const keyframesRipple = `
@keyframes ripple {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
};`;

export const keyframesRippleDuplicate = `
@keyframes rippleDuplicate {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
};
`;
