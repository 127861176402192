import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const InfoLineContainer = styled.div`
  align-items: center;
  display: flex;
  font-family: ${(p) => p.theme.typography.fontFamily};
  justify-content: flex-start;
  width: 100%;
  ${() =>
    isMobile ? 'margin-top: 10px;' : 'margin-top: -1rem; margin-bottom: 1rem;'}
`;
export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.625rem;
`;
