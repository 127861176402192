import styled from 'styled-components';
import { Button } from '../../../Button';
import { cookieBarAppearFrame } from './CookieModalInfoBar.frames';

const COMMON_ANIMATION_DURATION = '0.5s';

const COMMON_MEDIUM_SCREEN_SIZE = '960px';
const COMMON_SMALL_SCREEN_SIZE = '390px';

export const CookieModalInfoBarContainer = styled.div<{ isVisible: boolean }>`
  align-items: center;
  animation: ${cookieBarAppearFrame} ${COMMON_ANIMATION_DURATION} ease-in-out;
  background-color: ${({ theme }) => theme.palette.footer.background.secondary};
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: 0;
  min-height: 4rem;
  padding: 1rem;
  position: fixed;
  transition: transform ${COMMON_ANIMATION_DURATION} ease;
  width: 100%;
  @media (max-width: ${COMMON_MEDIUM_SCREEN_SIZE}) {
    padding: 1rem 1.5rem;
    width: 100%;
  }
  z-index: 98;

  ${({ isVisible }) =>
    !isVisible &&
    `
    transform: translateY(100%);
   `}
`;

export const CookieModalInfoBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;
  @media (max-width: ${COMMON_MEDIUM_SCREEN_SIZE}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const CookieModalInfoBarText = styled.div`
  color: ${({ theme }) => theme.palette.text.paragraph.primary};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 0.75rem;
  text-align: justify;
  @media (max-width: ${COMMON_MEDIUM_SCREEN_SIZE}) {
    width: 100%;
    margin-bottom: 1rem;
  }
  @media (max-width: ${COMMON_SMALL_SCREEN_SIZE}) {
    margin-bottom: 0;
  }
`;

export const CookieInfoBarButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  > :first-child {
    margin: 0 2rem;
  }
  @media (max-width: ${COMMON_MEDIUM_SCREEN_SIZE}) {
    width: 100%;
    > :first-child {
      margin: 0 0.5rem 0 0;
    }
  }
  @media (max-width: ${COMMON_SMALL_SCREEN_SIZE}) {
    width: 100%;
    margin-top: 1rem;
    flex-direction: column-reverse;
    > :first-child {
      margin-right: 0;
    }
  }
`;

export const CookieBarButton = styled(Button)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 0.875rem;
  height: 3.5rem;
  padding: 0.5rem;
  width: 10rem;
  @media (max-width: 1232px) {
    height: 4.5rem;
  }
  @media (max-width: ${COMMON_MEDIUM_SCREEN_SIZE}) {
    height: 3.5rem;
    width: 100%;
  }
  @media (max-width: ${COMMON_SMALL_SCREEN_SIZE}) {
    height: 3.5rem;
    width: 100%;
    margin: 0.5rem 0;
  }
  @media (max-width: 250px) {
    height: 4.5rem;
  }
`;
