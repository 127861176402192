import React from 'react';
import { usePickerContext } from '../../../../contexts';
import { getMonthIndex } from '../../../../contexts/PickerContext/PickerContext.utils';
import { convertToTwoDigitsString } from '../../../../helpers/convertToTwoDigitsString';
import { isDatePickerFormFilled } from '../../../../helpers/isDatePickerFormFilled';
import { isMonthInRange } from '../../../../helpers/isInRange';
import { StyledCalendarButton } from '../../RenderPicker.styled';
import { StyledMonthsWrapper } from './MonthPicker.styled';

export const MonthPicker: React.FC = () => {
  const {
    state: { date, datesRange, today, monthNames },
    dispatch,
  } = usePickerContext();

  const onClickHandler = React.useCallback(
    (monthIndex: number): void => {
      const month = convertToTwoDigitsString(monthIndex + 1);

      if (month === date.month) {
        dispatch({
          type: 'SET_VISIBLE_PICKER',
          payload: null,
        });

        return;
      }

      dispatch({
        type: 'SET_DATE',
        payload: { month: convertToTwoDigitsString(monthIndex + 1) },
      });

      if (isDatePickerFormFilled({ ...date, month })) {
        dispatch({
          type: 'SET_VISIBLE_PICKER',
          payload: null,
        });
      }
    },
    [date, dispatch],
  );

  return (
    <StyledMonthsWrapper>
      {monthNames.map((monthName, index) => (
        <StyledCalendarButton
          key={monthName}
          onClick={() => onClickHandler(index)}
          selected={getMonthIndex(date.month) === index}
          isToday={today.getUTCMonth() === index}
          isDisabled={
            datesRange && !isMonthInRange(date.year, index, datesRange)
          }
          type="button"
        >
          {monthName.slice(0, 3)}
        </StyledCalendarButton>
      ))}
    </StyledMonthsWrapper>
  );
};
