import * as React from 'react';
import styled from 'styled-components';

type StyledPanelWrapperType = {
  duration: string;
  isActive: boolean;
};

type StyledPanelsWrapperType = {
  styles?: React.CSSProperties;
  minHeight: number;
};

export const StyledPanelWrapper = styled.div<StyledPanelWrapperType>`
  left: 0;
  min-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity ${(props) => props.duration} ease-out;
  transition: ${(p) => p.duration};
  visibility: ${(props) => (props.isActive ? 'visible' : 'collapse')};
  width: 100%;
  z-index: 2;
  ${(props) => (!props.isActive ? 'opacity: 0;' : '')};
`;

export const StyledPanelsWrapper = styled.div<StyledPanelsWrapperType>`
  display: flex;
  justify-content: flex-start;
  min-height: ${(p) => p.minHeight}px;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  ${({ styles }) => styles && { ...styles }}
`;
