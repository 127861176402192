import styled from 'styled-components';
import { Button } from '../..';
import { Typography } from '../../Typography';
import { ReactComponent as Chevron } from '../../assets/svg/chevron.svg';

export const StyledConsentElement = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 0 0 95%;
  flex-direction: column;
`;

export const StyledConsentRequiredInformation = styled.div`
  background: ${(p) => p.theme.palette.commons.background.infotip};
  border-radius: 0.25rem;
  font-family: ${(p) => p.theme.typography.fontFamily};
  margin-bottom: 0.5rem;
  margin-left: 2.625rem;
  margin-top: 0.25rem;
  padding: 0.5rem;
  width: 100%;
`;

export const StyledDescriptionText = styled(Typography)`
  color: ${(p) => p.theme.palette.text.paragraph.primary};
  margin: 0;
  margin-left: 2.625rem;
`;

export const StyledConsentRow = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
`;

function retrieveCursorOrGiveMeNothing(props: { pointer?: boolean }): string {
  return props.pointer ? `cursor: pointer` : ``;
}

export const StyledConsentText = styled.p<{
  pointer?: boolean;
  margin?: boolean;
}>`
  color: ${(p) => p.theme.palette.text.paragraph.primary};
  font-size: ${(p) => p.theme.typography.body1.fontSize};
  font-weight: 400;
  ${retrieveCursorOrGiveMeNothing};
  ${(props) => (props.margin ? 'margin: auto auto auto 0.5rem' : 'margin: 0')};
`;

export const StyledDocumentLinks = styled.div`
  margin-left: 2.25rem;
`;

export const StyledDocumentLink = styled.a``;

export const StyledExpand = styled.span`
  cursor: pointer;
  display: flex;
`;

export const StyledChevron = styled(Chevron)<{ isExpanded?: boolean }>`
  fill: ${({ theme }) => theme.palette.brand.color};
  transform: rotate(${(props) => (props.isExpanded ? '-180deg' : '0deg')});
  transition: transform ease-in 0.2s;
`;

export const StyledSelectAll = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.palette.commons.divider};
  display: flex;
  justify-content: space-between;
  margin: -0.5rem 0 0.25rem 0;
`;

export const StyledSelectWrapper = styled.div`
  align-self: center;
  display: flex;
  flex: 0 0 75%;
`;

export const StyledSelectAllLabel = styled.p`
  color: ${(p) => p.theme.palette.text.paragraph.primary};
  cursor: pointer;
  font-size: ${(p) => p.theme.typography.body1.fontSize};
  font-weight: 700;
`;

export const ConsentsWrapper = styled.div`
  background-color: ${(p) => p.theme.palette.commons.background.container};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem 1.3rem 0 0;
  position: relative;
`;

export const CloseConsentsButton = styled(Button)`
  margin-top: 1rem;
`;
