import { getContainerPositionByBody } from './getContainerPositionByBody';
import { getElementProperties } from './getElementProperties';
import {
  PositionPropertiesType,
  PossibilityPositionPropertiesType,
  XYVariantsType,
  YVariantsType,
  XVariantsType,
  ElementProperties,
} from '../Menu.types';
import { safeWindow } from '../../utils/safeWindow';

export const getSelfVerticalPosition = (
  self: YVariantsType,
  elementProperties: ElementProperties,
): number => {
  switch (self) {
    case 'top':
      return 0;
    case 'center':
      return elementProperties.height / -2;
    default:
      return -elementProperties.height;
  }
};

export const getSelfHorizontalPosition = (
  self: XVariantsType,
  elementProperties: ElementProperties,
  labelProperties: ElementProperties,
  autoWidth: boolean,
): number => {
  const element = autoWidth ? labelProperties : elementProperties;
  switch (self) {
    case 'left':
      return 0;
    case 'middle':
      return element.width / -2;
    default:
      return -element.width;
  }
};

export const getAnchorVerticalPosition = (
  self: YVariantsType,
  elementProperties: ElementProperties,
): number => {
  switch (self) {
    case 'top':
      return 0;
    case 'center':
      return elementProperties.height / 2;
    default:
      return elementProperties.height;
  }
};

export const getAnchorHorizontalPosition = (
  self: XVariantsType,
  elementProperties: ElementProperties,
): number => {
  switch (self) {
    case 'left':
      return 0;
    case 'middle':
      return elementProperties.width / 2;
    default:
      return elementProperties.width;
  }
};
export const getPossibilityPositionProperty = (
  current: number,
  min: number,
  max: number,
): number => {
  if (current > max) {
    return max;
  }
  if (current < min) {
    return min;
  }
  return current;
};
export const getPossibilityPositionProperties = (
  positions: PossibilityPositionPropertiesType,
  contetntProperties: ElementProperties,
  withinTheScreen: boolean,
): PossibilityPositionPropertiesType => {
  const bodyWidth = safeWindow.document?.body.clientWidth ?? 0;
  const bodyHeight = safeWindow.document?.body.clientHeight ?? 0;

  const minLeftPossibility = withinTheScreen ? 0 : -contetntProperties.width;
  const maxLeftPossibility = withinTheScreen
    ? bodyWidth - contetntProperties.width
    : bodyWidth + contetntProperties.width;
  const minTopPossibility = withinTheScreen ? 0 : -contetntProperties.height;
  const maxTopPossibility = withinTheScreen
    ? bodyHeight - contetntProperties.height
    : bodyHeight + contetntProperties.height;

  return {
    left: getPossibilityPositionProperty(
      positions.left,
      minLeftPossibility,
      maxLeftPossibility,
    ),
    top: getPossibilityPositionProperty(
      positions.top,
      minTopPossibility,
      maxTopPossibility,
    ),
  };
};

export const getPositionProperties = (
  labelRefCurent: HTMLElement,
  anchor: XYVariantsType,
  self: XYVariantsType,
  contentRefCurent: HTMLElement | null,
  offsetTop: number,
  autoWidth: boolean,
  withinTheScreen: boolean,
): PositionPropertiesType => {
  const propertiesByBody = getContainerPositionByBody(
    labelRefCurent,
    anchor,
    self,
  );

  const labelProperties = getElementProperties(labelRefCurent);
  const contetntProperties = getElementProperties(contentRefCurent);

  const selfVerticalPosition = getSelfVerticalPosition(
    propertiesByBody.selfPosition.positionY,
    contetntProperties,
  );
  const selfHorizontalPosition = getSelfHorizontalPosition(
    propertiesByBody.selfPosition.positionX,
    contetntProperties,
    labelProperties,
    autoWidth,
  );
  const anchorVerticalPosition = getAnchorVerticalPosition(
    propertiesByBody.anchorPosition.positionY,
    labelProperties,
  );
  const anchorHorizontalPosition = getAnchorHorizontalPosition(
    propertiesByBody.anchorPosition.positionX,
    labelProperties,
  );

  const contentWidth = labelProperties.width;
  const topAnchorSelf =
    propertiesByBody.top -
    window.pageYOffset +
    offsetTop +
    selfVerticalPosition +
    anchorVerticalPosition;

  const leftAnchorSelf =
    propertiesByBody.left + selfHorizontalPosition + anchorHorizontalPosition;
  return {
    width: contentWidth,
    ...getPossibilityPositionProperties(
      {
        left: leftAnchorSelf,
        top: topAnchorSelf,
      },
      contetntProperties,
      withinTheScreen,
    ),
  };
};
