import React, { useEffect } from 'react';
import {
  CookieModalHeader,
  CookieModalWindow,
  CookiesModalHeaderTitle,
  CookieModalCloseIcon,
  CookieModalCommonDescriptionText,
  CookieModalButtonsWrapper,
  CookieModalButtonContainer,
  CookieModalButton,
  CookieModalOverlay,
  CookieModalBodyContent,
} from './CookieModalDisplay.styles';
import { IDS_OF_REQUIRED_CONSENTS } from '../../context/CookieModalProvider';
import { CookieModalConsent } from '../CookieModalConsent/CookieModalConsent';
import { useCookieModalContext } from '../../context/useCookieModalContext';
import { CookiePolicyDescriptionComponent } from '../CookiePolicyDescription/CookiePolicyDescriptionComponent';

export const CookieModalDisplay: React.FC = () => {
  const {
    translatedText,
    cookieConsentDefinitions,
    acceptSelectedConsents,
    denyAll,
    selectedConsentIds,
    acceptAll,
    handleCloseModal,
    isSettingMode,
    areConsentsChosen,
  } = useCookieModalContext();

  const optionalChosenConsents = selectedConsentIds.filter(
    (id) => !IDS_OF_REQUIRED_CONSENTS.includes(id),
  );

  useEffect(() => {
    if (isSettingMode) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isSettingMode]);

  const shouldBeVisible = isSettingMode && !areConsentsChosen;

  return (
    <CookieModalOverlay isVisible={shouldBeVisible}>
      <CookieModalWindow isVisible={shouldBeVisible}>
        <CookieModalHeader>
          <CookiesModalHeaderTitle>
            {translatedText.PRIVACY_POLICY}
          </CookiesModalHeaderTitle>
          <CookieModalCloseIcon onClick={handleCloseModal} />
        </CookieModalHeader>
        <CookieModalBodyContent>
          <CookieModalCommonDescriptionText>
            {translatedText.COMMON_POLICY_DESCRIPTION}
          </CookieModalCommonDescriptionText>
          <CookiePolicyDescriptionComponent />
          {cookieConsentDefinitions.map((consent) => (
            <CookieModalConsent key={consent.id} consent={consent} />
          ))}
          <CookieModalButtonContainer>
            <CookieModalButtonsWrapper>
              {optionalChosenConsents.length ? (
                <CookieModalButton
                  typeStyle="outlined"
                  size="regular"
                  onClick={acceptSelectedConsents}
                >
                  {translatedText.SAVE_AND_CLOSE}
                </CookieModalButton>
              ) : (
                <CookieModalButton
                  typeStyle="outlined"
                  size="regular"
                  onClick={denyAll}
                >
                  {translatedText.DENY_ALL}
                </CookieModalButton>
              )}

              <CookieModalButton
                typeStyle="secondary"
                size="regular"
                onClick={acceptAll}
              >
                {translatedText.ACCEPT_ALL}
              </CookieModalButton>
            </CookieModalButtonsWrapper>
          </CookieModalButtonContainer>
        </CookieModalBodyContent>
      </CookieModalWindow>
    </CookieModalOverlay>
  );
};
