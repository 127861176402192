import React, { useMemo } from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { CookieModalProvider } from './context/CookieModalProvider';
import { CookiesModalProps } from './types/CookiesModalProps';
import { checkCookieConsentsInSession } from './context/utils';
import { CookieModalDisplay } from './components/CookieModalDisplay/CookieModalDisplay';
import { CookieModalInfoBar } from './components/CookieModalInfoBar/CookieModalInfoBar';

export const CookieModal: React.FC<CookiesModalProps> = (props) => {
  const shouldRenderComponents = useMemo(
    () => !checkCookieConsentsInSession(),
    [],
  );

  if (!shouldRenderComponents) {
    return null;
  }

  return (
    <DefaultThemed>
      <CookieModalProvider {...props}>
        <CookieModalDisplay />
        <CookieModalInfoBar />
      </CookieModalProvider>
    </DefaultThemed>
  );
};
