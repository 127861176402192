import styled from 'styled-components';
import hex from '../assets/svg/hex.svg';

export const StyledHexagonLoader = styled.div`
  height: 170px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 170px;

  .hexagons {
    border-radius: 100%;
    left: 35px;
    padding: 5%;
    position: relative;
    top: 30px;

    .hexagon {
      background: url(${hex}) no-repeat;
      height: 46px;
      position: absolute;
      transform-origin: center;
      width: 40px;

      :nth-child(1) {
        animation: animateHex 3s infinite;
      }

      :nth-child(2) {
        animation: animateHex 3s 0.2s infinite;
        left: 53px;
      }

      :nth-child(3) {
        animation: animateHex 3s 1s infinite;
        left: -13px;
        top: 46px;
      }

      :nth-child(4) {
        animation: animateHex 3s 1.2s infinite;
        left: 31px;
        top: 46px;
      }

      :nth-child(5) {
        animation: animateHex 3s 0.4s infinite;
        left: 75px;
        top: 46px;
      }

      :nth-child(6) {
        animation: animateHex 3s 0.8s infinite;
        top: 84px;
      }

      :nth-child(7) {
        animation: animateHex 3s 0.6s infinite;
        left: 53px;
        top: 84px;
      }
    }
  }

  @keyframes rotateSmall {
    100% {
      transform: rotate(1turn);
    }
  }

  @keyframes rotateLarge {
    0% {
      clip-path: inset(0px 30px 30px 0px);
      -webkit-clip-path: inset(0px 30px 30px 0px);
    }
    50% {
      clip-path: inset(0px 150px 150px 0px);
      -webkit-clip-path: inset(0px 150px 150px 0px);
    }
    100% {
      transform: rotate(1turn);
      clip-path: inset(0px 30px 30px 0px);
      -webkit-clip-path: inset(0px 30px 30px 0px);
    }
  }

  @keyframes animateHex {
    0% {
      transform: scale(1.02);
    }
    20%,
    50% {
      transform: scale(0.6);
      opacity: 0;
    }
    65% {
      transform: scale(1.02);
      opacity: 1;
    }
  }
`;
