import { BeeFieldStyles } from '../../BeeField.types';

export const DEFAULT_ANIMATED_LABEL_STYLES: BeeFieldStyles = {
  focused: {
    fontSize: '0.75rem',
    lineHeight: '0.5rem',
    top: '-0.25rem',
  },
  default: {
    fontSize: '1rem',
    lineHeight: '0',
    top: '50%',
    left: '1rem',
  },
};
