import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ANIMATIONS } from '../Theme/utils/animations';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { safeWindow } from '../utils/safeWindow';
import {
  StyledContentWrapper,
  StyledDialogBackdrop,
  StyledDialogWrapper,
} from './Dialog.styled';
import { DialogProps } from './Dialog.types';

export const Dialog: React.FC<DialogProps> = ({
  children,
  value,
  onChange,
  backdropColor = 'rgba(0, 0, 0, 0.5)',
  position = 'center',
  noBackdropDismiss = false,
  duration = ANIMATIONS.defaultTransitionTime,
  zIndex = 10,
}: DialogProps) => {
  const ref = useRef(null);
  const contentRef = useRef(null);
  const [isRenderedPortal, setIsRenderedPortal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const durationMiliseconds = Number(duration.replace(/[^\d.-]/g, '')) * 1000;
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const body = safeWindow.document?.body;

  const show = (): void => {
    setIsVisible(false);
    setIsRenderedPortal(true);
    requestAnimationFrame(() => {
      timer.current = setTimeout(() => {
        setIsVisible(true);
      }, 0);
    });
  };

  const hide = (): void => {
    setIsVisible(false);
    timer.current = setTimeout(() => {
      setIsRenderedPortal(false);
    }, durationMiliseconds);
  };

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isVisible]);

  useEffect(() => {
    timer.current && clearTimeout(timer.current);
    value ? show() : hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current);
      document.body.style.overflowY = 'auto';
    };
  }, []);

  const handleClickOnBackdrop = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    event.target === event.currentTarget &&
      value &&
      !noBackdropDismiss &&
      onChange &&
      onChange(false);
  };

  const componentJsx =
    body &&
    createPortal(
      <DefaultThemed>
        <>
          <StyledDialogBackdrop
            isVisible={isVisible}
            backgroundColor={backdropColor}
            duration={duration}
          />
          <StyledDialogWrapper
            isVisible={isVisible}
            position={position}
            duration={duration}
            onClick={handleClickOnBackdrop}
            zIndex={zIndex}
          >
            <StyledContentWrapper
              duration={duration}
              isVisible={isVisible}
              position={position}
            >
              <div ref={contentRef}>{children}</div>
            </StyledContentWrapper>
          </StyledDialogWrapper>
        </>
      </DefaultThemed>,
      body,
    );
  return <div ref={ref}>{isRenderedPortal && componentJsx}</div>;
};
