import * as React from 'react';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import {
  AnimatedLabelStyledWrapper,
  AnimatedLabelStyled,
} from './AnimatedLabel.styled';
import { AnimatedLabelProps } from './AnimatedLabel.types';

export const AnimatedLabel: React.FC<AnimatedLabelProps> = ({
  visible,
  focused,
  label,
  error,
  children,
  onInputClick,
  disabled,
  isValid,
  readOnly,
  isHovered,
}: AnimatedLabelProps): React.ReactElement => {
  return (
    <DefaultThemed>
      <AnimatedLabelStyledWrapper
        visible={visible}
        focused={focused}
        error={error}
        onClick={(e) => onInputClick && onInputClick(e)}
        disabled={disabled}
        isValid={isValid}
        readOnly={readOnly}
        isHovered={isHovered}
      >
        <AnimatedLabelStyled>{label}</AnimatedLabelStyled>
        {children}
      </AnimatedLabelStyledWrapper>
    </DefaultThemed>
  );
};
