import React, { FC, PropsWithChildren, useContext } from 'react';
import { DefaultTheme, ThemeContext, ThemeProvider } from 'styled-components';

import { beesafeTheme as defaultTheme } from '../Theme/index';

export type ThemeConfiguration = typeof defaultTheme;

export type ThemeProp = {
  theme: ThemeConfiguration;
};

export const useTheme = (): ThemeConfiguration => {
  return useContext<DefaultTheme>(ThemeContext);
};

export const Themed: FC<PropsWithChildren<{ theme?: ThemeConfiguration }>> = (
  props: PropsWithChildren<{ theme?: ThemeConfiguration }>,
) => {
  return (
    <ThemeProvider theme={props.theme ?? defaultTheme}>
      {props.children}
    </ThemeProvider>
  );
};

export const DefaultThemed: FC<{ children: JSX.Element }> = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const theme = useTheme();
  return <Themed theme={theme}>{children}</Themed>;
};
