import { CookieModalMarkDownContent, TenantSourceInfo } from '../../../types';

export const getCookieBarDescriptionInEnglish = (
  sourceInfo: TenantSourceInfo,
): CookieModalMarkDownContent[] => [
  {
    type: 'text',
    text: `We use cookies, among others, to personalize content for users and for optimization purposes. 
    You can control and define how cookies are saved on your device using your web browser settings or the 
    "Set preferences" option. Privacy policy of`,
  },
  {
    type: 'linkedText',
    text: sourceInfo.sourceName,
    link: sourceInfo.link,
  },
  {
    type: 'text',
    text: `is available after clicking "Set preferences". By clicking "Accept all cookies," you accept the use all types of cookies.`,
  },
];
