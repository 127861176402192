import React, { FC } from 'react';
import styled from 'styled-components';
import {
  StyledTypographyElementType,
  TypographyProps,
} from './Typography.types';

export const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle: 'h6',
  body: 'p',
  inherit: 'p',
  span: 'span',
  li: 'li',
  dt: 'dt',
  dd: 'dd',
};

export const TypographyElement: FC<TypographyProps> = ({
  component,
  children,
  ...rest
}) => {
  const tags = {
    ...rest,
    styles: undefined,
    align: undefined,
    variant: undefined,
    color: undefined,
    fontFamily: undefined,
  };
  return React.createElement(
    component ? defaultVariantMapping[component] : defaultVariantMapping.body,
    {
      ...tags,
    },
    children,
  );
};

export const StyledTypographyElement = styled(
  TypographyElement,
)<StyledTypographyElementType>`
  ${({ styles }) => styles && { ...styles }}
`;
