import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ReactComponent as CloseIcon } from '../assets/svg/close.svg';
import { BeeField } from '../BeeField/BeeField';
import { BeeFieldState } from '../BeeField/BeeField.types';
import { Icon } from '../BeeTextInput/components/Icon';
import { Dialog } from '../Dialog/Dialog';
import { useCheckFocus, useFocus } from '../Hooks/UseFocus/useFocus.hook';
import { Menu } from '../Menu/Menu';
import {
  onKeyDownArrowDown,
  onKeyDownEnter,
  onKeyDownTab,
} from '../utils/onKeyDown.utils';
import {
  DropIcon,
  StyledInput,
  StyledLabel,
  StyledLabelContent,
  StyledOptionsContainer,
} from './BeeSelect.style';
import { SelectProps } from './BeeSelect.type';
import { DialogContent } from './components/DialogContent/DialogContent.component';
import { Option } from './components/Option/Option.component';
import { OptionType, SingleValueType } from './components/Option/Option.type';

export const BeeSelect: React.FC<SelectProps> = (props: SelectProps) => {
  const {
    value,
    onChange,
    options,
    inputValue: inputValueProp,
    onInput,
    onClick,
    setLabel,
    search,
    selectedLabel,
    isStepsMode,
    useDialog,
    placeholder,
    inProgress,
    zIndex,
    leadingIconBgColor = 'transparent',
    trailingIconBgColor = 'transparent',
    dataE2e = 'select',
    dialogFullScreen = true,
    hideDropdown = false,
    hideOnScroll = false,
    disabled = false,
    selectFirstOptionOnEnter = false,
    alwaysOpenedWhenOptionsExists = false,
    clearOptionText,
  } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>('');
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [focused, setFocused] = useState<SingleValueType>(null);
  const [isKeyPressedShift, setIsKeyPressedShift] = useState<boolean>(false);
  const { focusNextElementInDOM, focusPrevElementInDOM } = useFocus();
  const isFocusedMenu = useCheckFocus(menuRef);

  const inputVal = inputValueProp !== undefined ? inputValueProp : inputValue;
  const isLoading = typeof inProgress !== 'undefined';
  const isMultipleMode = Array.isArray(value);

  useEffect(() => {
    options.length === 0 && !useDialog && setIsOpened(false);
  }, [options, useDialog]);

  useEffect(() => {
    if (!isOpened) {
      setFocused(null);
    }
  }, [isOpened]);

  const onClickSelectHandle = (): void => {
    if (disabled || isLoading) {
      return;
    }

    onClick?.();
    setIsOpened((open) => !open);
  };

  const onChangeOptionHandle = (newVal: SingleValueType): void => {
    if (isMultipleMode) {
      const valueCopy = [...value];
      const index = value.indexOf(newVal);
      if (index > -1) {
        valueCopy.splice(index, 1);
        onChange(valueCopy);
      } else {
        onChange([...value, newVal]);
      }
    } else {
      onChange(newVal);
      !isStepsMode && setIsOpened(false);
      const refToFocus = shouldRenderInput ? inputRef : menuRef;
      newVal && refToFocus.current?.focus();
    }
  };
  const shouldRenderInput = !!onInput || search;
  const filteredOptions = useMemo((): OptionType[] => {
    if (search && inputVal) {
      return options.filter((option) =>
        option.label.toLowerCase().includes(inputVal.toLowerCase()),
      );
    }
    return [...options];
  }, [inputVal, search, options]);

  useEffect(() => {
    const clearOptionAdded = filteredOptions.length !== options.length;
    const shouldRemoveClearOption = clearOptionAdded && !value && !isOpened;
    const shouldRenderClearOption =
      clearOptionText &&
      !isMultipleMode &&
      value &&
      isOpened &&
      !clearOptionAdded;

    if (shouldRenderClearOption) {
      filteredOptions.unshift({
        label: '',
        title: clearOptionText,
        value: null,
      });
    }

    if (shouldRemoveClearOption) {
      filteredOptions.shift();
    }
  }, [isOpened]);

  const menuJsx = isLoading
    ? inProgress
    : filteredOptions.map((option, i) => {
        return (
          <Option
            isOpened={isOpened}
            value={value}
            focused={focused}
            option={option}
            icon={option.title === clearOptionText ? <CloseIcon /> : undefined}
            onChange={onChangeOptionHandle}
            setFocused={(newVal) => setFocused(newVal)}
            setIsKeyPressedShift={setIsKeyPressedShift}
            dataE2e={`${dataE2e}-option-${i}`}
            focusNextOption={() => {
              const index = options.map((el) => el.value).indexOf(focused);
              index > -1 && options[index + 1]
                ? setFocused(options[index + 1].value)
                : setFocused(options[0].value);
            }}
            focusPrevOption={() => {
              const index = options.map((el) => el.value).indexOf(focused) - 1;
              index > -1
                ? setFocused(options[index].value)
                : setFocused(options[options.length - 1].value);
            }}
            onKeyDownTab={() => {
              isKeyPressedShift
                ? focusPrevElementInDOM(menuRef.current as HTMLDivElement)
                : focusNextElementInDOM(menuRef.current as HTMLDivElement);
            }}
            inputMode={!!shouldRenderInput}
            key={option.value as string}
          />
        );
      });

  const selectedOptionLabel = useMemo(() => {
    if (selectedLabel) {
      return selectedLabel;
    }
    if (isMultipleMode) {
      let str = '';
      for (const val of value) {
        const index = options.map((el) => el.value).indexOf(val);
        if (index > -1) {
          if (!str) {
            str = options[index].label;
          } else {
            str = setLabel?.(value) || `${str}, ${options[index].label}`;
          }
        }
      }
      return str;
    } else {
      const index = options.map((el) => el.value).indexOf(value);
      if (index > -1) {
        return options[index].label;
      }
    }
    return '';
  }, [selectedLabel, isMultipleMode, value, options, setLabel]);
  const onKeyDownHandle = (e: React.KeyboardEvent): void => {
    onKeyDownArrowDown(e, () => {
      e.preventDefault();
      if (!focused && (isOpened || isFocusedMenu) && options.length) {
        setFocused(options[0].value);
      }
    });
    onKeyDownTab(e, () => {
      setIsOpened(false);
    });
  };
  const setInputValueHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    inputValueProp === undefined && setInputValue(e.target.value);
    onInput && onInput(e);
    setIsOpened(true);
  };
  const isOpenedDialog = !hideDropdown && isOpened;
  const isOpenedMenu =
    isOpenedDialog && (isLoading || filteredOptions.length > 0);
  const isAlwaysOpenedWhenOptionsExists =
    alwaysOpenedWhenOptionsExists && filteredOptions.length > 0;
  const onInputKeyDownHandle = useCallback(
    (e: React.KeyboardEvent): void => {
      onKeyDownEnter(e, () => {
        selectFirstOptionOnEnter &&
          filteredOptions.length &&
          (isOpenedMenu || isOpenedDialog || isAlwaysOpenedWhenOptionsExists) &&
          onChangeOptionHandle(filteredOptions[0].value);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filteredOptions,
      isAlwaysOpenedWhenOptionsExists,
      isOpenedDialog,
      isOpenedMenu,
      selectFirstOptionOnEnter,
    ],
  );
  const selectJsx = (
    <StyledLabelContent isLoading={isLoading} data-e2e={`${dataE2e}-label`}>
      {selectedOptionLabel}
      {shouldRenderInput && !useDialog && (
        <StyledInput
          data-e2e={`${dataE2e}-input`}
          placeholder={placeholder}
          ref={inputRef}
          value={inputVal}
          onChange={setInputValueHandle}
          onKeyDown={onInputKeyDownHandle}
        />
      )}
    </StyledLabelContent>
  );
  const getFieldState = (): BeeFieldState => {
    if (disabled) {
      return value ? BeeFieldState.DISABLED : BeeFieldState.INACTIVE;
    }
    if (props.error) {
      return BeeFieldState.ERROR;
    }
    if (isOpened || isFocusedMenu) {
      return BeeFieldState.FOCUSED;
    }
    const isArray = Array.isArray(selectedOptionLabel);
    if (
      inputVal ||
      (selectedOptionLabel &&
        (!isArray || (isArray && selectedOptionLabel.length > 0)))
    ) {
      return BeeFieldState.CHECKED;
    }
    return BeeFieldState.INACTIVE;
  };

  return (
    <BeeField
      state={getFieldState()}
      label={props.label}
      helperText={props.helperText}
      error={props.error}
      prefix={
        props.prefixNode
          ? props.prefixNode
          : props.leadingIcon && (
              <Icon
                inputState={getFieldState()}
                bgColor={leadingIconBgColor}
                orientation="left"
                icon={props.leadingIcon}
              />
            )
      }
      suffix={
        props.suffixNode ? (
          props.suffixNode
        ) : (
          <Icon
            inputState={getFieldState()}
            bgColor={trailingIconBgColor}
            orientation="right"
            icon={props.trailingIcon || <DropIcon isOpened={isOpened} />}
          />
        )
      }
    >
      {useDialog ? (
        <>
          <StyledLabel onClick={onClickSelectHandle}>{selectJsx}</StyledLabel>
          <Dialog
            value={isOpenedDialog || isAlwaysOpenedWhenOptionsExists}
            onChange={setIsOpened}
            zIndex={zIndex}
          >
            <DialogContent
              close={() => setIsOpened(false)}
              label={props.label}
              onBack={props.onBack}
              placeholder={placeholder}
              inputRef={inputRef}
              contentRef={contentRef}
              dialogFullScreen={dialogFullScreen}
              shouldRenderInput={!!shouldRenderInput}
              inputVal={inputVal}
              setInputValueHandle={setInputValueHandle}
              dataE2e={dataE2e}
              onInputKeyDown={onInputKeyDownHandle}
            >
              {menuJsx}
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <Menu
          el={selectJsx}
          value={isOpenedMenu || isAlwaysOpenedWhenOptionsExists}
          onChange={onClickSelectHandle}
          ref={menuRef}
          onKeyDown={onKeyDownHandle}
          hideOnScroll={hideOnScroll}
          offsetTop={2}
          zIndex={zIndex}
        >
          <StyledOptionsContainer ref={contentRef}>
            {menuJsx}
          </StyledOptionsContainer>
        </Menu>
      )}
    </BeeField>
  );
};
export default BeeSelect;
