import React from 'react';

import { isMobile } from 'react-device-detect';
import { BeeSelect } from '../BeeSelect/BeeSelect.component';
import { SingleValueType } from '../BeeSelect/components/Option/Option.type';
import {
  StyledChevronIcon,
  StyledChips,
  StyledChipsContainer,
  StyledNextChip,
} from './BeeStepSelect.styled';
import { BeeStepSelectProps } from './BeeStepSelect.type';

export const BeeStepSelect: React.FC<BeeStepSelectProps> = (
  props: BeeStepSelectProps,
) => {
  const { chips, nextChip, onChange, onDelete, dataE2e = 'select' } = props;
  const chipsJsx = chips.map((chip, i) => {
    return (
      <StyledChips
        onClick={() => isMobile && onDelete(chip.value)}
        onDelete={() => !isMobile && onDelete(chip.value)}
        size="medium"
        color="primary"
        key={i}
        dataE2e={`${dataE2e}-chip-${i}`}
      >
        {chip.label}
      </StyledChips>
    );
  });
  const isShowArrowOnMobile = nextChip && isMobile;
  const selectedLabel = isMobile ? (
    <StyledChipsContainer>
      {chipsJsx}
      {nextChip && (
        <StyledNextChip>
          {nextChip}
          <StyledChevronIcon />
        </StyledNextChip>
      )}
    </StyledChipsContainer>
  ) : (
    chipsJsx
  );
  return (
    <BeeSelect
      {...props}
      dataE2e={dataE2e}
      onChange={(newVal) => onChange(newVal as SingleValueType)}
      selectedLabel={
        chipsJsx.length || isShowArrowOnMobile ? selectedLabel : undefined
      }
      isStepsMode
      onInput={props.hideInput ? undefined : props.onInput}
      useDialog={isMobile}
    />
  );
};
export default BeeStepSelect;
