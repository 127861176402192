import {
  StyledSelectAll,
  StyledSelectAllLabel,
  StyledSelectWrapper,
} from './ConsentsModal.styled';
import { CheckBox } from '../../CheckBox';
import React from 'react';
import { ConsentsCustomerHeaderProps } from './ConsentsModal.types';

export const ConsentsModalHeader = (
  props: ConsentsCustomerHeaderProps,
): JSX.Element => {
  const { handleSelectAll, areAllSelected, headerText } = props;

  return (
    <StyledSelectAll data-e2e="selectAll">
      <StyledSelectWrapper>
        <CheckBox
          id="selectAll"
          name="selectAll"
          text={<StyledSelectAllLabel>{headerText}</StyledSelectAllLabel>}
          checked={areAllSelected}
          onChange={handleSelectAll}
        />
      </StyledSelectWrapper>
    </StyledSelectAll>
  );
};
