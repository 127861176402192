import styled from 'styled-components';

export const CookieModalSeeMoreButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.text.paragraph.primary};
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: 1rem;
  padding: 0;
  text-decoration: underline;
  width: auto;
  &:hover {
    text-decoration: none;
  }
`;

export const CookiePolicyDescriptionContainer = styled.div<{
  isOpen: boolean;
}>`
  margin-top: 1rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-out;
  ${({ isOpen }) =>
    isOpen &&
    `
  max-height: 500rem;
  transition: max-height 1s ease-in;
  `}
`;
