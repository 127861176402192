import React, {
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BeeField } from '../BeeField';
import { DefaultThemed } from '../ThemeProvider/ThemeProvider';
import { Validator } from '../Validator';
import { useCombinedRefs } from '../shared/utils';
import { DEFAULT_TEXT_INPUT_STYLES } from './BeeTextInput.defaults';
import { StyledBeeTextInput } from './BeeTextInput.styled';
import { BeeTextInputWrapperProps, InputValue } from './BeeTextInput.types';
import { getEmptyValue, getInputState } from './BeeTextInput.utils';
import { InputContext } from './InputContext/InputContext';
import { InputContextType } from './InputContext/InputContext.types';
import { Icon } from './components/Icon';
import { usePrevious } from '../Hooks';

function BeeTextInput(
  {
    styles = DEFAULT_TEXT_INPUT_STYLES,
    maskProps,
    validatorProps,
    helperText,
    helperTextFullWidth,
    leadingIcon,
    leadingIconBgColor,
    trailingIcon,
    trailingIconBgColor,
    suffixNode,
    prefixNode,
    fullWidth,
    noTopMargin,
    onChange,
    onBlur,
    onFocus,
    onMouseOver,
    onMouseLeave,
    onKeyPressEnter,
    ...props
  }: BeeTextInputWrapperProps,
  ref: React.ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const mask = maskProps?.mask;
  const maskChar = maskProps?.maskChar;
  const skipEmptyValidation = validatorProps?.skipEmptyValidation;
  const emptyValue = getEmptyValue(mask, maskChar, skipEmptyValidation);

  const previousValue = usePrevious(props.value);
  const [value, setValue] = useState<InputValue>(props.value || emptyValue);
  const [focused, setFocus] = useState(false);
  const [hovered, setHover] = useState(false);
  const innerInputRef = useRef(null);
  const textInputRef = useCombinedRefs<HTMLInputElement>(ref, innerInputRef);

  const withAnimatedLabel = !!props.label;
  const filled = value !== emptyValue;
  const disabled = props.disabled !== undefined && props.disabled;
  const disableAutoComplete = props.disableAutoComplete ?? false;
  const autoComplete = disableAutoComplete
    ? 'new-password'
    : props.autoComplete;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange?.(event);
    setValue(event.target.value);
  };

  const handleKeyPressEnter = (
    event: KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter') {
      onKeyPressEnter?.();
    }
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    onFocus?.(event);
    setFocus(true);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    onBlur?.(event);
    setFocus(false);
  };

  const handleMouseOver = (event: MouseEvent<HTMLInputElement>): void => {
    onMouseOver?.(event);
    setHover(true);
  };

  const handleMouseLeave = (event: MouseEvent<HTMLInputElement>): void => {
    onMouseLeave?.(event);
    setHover(false);
  };

  useEffect(() => {
    if (previousValue !== props.value) {
      setValue(props.value || emptyValue);
    }
  }, [props.value, emptyValue, previousValue]);

  return (
    <DefaultThemed>
      <Validator value={value} {...validatorProps}>
        {(validationResult) => {
          const error = validationResult.error || props.error;
          const inputState =
            props.inputState ||
            getInputState({
              error,
              focused,
              hovered,
              disabled,
              filled,
            });
          const contextValue: InputContextType = {
            inputState,
            styles,
            withAnimatedLabel,
          };

          return (
            <InputContext.Provider value={contextValue}>
              <BeeField
                state={inputState}
                styles={styles}
                label={props.label}
                helperText={helperText}
                helperTextFullWidth={helperTextFullWidth}
                error={error}
                noTopMargin={noTopMargin}
                fullWidth={fullWidth}
                prefix={
                  prefixNode ||
                  (leadingIcon && (
                    <Icon
                      inputState={inputState}
                      bgColor={leadingIconBgColor}
                      orientation="left"
                      icon={leadingIcon}
                    />
                  ))
                }
                suffix={
                  suffixNode ||
                  (trailingIcon && (
                    <Icon
                      bgColor={trailingIconBgColor}
                      orientation="right"
                      icon={trailingIcon}
                    />
                  ))
                }
              >
                <StyledBeeTextInput
                  {...props}
                  maskProps={maskProps}
                  passedRef={textInputRef}
                  value={value}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onMouseOver={handleMouseOver}
                  onMouseLeave={handleMouseLeave}
                  onKeyPress={handleKeyPressEnter}
                  inputState={inputState}
                  styles={styles}
                  disabled={disabled}
                  autoComplete={autoComplete}
                />
              </BeeField>
            </InputContext.Provider>
          );
        }}
      </Validator>
    </DefaultThemed>
  );
}

const BeeTextInputWithRef = React.forwardRef<
  HTMLInputElement,
  BeeTextInputWrapperProps
>(BeeTextInput);

export { BeeTextInputWithRef as BeeTextInput };
