import React, { useRef, useEffect } from 'react';
import { TabProps } from './Tabs.types';
import { useTabs } from './Tabs.provider';
import { StyledTabWrapper } from './Tabs.styled';
import { getPosition } from './utils/getPosition';

export const Tab: React.FC<TabProps> = ({
  name,
  onClick,
  children,
}: TabProps) => {
  const ref = useRef(null);
  const {
    value,
    tabStyle,
    activeTabStyle,
    duration,
    onChange,
    setBackdropPosition,
  } = useTabs();
  const onClickTabHandle = (e: React.MouseEvent<HTMLElement>): void => {
    onChange(name);
    onClick && onClick(e);
  };
  const setNewBackdropPosition = (): void => {
    if (name === value) {
      const position = getPosition(ref.current);
      setBackdropPosition(position);
    }
  };
  useEffect(() => {
    setNewBackdropPosition();
  }, [name, value]);
  const isActive = value === name;
  return (
    <StyledTabWrapper
      ref={ref}
      onClick={onClickTabHandle}
      isActive={isActive}
      duration={duration}
      tabStyle={tabStyle}
      activeTabStyle={activeTabStyle}
    >
      {children}
    </StyledTabWrapper>
  );
};
