import { BeeTextInputStyles } from './BeeTextInput.types';
import { BeeFieldState } from '../BeeField/BeeField.types';

export const DEFAULT_TEXT_INPUT_STYLES: BeeTextInputStyles = {
  hover: {},
  focused: {
    borderWidth: '0.125rem',
  },
  checked: {},
  error: {},
  default: {
    borderWidth: '0.0625rem',
  },
};

export const DEFAULT_TEXT_INPUT_STATE = BeeFieldState.INACTIVE;
